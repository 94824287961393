import React, { Component, Fragment } from "react";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { Button, Row, Col, Card, Container,FormGroup, Label, CardBody, CardFooter} from "reactstrap";
import { FaPencilAlt} from "react-icons/fa";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import Loader from "react-loaders";
import Swal from 'sweetalert';
import Tabs from "react-responsive-tabs";

class Competencias extends Component {
    constructor(props) {
        super(props);
        this.state = {
          values:{
            id:0,
            typeOfRequirementId:0,
            title:"",
            listSuppliers:[],
            supplierName:"",
            supplierEmail:"",
          },
          typeOfRequirementIdSE:{id:0, questionnaireId:0, name:""},
          listSuppliers:[],
          listInformedSuppliers:[],
          listOfSuppliers:[],
          idSupplier:{value:0, label:""},
          idCompetenciaSe:{value:0, label:""},
          tipoCompetenciaList:[
            {value:0, label:""},
            {value:1, label:"Competencia abierta"},
            {value:2, label:"Competencia limitada"},
            {value:3, label:"Contratación directa"}
          ],
          loading: false,
          loadingC:false,
          modal: false,
          modalCuestionario: false,
          mensajeOperacion: "",
          textoIngresado:"",
          tableListOfSupplier:"",
        };
    
        this.renderSupplierTable = this.renderSupplierTable.bind(this);
        this.handleAddSupplier = this.handleAddSupplier.bind(this);
        this.handleChangeTipoCompetencia = this.handleChangeTipoCompetencia.bind(this);
        this.handleAddTempSupplier = this.handleAddTempSupplier.bind(this);
        this.handleChangeSupplier = this.handleChangeSupplier.bind(this);
        this.handleSaveCompetencia = this.handleSaveCompetencia.bind(this);
      }

      handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
          values: {
            ...this.state.values,
            [name]: value,
          },
        });
      };

      handleChangeSupplier(data)
      {
        this.setState({idSupplier:data});
      }
      
      handleChangeTipoCompetencia(data)
      {
        this.setState({idCompetenciaSe:data});
      }

      componentDidMount() {
        if(this.props.procurementId>0)
        {
          this.getInformacionGeneral(this.props.procurementId);
          this.getSuppliersInformed(this.props.procurementId);
        }

        this.getListOfSupplier(1);
        this.getSuppliers();
      }

      EditPregunta(idPregunta, texto){
        this.setState({pregunta:{id:idPregunta, questionnaireId:0, name:texto}});
        this.setState({textoIngresado:texto});
        this.toggleCuestionario();
      }

      getTabs() {

        let valores = [];
        let item = {
            title: <div><span>{"proveedor registrado"}&nbsp;</span>{/*}<div className="badge rounded-pill bg-danger ms-0 me-2">{"1"}</div>{*/}</div> ,
            content: <div>
                    <Row className={"m-2 pb-2"}>
                      <Col  md={12}>
                        <div className="mb-2">
                        <FormGroup>
                        <Select
                        defaultValue={this.state.idSupplier}
                        isDisabled={!this.props.editProccess}
                        id="templateId"
                        name="templateId"
                        placeholder="Please select item"
                        isSearchable={true}
                        onChange={this.handleChangeSupplier}
                        options={this.state.listSuppliers}
                        />                        
                        </FormGroup>
                        </div>
                    </Col>
                    <Col md={12}>
                      <Button color="primary" 
                      disabled={!this.props.editProccess}
                      onClick={()=>this.handleAddSupplier()}
                      >
                      {"Agregar proveedor"}
                      </Button>{" "}                                
                    </Col>
                    </Row>
                    </div>,
          }
        valores.push(item);
        item = {
            title: <div><span>{"Proveedor no registrado"}&nbsp;</span></div> ,
            content:<div>
                        <Row className={"m-2 pb-2"}>
                              <Col  md={12}>
                                  <div className="mb-2">
                                  <FormGroup>
                                  <Label for="idModulo">{"Email"}</Label>
                                  <input
                                  type="text"
                                  disabled={!this.props.editProccess}
                                  className="form-control"
                                  id="supplierEmail"
                                  aria-describedby="emailHelp"
                                  name="supplierEmail"
                                  value={this.state.supplierEmail}
                                  ref="supplierEmail"
                                  //readOnly={true}
                                  //readOnly={!this.state.editProccess}
                                  onChange={this.handleChange}
                                  ></input>                    
                                  </FormGroup>
                                  </div>
                                  <div className="mb-2">
                                  <FormGroup>
                                  <Label for="idModulo">{"Nombre del proveedor"}</Label>
                                  <input
                                  type="text"
                                  disabled={!this.props.editProccess}
                                  className="form-control"
                                  id="supplierName"
                                  aria-describedby="emailHelp"
                                  name="supplierName"
                                  value={this.state.supplierName}
                                  ref="supplierName"
                                  //readOnly={true}
                                  //readOnly={!this.state.editProccess}
                                  onChange={this.handleChange}
                                  ></input>                       
                                  </FormGroup>
                                  </div>                                    
                              </Col>                   
                              <Col md={12}>
                                <Button color="primary" 
                                disabled={!this.props.editProccess}
                                onClick={()=>this.handleAddTempSupplier()}
                                >
                                {"Agregar proveedor"}
                                </Button>{" "}                                
                              </Col>             
                          </Row> 
            </div>,
          }
        valores.push(item);
        /*item = {
            title: <div><span>{"Listas aprobadas"}&nbsp;</span></div> ,
            content: <div>{this.state.tableListOfSupplier}</div>,
          }
        valores.push(item);*/
    
        return valores.map((tab, index) => ({
          title: tab.title,
          getContent: () => tab.content,
          key: index,
        }));
      }

      renderSupplierTable(suppliers) {
              const columns = [
                {
                  name: "",
                  width: "5rem",
                  cell: (row) => {
                    return (
                      <Fragment>
                        <button
                          className="btn btn-outline-primary"
                          onClick={(id) => this.EditTipoProceso(row.id, row.name, row.description, row.code, row.templateId, row.daysLimitOffers, row.daysLimitDeclarations)}
                        >
                          <FaPencilAlt size={16} />
                        </button>
                        &nbsp;
                      </Fragment>
                    );
                  },
                },
                {
                  name: this.props.t('Name'),
                  id: "code",
                  //width: "25rem",
                  selector: (row) => row.supplierName,
                  sortable: true,
                },
                {
                  name: this.props.t('Email'),
                  //width: "10rem",
                  selector: (row) => row.supplierEmail,
                  sortable: true,
                },
              ];
          
              return (
                <div>
                  <DataTable
                    data={suppliers}
                    columns={columns}
                    pagination
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Registros por pagina:",
                      rangeSeparatorText: "de",
                    }}
                  />
                </div>
              );
      }

      renderListSupplierTable(suppliers) {
        const columns = [
          {
            name: "",
            width: "5rem",
            cell: (row) => {
              return (
                <Fragment>
                  <button
                    className="btn btn-outline-primary"
                    onClick={(id) => this.EditTipoProceso(row.id, row.name, row.description, row.code, row.templateId, row.daysLimitOffers, row.daysLimitDeclarations)}
                  >
                    <FaPencilAlt size={16} />
                  </button>
                  &nbsp;
                </Fragment>
              );
            },
          },
          {
            name: this.props.t('Title'),
            id: "code",
            //width: "25rem",
            selector: (row) => row.title,
            sortable: true,
          },
          {
            name: this.props.t('Email'),
            //width: "10rem",
            selector: (row) => row.supplierEmail,
            sortable: true,
          },
        ];
    
        return (
          <div>
            <DataTable
              data={suppliers}
              columns={columns}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              paginationComponentOptions={{
                rowsPerPageText: "Registros por pagina:",
                rangeSeparatorText: "de",
              }}
            />
          </div>
        );
}

      render() {
        let contents = this.state.loading ? (
          <p>
            <em>{this.props.t('Loading')}...</em>
          </p>
        ) : (
          this.renderSupplierTable(this.state.listInformedSuppliers===null?[]:this.state.listInformedSuppliers)
        );

        let contentsComp = this.state.loadingC ? (
          <p>
            <Loader type="ball-pulse" /><em>Cargando...</em>
          </p>
        ) : (
          <Card className="card card-nav-tabs mb-4">
          <CardBody  className="p-3">
          <div className="mb-2">
              <label htmlFor="exampleInputEmail1" className="form-label">
                  {"Tipo de competencia"}
              </label>
              <Select
              defaultValue={this.state.idCompetenciaSe}
              isDisabled={!this.props.editProccess}
              id="metodo"
              name="metodo"
              placeholder="Please select item"
              isSearchable={true}
              //isDisabled={!this.state.editProccess}
              onChange={this.handleChangeTipoCompetencia}
              options={this.state.tipoCompetenciaList}
              /> 
          </div>                    
      <Row>
      </Row>  
          {this.state.loading ? <div><Loader type="ball-beat" />
                  <p>
                  <em>Registrando cambios...</em>
                  </p></div> : ""}
          </CardBody>
          <CardFooter>
          <Button color="primary" 
              disabled={!this.props.editProccess}
              onClick={this.handleSaveCompetencia}
              >
              {"Guardar"}
              </Button>{" "}
          </CardFooter>
      </Card>    
        );        

        return (
                <div>
                  <div>  
                  <Container fluid>   
                  {contentsComp}
                  {this.state.idCompetenciaSe.value>0?
                    <div className="card card-nav-tabs mt-4 pt-2">
                      <div className=""></div>
                        <Tabs tabsWrapperClass="nav-tabs-navigation card-header" transform={false}
                        showInkBar={true} items={this.getTabs()}/>
                    </div>:""
                    }
                  </Container>
                  </div>  
                  <Card className="main-card m-2 p-2">
                  <Row>
                    <Col md="12">
                        <h3 style={{paddingLeft: "10px", paddingTop:"10px"}}>{this.props.t('Lista de proveedores!!')}</h3>
                        <div className="px-0">
                            {contents} <br/>
                        </div>
                    </Col>
                  </Row> 
                  </Card>                 
                </div>
        );
      }

      async getListOfSupplier(idOficina) {
        try {
          if (idOficina == null) idOficina = 0;
      
          const bod = { Id: 0, Name:"", ProcurementId:0, LisOfSupplierId:0,};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_SUPPLIER;

            customAxios.patch(url,
                values,
                { headers }).then((res) => {
                this.setState({ listOfSuppliers: res.data, loading: false });
                this.setState({tableListOfSupplier: this.renderListSupplierTable(res.data)})
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }

    validarEmail(email) {
        // Expresión regular para validar un correo electrónico
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    }

      async handleAddSupplier() {
        try {
          if(this.state.idSupplier.value<=0)
            {
              Swal("Error","Indique un proveedor registrado","error");
              return;
            }
  
          const bod = {  Id: 0, 
            ProcurementId:this.props.procurementId, 
            SupplierId:this.state.idSupplier.value,
            Status:1,
        };
          const values = JSON.stringify(bod);
          
          this.setState({loading: false});
      
          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
            
              const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            this.setState({loading: true});
    
            let url = process.env.REACT_APP_API + config.API_ESOURCING_SUPPLIER;
            customAxios.put(url,
              values,
                { headers }).then((res) => {
                    this.setState({loading: false});
                    if (!res.data.operacionConExito) {
                      Swal("Error",res.data.error,"error");
                      this.setState({loading: false});
                    } 
                    else {
                    this.setState({loading: false});
                    Swal("Registro exitoso!!","¡Lista de proveedores registrado con éxito" ,"success");
                    this.getSuppliersInformed(this.props.procurementId);
                    }
                })
              .catch((err) => {
                this.setState({loading: false});
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }   
      
      async handleAddTempSupplier() {
        try {
          if(this.state.values.supplierName==='')
            {
              Swal("Error","Por favor indique el nombre del proveedor","error");
              return;
            }

            if(this.state.values.supplierEmail==='')
              {
                Swal("Error","Por favor indique el email del proveedor","error");
                return;
              }

              if(!this.validarEmail(this.state.values.supplierEmail))
                {
                  Swal("Error","El email no es válido.","error");
                  return;
                }              

            const bod = {  Id: 0, 
            ProcurementId:this.props.procurementId, 
            SupplierId:0,
            SupplierName:this.state.values.supplierName,
            SupplierEmail:this.state.values.supplierEmail,
            ProcurementId:this.props.procurementId,
            TypeSupplier:"Informed",
            Status:1,
        };
          const values = JSON.stringify(bod);
          
          this.setState({loading: false});
     
          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
            
              const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            this.setState({loading: true});
    
            let url = process.env.REACT_APP_API + config.API_ESOURCING_SUPPLIER+"/addTempSupplier";
            customAxios.post(url,
              values,
                { headers }).then((res) => {
                    this.setState({loading: false});
                    if (!res.data.operacionConExito) {
                      Swal("Error",res.data.error,"error");
                      this.setState({loading: false});
                    } 
                    else {
                    this.setState({loading: false});
                    Swal("Registro exitoso!!","¡Lista de proveedores registrado con éxito" ,"success");
                    this.getSuppliersInformed(this.props.procurementId);
                    }
                })
              .catch((err) => {
                this.setState({loading: false});
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      } 

      async getSuppliersInformed(procurementId) {
        try {
      
          const bod = { Id: 0, Name:"", ProcurementId:procurementId, LisOfSupplierId:0};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
              const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_SUPPLIER + '/getSupplierInformed';

            customAxios.post(url,
                values,
                { headers }).then((res) => {
                this.setState({ listInformedSuppliers: res.data, loading: false });
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }    

      async getSuppliers() {
        try {
      
          const bod = { Id: 0, Name:"", ProcurementId:0, LisOfSupplierId:0};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
              const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_SUPPLIER + '/getSupplier';

            customAxios.post(url,
                values,
                { headers }).then((res) => {
                
                let valores = [];
                let item = {
                  value: 0,
                  label: "",
                };
                res.data.forEach(element => {
                    let item = {value: element.id, label: element.name + " " + element.email , email: element.email}
                    valores.push(item);
                });
      
                this.setState({listSuppliers: valores});
                
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }      

      async handleSaveCompetencia() {
        try {
          
              if(this.state.idCompetenciaSe.value <=0)
              {
                Swal("Advertencia","Por favor indique el tipo de competencia para el proceso.","warning");
                return;
              }
    
    
          const bod = { ProcurementId: this.props.procurementId, 
            TipoCompetencia: this.state.idCompetenciaSe.value
        };
          const values = JSON.stringify(bod);
          //console.log(bod)
          
          //console.log(values)
          //return;
          this.setState({loading: false});
      
          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
            
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            this.setState({loading: true});
            let url = process.env.REACT_APP_API + config.API_ESOURCING_PROCUREMENT+"/addCompetencia";
            customAxios.post(url,
              values,
                { headers }).then((res) => {
                    this.setState({loading: false});
                    if (!res.data.operacionConExito) {
                      Swal("Error",res.data.error,"error");
                      this.setState({loading: false});
                    } 
                    else {
                    this.setState({loading: false});
                    Swal("Registro exitoso!!","¡El tipo de competencia se ha registrado con éxito." ,"success");
                    this.getInformacionGeneral(this.props.procurementId)
                    }
                })
              .catch((err) => {
                this.setState({loading: false});
                console.log("AXIOS ERROR: ", err);
                //this.setState({loadingG: false});
              })
        } catch (error) {
          console.log(error);
        }
      } 

      async getInformacionGeneral(procurementId) {
        try {
    
          const bod = { Id: procurementId, Title:"", TipoConsulta:""};
          const values = JSON.stringify(bod);
          //console.log(bod)
    
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
    
              this.setState({loadingC: true });
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_GENERALINFO;
            customAxios.patch(url,
                values,
                { headers }).then((res) => {
    //console.log(res.data)
                if(res.data)
                {
                  if(res.data[0].typoCompetenciaId)
                  {
                    //console.log("viene dato")
                    let existe = this.state.tipoCompetenciaList.find(element => element.value === res.data[0].typoCompetenciaId);
                    if(existe)
                      {
                        //console.log("encontro dato")
                        this.setState({idCompetenciaSe:existe});
                      }
  
                  }
                }
    
                this.setState({ loadingC: false });
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loadingC: false });
              })
        } catch (error) {
          this.setState({loadingC: false });
          console.log(error);
        }
      }      
}

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

    export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(Competencias)));    