import React, { Component, Fragment } from "react";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { Button, Row, Col, Card, CardBody, CardHeader, CardFooter,Collapse} from "reactstrap";
import {FaPencilAlt, FaCheckSquare, FaRegSquare} from "react-icons/fa";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import Loader from "react-loaders";
import TextareaAutosize from "react-textarea-autosize";
import Swal from 'sweetalert';

class ExpresionInteres extends Component {
    constructor(props) {
        super(props);
        this.state = {
          values:{
            id:0,
            idEtapa:0,
            description:"",
            tipoCriterio:"Aceptación/Rechazo",
            loteId:0,
            listLotes:[],
          },
          accordion: [true, false, false, false, false],
          idEtapaSe:{value:0, label:""},
          idLoteSe:{value:0, label:""},
          interestList:[],
          listSubProcesos: [],
          rowsSelect:[],
          tableLotes:"",
          loading: false,
          modal: false,
          modalMsg: false,
          modalCriterio: false,
          mensajeOperacion: "",
        };
    
        this.renderExpresionesTable = this.renderExpresionesTable.bind(this);
      }

      toggleAccordion(tab) {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
    
        this.setState({
          accordion: state,
        });
      }

      handleChangeEtapa(data)
      {
        let valor = this.state.values;
        valor.idEtapa = data.value;
    
        this.setState({values:valor});
        this.setState({idEtapaSe:data});
      }

      handleChangeLote(data)
      {
        let valor = this.state.values;
        valor.loteId = data.value;
    
        this.setState({values:valor, idLoteSe:data});
      }
      
      handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
          values: {
            ...this.state.values,
            [name]: value,
          },
        });
      };

      toggle() {
        this.setState({
          modal: !this.state.modal,
        });
      }

      toggleMsg() {
        this.setState({
          modalMsg: !this.state.modalMsg,
        });
      }

      toggleCriterio() {
        this.setState({
          modalCriterio: !this.state.modalCriterio,
        });
      }

      componentDidMount() {
        //console.log(this.props.procurementId)
        this.getExpresiones(this.props.procurementId);
        //this.getLotes(this.props.procurementId);
      }

      EnviarProcurementPage(procurementId){
        if(procurementId>0)
        {
            var data = {procurementId:procurementId};
            this.props.history.push({
                pathname: "/apps/procurement",
                query:data,
              })
        }
    }

    renderExpresionesTable(listCriterion) {
        const columns = [
          {
            name: this.props.t('Nombre de la empresa'),
            id: "code",
            //width: "25rem",
            selector: (row) => row.supplierName,
            sortable: true,
          },
          {
            name: this.props.t('Fecha de expresión de interés'),
            //width: "10rem",
            selector: (row) => row.dateExpressedInterest,
            sortable: true,
          },
          {
            name: this.props.t('Fecha de confirmación de interés'),
            //width: "15rem",
            selector: (row) => row.dateConfirmInterest,
            sortable: true,
          },
          {
            name: this.props.t('Fecha de confirmación de intención de no presentar oferta'),
            //width: "15rem",
            selector: (row) => row.dateCanceledOffer,
            sortable: true,
          },      
          {
            name: this.props.t('Motivo de no presentar oferta'),
            //width: "15rem",
            selector: (row) => row.reasonCanceledOffer,
            sortable: true,
          },                 
        ];
    
        return (
          <div>
            <DataTable
              data={listCriterion}
              columns={columns}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Registros por pagina:",
                rangeSeparatorText: "de",
              }}
            />
          </div>
        );
      }

      render() {
        let contents = this.state.loading ? (
          <p>
            <em>{this.props.t('Loading')}...</em>
          </p>
        ) : (
          this.renderExpresionesTable(this.state.interestList)
        );
    
        return (
                <div>
                  <Card style={{margin:"5px"}}>
                  <Row>
                    <Col md="12">
                        <h3 className="p-4">{this.props.t('expresiones de interés!!')}</h3>
                        <div className="px-0">
                          <CardBody className="px-2">
                           
                            {this.state.interestList[0]?.lotesList?.map((lote, index) => (
                            <Col md="12" key={index}>
                              <div id="accordion" className="accordion-wrapper mb-3">
                                      <Card className="main-card mb-3">
                                        <CardHeader id="headingOne" color="primary">
                                          <Button block color="link" className="text-start m-0 p-0" onClick={() => this.toggleAccordion(index)}
                                            aria-expanded={this.state.accordion[index]} aria-controls="collapseOne">
                                            <div className="widget-heading">
                                                  {lote.description}
                                                  </div>
                                          </Button>
                                        </CardHeader> 
                                        <Collapse isOpen={this.state.accordion[index]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                                        <CardBody>
                                                  {this.renderExpresionesTable(lote.suppliersInSubProccess)}
                                        </CardBody>
                                        <CardFooter className="d-block text-end">
                                            <div className="widget-content-right">
                                            {/*}<Button className="mb-2 me-2 btn-icon" color="link" onClick={(id) => this.handleAddDetail(myCategory.Id)}>
                                              <i className="pe-7s-plus btn-icon-wrapper"> </i>
                                              {this.props.t('Question')}
                                            </Button>{*/}
                                            </div>         
                                        </CardFooter>
                                        </Collapse>
                                      </Card>
                              </div>
                            </Col>
                            ))}                             
                            
                            {contents}
                          </CardBody>
                        </div>
                    </Col>
                  </Row>
                  </Card>
                </div>
        );
      }

      async getExpresiones(procurementId) {
        try {
      
          const bod = { Id: this.props.procurementId, Title:"", TipoConsulta:""};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_REVIEWS;

            customAxios.patch(url,
                values,
                { headers }).then((res) => {
                this.setState({ interestList: res.data, loading: false });
                console.log(res.data)
                /*let valores = [];
                let item = {
                  value: 0,
                  label: "",
                };
                res.data.forEach(element => {
                    let item = {value: element.id, label: this.props.t(element.name)}
                    valores.push(item);
                });
      
                this.setState({ProjectsV: valores});*/
                //this.setState({countItems: valores.length});
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }
}

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

    export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(ExpresionInteres)));    