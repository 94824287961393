import React, { Component, Fragment } from "react";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { Button, FormGroup, Row, Col, CardHeader, Card, CardBody, CardFooter, Alert, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Loader from "react-loaders";
import Select from 'react-select';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';


class DetalleFinanciero extends Component {

  constructor(props) {
    super(props);
    this.state = {
      values: {
        id: 0,
      },
      esObligatorio:{value:1, label:"Obligatoria"},
      tipoPrecio:{value:0, label:""},
      procurementId:0,
      detallesFinancieros:[{value:1, label:"Obligatoria"},{value:2, label:"No obligatoria"}],
      priceTypes:[{value:0, label:""},{value:1, label:"Oferta financiera total"},{value:2, label:"Precio unitario"}],
      infoGenral:[],
      loading: false,
      modalMsg: false,
      mensajeOperacion: "",
      };

    this.toggleMsg = this.toggleMsg.bind(this);
    this.handleChangeEsObligatorio = this.handleChangeEsObligatorio.bind(this);
    this.handleChangeTipoPrecio = this.handleChangeTipoPrecio.bind(this);
    this.handleSaveGeneraDetalleFinanciero = this.handleSaveGeneraDetalleFinanciero.bind(this);
  }

  handleChangeEsObligatorio(data)
  {
    this.setState({esObligatorio:data});
  }

  handleChangeTipoPrecio(data)
  {
    this.setState({tipoPrecio:data});
  }

  toggleMsg() {
    this.setState({
      modalMsg: !this.state.modalMsg,
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      values: {
        ...this.state.values,
        [name]: value,
      },
    });
    //console.log(this.state.values)
  };

  componentDidMount() {
    //console.log(this.props.procurementId)
    this.setState({procurementId:this.props.procurementId});
    this.getInformacionGeneral(this.props.procurementId);
  }


  render() {
    let contents = this.state.loading ? (
      <p>
        <Loader type="ball-pulse" /><em>Cargando...</em>
      </p>
    ) : (
      <Card className="main-card mb-1 pt-4">
      <CardHeader><p>Detalle de la oferta financiera en la respuesta del proveedor</p></CardHeader>
          <CardBody  className="p-3">
          <Row>
                  <Col  md={12}>
                      <div className="mb-2">
                      <FormGroup>
                      <Alert className="mbg-3" color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
                          <span className="pl-2">
                          {/*}<FontAwesomeIcon icon={faQuestionCircle} />{*/}
                          </span>
                          Seleccione una de las dos opciones a continuación. Si selecciona Obligatorio, los proveedores deberán obligatoriamente incluir en su respuesta los detalles de la oferta financiera (oferta financiera total, divisa, comentarios).
                          Si selecciona No obligatorio, los proveedores no deberán incluir estos detalles en su respuesta.
                          Se recomienda que seleccione esta última opción para casos complejos que no son evaluados en base al precio total, por ejemplo para el establecimiento de Acuerdos a largo plazo u Oferta abierta.
                      </Alert>                                    
                      </FormGroup>
                      </div>
                  </Col>
                  <Col  md={12}>
                      <div className="mb-2">
                      <FormGroup>
                      <Select
                      defaultValue={this.state.esObligatorio}
                      isDisabled={!this.props.editProccess}
                      id="metodo"
                      name="metodo"
                      placeholder="Please select item"
                      isSearchable={true}
                      onChange={this.handleChangeEsObligatorio}
                      options={this.state.detallesFinancieros}
                      />                        
                      </FormGroup>
                      </div>
                  </Col>  
                  {this.state.esObligatorio.value===1?                          
                  <Col  md={12}>
                      <div className="mb-2">
                      <FormGroup>
                      <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      >
                          {"Tipo de precio"}
                      </label>
                      <Select
                      defaultValue={this.state.tipoPrecio}
                      isDisabled={!this.props.editProccess}
                      id="metodo"
                      name="metodo"
                      placeholder="Please select item"
                      isSearchable={true}
                      onChange={this.handleChangeTipoPrecio}
                      options={this.state.priceTypes}
                      />                        
                      </FormGroup>
                      </div>
                  </Col>:""}
          </Row>                        

              {this.state.loading ? <div><Loader type="ball-beat" />
              <p>
              <em>Sending request...</em>
              </p></div> : ""}
          </CardBody>
          <CardFooter>
              <Button color="primary" 
                  disabled={!this.props.editProccess}
                  onClick={this.handleSaveGeneraDetalleFinanciero}
                  >
              {"Guardar"}
              </Button>{" "}
          </CardFooter>
      </Card>
    );

    const mensajeOperacion = this.state.mensajeOperacion;

    return (
    <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            classNames="TabsAnimation"
            appear={true}
            timeout={1500}
            enter={false}
            exit={false}
          >     
          {contents}
        </CSSTransition>
        </TransitionGroup>
        <Modal
            isOpen={this.state.modalMsg}
            toggle={this.toggleMsg}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleMsg}>{this.props.t('Project')}s</ModalHeader>
            <ModalBody>
              <p>{this.state.mensajeOperacion}</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggleMsg}>
              {this.props.t('OK')}
              </Button>
            </ModalFooter>
          </Modal>        
    </Fragment>        
    );
  }

  async getInformacionGeneral(procurementId) {
    try {
      let supplierId =0;
      /*if(rolId === 12)
        supplierId = this.props.auth.user.supplierId;*/

      const bod = { Id: procurementId, Title:"", TipoConsulta:""};
      const values = JSON.stringify(bod);
      //console.log(bod)

        const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.auth.accessToken,
          }

          this.setState({loading: true });
        const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
        let url = process.env.REACT_APP_API + config.API_ESOURCING_GENERALINFO;
        customAxios.patch(url,
            values,
            { headers }).then((res) => {

            if(res.data)
            {

              let detalle={}
              if(res.data[0].financialDetMandatory)
              {
                if(res.data[0].financialDetMandatory===true)
                  detalle = {value:1, label:"Obligatoria"}
                else
                  detalle = {value:2, label:"No obligatoria"}
              }
              else
                detalle = {value:0, label:""}
                
                //console.log(detalle)
                this.setState({esObligatorio:detalle});

              if(res.data[0].priceTypeId)
              if(res.data[0].priceTypeId>0)
              {
                let existe = this.state.priceTypes.find(element => element.value === res.data[0].priceTypeId);
                //console.log(existe)
                //console.log(res.data)
                if(existe)
                {
                  this.setState({tipoPrecio:existe});
                }
                  else
                  this.setState({tipoPrecio:{value:0, label:""}});
              }
              else
              this.setState({tipoPrecio:{value:0, label:""}});

            }

            this.setState({ loading: false });

            /*let valores = [];
            let item = {
              value: "",
              label: "Seleccione DDT",
            };
            valores.push(item);
            res.data.forEach(element => {
                let item = {value: element.purchaseOCode, label:element.purchaseOCode}
                valores.push(item);
            });
  
            this.setState({DDTs: valores});*/

            //console.log(res.data)
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.setState({loading: false });
          })
    } catch (error) {
      console.log(error);
    }
  }


  async handleSaveGeneraDetalleFinanciero() {
    try {
      
          if(this.state.values.esObligatorio.value <=0)
          {
            this.setState({ mensajeOperacion: "Por favor indique si el detalle financiero es o no obligatorio." });
            this.toggleMsg();
            return;
          }


      const bod = { ProcurementId: this.props.procurementId, 
        IsMandatory:this.state.values.esObligatorio.label ==='Obligatoria'?true:false, 
        PriceTypeId: this.state.values.tipoPrecio.value
    };
      const values = JSON.stringify(bod);
      //console.log(bod)
      
      //console.log(values)
      //return;
      this.setState({loading: false});
  
      const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.auth.accessToken,
          }
        
        const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
        this.setState({loading: true});
        let url = process.env.REACT_APP_API + config.API_ESOURCING_PROCUREMENT;
        customAxios.put(url,
          values,
            { headers }).then((res) => {
                this.setState({loading: false});
                if (!res.data.operacionConExito) {
                  this.setState({ mensajeOperacion: res.data.error });
                  this.setState({loading: false});
                  this.toggleMsg();
                } 
                else {
                this.setState({loading: false});
                this.setState({ mensajeOperacion: "Documento agregada con éxito." });
                //this.toggleDDT();
                this.toggleMsg();
                //this.getProducts(1);
                }
            })
          .catch((err) => {
            this.setState({loading: false});
            console.log("AXIOS ERROR: ", err);
            //this.setState({loadingG: false});
          })
    } catch (error) {
      console.log(error);
    }
  } 

}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
    return {
      setToken: (token) => {
        dispatch(setToken(token));
      }
    }
  };

export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(DetalleFinanciero)));
