import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import config from "../../config.json";
import axios from 'axios';
import HeaderLogo from "../../Layout/AppLogo";
import logo from "../../assets/utils/images/Logo-Proeri.png";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cx from "classnames";
import { Button, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

class PublicPage extends Component {

      constructor(props) {
            super(props);      
            this.state = {                  
                  loading : true,
                  tblProcurement : [],                                     
            };  
            this.onClickExpresarInteres = this.onClickExpresarInteres.bind(this);          
      }

      

      componentDidMount() {
            axios.patch(config.API_PUBLIC)
                  .then((response) => {
                        this.setState({ tblProcurement: response.data});
                  })
      }

      onClickExpresarInteres(IdProcedurement){
            console.log('Expresar Interes: '+IdProcedurement)            
            this.props.history.push('/login?idprocedurement='+IdProcedurement);
      }

      renderHeaderPage(){
            return (
            <TransitionGroup>   
                  <CSSTransition component="div"className="app-header header-shadow"appear={true} timeout={1500} enter={false} exit={false}>
                        <div>
                              <div className="app-header__logo">   
                                    <img src={logo} alt="react logo" style={{ width: '60px', }}/>                                                                     
                              </div>
                              <div className="widget-heading"></div>
                              <div className="app-header__content">                                    
                                    <div className="app-header-left text-center">
                                          {/*}<MegaMenu />{*/}
                                          <h2>Listado de licitaciones</h2>
                                    </div>
                                    <div className="app-header-right">
                                          <span className="input-group-text">
                                                <FontAwesomeIcon icon={faUser} />
                                                <Link to="/login" >Iniciar Sesión</Link>
                                          </span>
                                          
                                    </div>
                              </div>
                        </div>
                  </CSSTransition>                                               
            </TransitionGroup>
            );
      }

      renderLicitaciones(){ 
            const columns = [ 
                  {
                        name: '',         
                        width: "10rem",
                        cell: (row) => {
                              return (
                                <Fragment>
                                  <Button size='sm' onClick={() => this.onClickExpresarInteres(row.id) }>
                                    Expresar Interés
                                  </Button>
                                </Fragment>
                              );
                            },
                  },
                  {
                        name: "No",
                        id: "id",
                        selector: (row) => row.id ,
                        sortable: true,
                        omit:true
            
                  },
                  {
                        name: "Titulo",
                        id: "title",
                        selector: (row) => row.title ,
                        sortable: true,
                        wrap: true
                  },
                  {
                        name: "Creado",
                        id: "createdOn",
                        selector: (row) => row.createdOn ,
                        sortable: true,
                  },
                  {
                        name: "Organizacion",
                        id: "organizationalUnit",
                        selector: (row) => row.organizationalUnit,
                        sortable: true,
                  },
                  {
                        name: "Referencia",
                        id: "reference",
                        selector: (row) => row.reference ,
                        sortable: true,
                  },
                  {
                        name: "Fecha limite para ofertas",
                        id: "deadlineToOffers",
                        selector: (row) => row.deadlineToOffers ,
                        sortable: true,
                  },
                  {
                        name: "Fecha limite para aclaraciones",
                        id: "deadlineForClarifications",
                        selector: (row) => row.deadlineForClarifications,
                        sortable: true,
                  }
            ];

            return (
                  <DataTable
                  data={this.state.tblProcurement}
                  columns={columns}
                  pagination
                  paginationPerPage={5}                              
                  paginationRowsPerPageOptions={[5, 15, 25, 50]}
                  paginationComponentOptions={{
                        rowsPerPageText: "Registros por pagina:",
                        rangeSeparatorText: "de",
                  }} />
            )
      }


      render (){
            return (
                  <div>
                        <div className="app-container fixed-header ">
                              {this.renderHeaderPage()}
                              <div className="app-main">
                                    <div className="app-main__outer">
                                          <div className="app-main__inner">
                                               {this.renderLicitaciones() }
                                          </div>                        
                                    </div>                              
                              </div>  
                        </div>    
                  </div>
            );
      }           
}

export default withRouter(PublicPage);
