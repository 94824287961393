import React, { Component,Fragment } from "react";
import DataTable from "react-data-table-component";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {  FaFilePdf, FaTrash, FaCheck} from "react-icons/fa";
import {Button,Row, Col,FormGroup, Label, Input,Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody,Alert} from "reactstrap";
import Loader from "react-loaders";

class Documentos extends Component{
    constructor(props) {
        super(props);
        this.state = {
          values:{
            descriptionDoc:"",
          },
          expedientes:[],
          loading: false,
          tipoDocumento:0,
          fileCarga: {},
          tiposDoc:[],
          codigoDDT:"",
          mensajeOperacion: "",
          modalMsg:false,
      };

      this.toggleMsg = this.toggleMsg.bind(this);
      }
    
      componentDidMount() {
        //this.setState({codigoDDT:this.props.codigoDDT});
        this.obtenerExpedientes(this.props.procurementId);
        //console.log(this.props.driveFolderId)
      }    

      handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
          values: {
            ...this.state.values,
            [name]: value,
          },
        });
        //console.log(this.state.values)
      };

      toggleMsg() {
        this.setState({
          modalMsg: !this.state.modalMsg,
        });
      }

      handleTipoDocumento = (e) => {
        const { name, value } = e.target;
        this.setState({ tipoDocumento: value });
      };

      handleUpload = (e) => {
        e.preventDefault();
    
        this.UploadFileGuarantee();
      };

      setFile(e) {
        this.setState({ fileCarga: {mimeType: e.target.files[0].type, fileCarga:e.target.files[0], name:e.target.files[0].name} });
      }

      async obtenerExpedientes(idDDT) {
        try {
    
          const bod = { Id: 0, ProcurementId:this.props.procurementId};
          const values = JSON.stringify(bod);
   
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
             
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_ANNEXDOC;
            customAxios.post(url,
                values,
                { headers }).then((res) => {
                this.setState({ expedientes: res.data, loading: false });
                //console.log(res.data)
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }

      async GetFileToDrive(urlFile) {
        try {
    
          const bod = { "url": urlFile};
          const values = JSON.stringify(bod);
    
          const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.auth.accessToken,
          }
    
        const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
              
            let url = process.env.REACT_APP_API + config.API_ESOURCING_ANNEXDOC;
          customAxios.put(url,
              values,
              { headers }).
            then((res) => {
              this.setState({ base64File: res.data, loading: false });
              //console.log(this.state.base64File);
              
                const container = document.querySelector('#container');
                const tempLink = document.createElement('a');
                tempLink.href = `data:application/pdf;base64,${this.state.base64File}`;
                tempLink.setAttribute('download', 'doc.pdf');
                tempLink.click();
  
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
            })
        } catch (error) {
          console.log(error);
        }
      }

      async UploadFileGuarantee() {
        try {
          if(this.state.fileCarga.fileCarga ==null)
          {
            this.setState({ mensajeOperacion: "Por favor cargue un documento."});
            this.toggleMsg();
            return;
          }

        const formData = new FormData();
        formData.append("Id", 0);
        formData.append("ProcurementId", this.props.procurementId);
        formData.append("DocumentTypeId", 3);
        formData.append("DriveFolderId", this.props.driveFolderId);
        formData.append("Name", this.state.values.descriptionDoc);
        formData.append("MimeType", this.state.fileCarga.mimeType);
        formData.append("FilePh", this.state.fileCarga.fileCarga);
        formData.append("Status", 1);
    
        //console.log(formData);
    
        this.setState({loading: false});
    
        const headers= {
          "Access-Control-Allow-Origin": true,
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.auth.accessToken,
        }
  
      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            
          let url = process.env.REACT_APP_API + config.API_ESOURCING_ANNEXDOC;
          this.setState({loading: true});
          customAxios.patch(url,
            formData,
              { headers }).
            then((res) => {
    
              if (!res.data.operacionConExito) {
                this.setState({ mensajeOperacion: res.data.error });
                this.toggleMsg();
              } 
              else {
              this.setState({loading: false});
              this.setState({ mensajeOperacion: "Expediente cargado con éxito." });
              
              this.setState({ fileCarga: null});
              this.obtenerExpedientes(this.props.codigoDDT);
              this.toggleMsg();
              }
            })
            .catch((err) => {
              this.setState({loading: false});
              console.log("AXIOS ERROR: ", err);
            })
      } catch (error) {
        console.log(error);
      }
     }      

     async DarDeBajaDocumento(id, estado)
     {
      try {

      const formData = new FormData();
      formData.append("Id", id);
      formData.append("ProcurementId", this.props.procurementId);
      formData.append("DocumentTypeId", 3);
      formData.append("DriveFolderId", this.props.driveFolderId);
      formData.append("Name", "..");
      formData.append("MimeType", "..");
      formData.append("FilePh", null);
      formData.append("Status", estado);
      //console.log(formData);
  
      this.setState({loading: false});
  
      const headers= {
        "Access-Control-Allow-Origin": true,
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.auth.accessToken,
      }

    const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
          
        let url = process.env.REACT_APP_API + config.API_ESOURCING_ANNEXDOC;
        this.setState({loading: true});
        customAxios.patch(url,
          formData,
            { headers }).
          then((res) => {
  
            if (!res.data.operacionConExito) {
              this.setState({ mensajeOperacion: res.data.error });
              this.toggleMsg();
            } 
            else {
            this.setState({loading: false});
            this.setState({ mensajeOperacion: "Expediente cargado con éxito." });
            
            this.setState({ fileCarga: null});
            this.obtenerExpedientes(this.props.codigoDDT);
            this.toggleMsg();
            }
          })
          .catch((err) => {
            this.setState({loading: false});
            console.log("AXIOS ERROR: ", err);
          })
    } catch (error) {
      console.log(error);
    }
     }

      render() {
        const tExpedientes = this.state.expedientes;
        const columns = [
          {
            name: "",
            width: "7rem",
            cell: (row) => {
              return (
                <Fragment>
                  <button
                    className={row.status===1?"btn btn-outline-primary":"btn btn-outline-light"}
                    onClick={(id) =>
                      this.GetFileToDrive(row.urlDrive)
                    }
                  >
                    <FaFilePdf size={16} />
                  </button>{" "}
                  &nbsp;
                  <button
                    className={row.status===1?"btn btn-outline-danger":"btn btn-outline-primary"}
                    disabled={!this.props.editProccess}
                    onClick={(id) =>
                      this.DarDeBajaDocumento(row.idExpediente, row.status===1?2:1)
                    }
                  >
                  {row.status===1?<FaTrash size={16} />:<FaCheck size={16} />}
                  </button>{" "}

                </Fragment>
              );
            },
          },
          {
              name: "Nombre del documento",
              id: "tipoDocumento",
              selector: (row) => row.tipoDocumento,
              sortable: true,
              //width: "14rem",
            },
            {
              name: "Fecha de importación",
              id: "tipoDocumento",
              selector: (row) => row.fechaDoc,
              sortable: true,
              //width: "14rem",
            },            
          ];

          let tablaDoc = this.state.loading ? (
              <div><Loader type="ball-pulse" />Cargando...</div>
          ) : (
            <DataTable
            data={tExpedientes}
            columns={columns}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 25]}
            paginationComponentOptions={{
              rowsPerPageText: "Registros por pagina:",
              rangeSeparatorText: "de",
            }}
          />
          );

    
        return (
          <div>
            <Card>
              <CardBody>
            <Row>
            <div className="mb-2">
                            <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                            >
                                {"Description"}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="descriptionDoc"
                                aria-describedby="emailHelp"
                                name="descriptionDoc"
                                disabled={!this.props.editProccess}
                                value={this.state.values.descriptionDoc}
                                ref="localAmount"
                                onChange={this.handleChange}
                                ></input>    
                        </div> 
            <Col md={12}>
                  <FormGroup>
                    <Input type="file" name="docFile" id="docFile" ref={"docFile"} 
                      disabled={!this.props.editProccess}
                      onChange={e => this.setFile(e)}/>
                  </FormGroup>                  
                  <FormGroup>
                    <Button color="primary" 
                    disabled={!this.props.editProccess}
                    onClick={this.handleUpload}>
                        Aceptar
                    </Button>{" "}
                  </FormGroup>                  
            </Col>
            </Row>  
            <Row>
              <Col md={12}>
              {tablaDoc}
              </Col>
              <Col md={12}>
              <Alert className="mbg-3 m-2" color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
                <span className="pl-2">
                  {/*}<FontAwesomeIcon icon={faQuestionCircle} />{*/}
                </span>
                {"Si eliminas un documento, en la informacion del proceso ya no lo visualizará el proveedor. Puedes volver a activarla para que lo vea el proveedor."}
              </Alert>            
            </Col>

            </Row>  
            </CardBody>
            </Card>
            <Modal
              isOpen={this.state.modalMsg}
              toggle={this.toggleMsg}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleMsg}>Documentos</ModalHeader>
              <ModalBody>
                <p>{this.state.mensajeOperacion}</p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.toggleMsg}>
                  Aceptar
                </Button>
              </ModalFooter>
            </Modal>

          </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Documentos));