import React, { Component, Fragment } from "react";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { Button, Row, Col, CardHeader, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter,FormGroup, Label} from "reactstrap";
import { FaPencilAlt} from "react-icons/fa";
import DataTable from "react-data-table-component";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import Loader from "react-loaders";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";

class WorkFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {
          values:{
            id:0,
            title:"",
            idTipoProceso:0,
            idUnidadOrg:{value:0, label:""},
            paisesBeneficiarios:{value:0, label:""},
            esPlantilla:false,
            idPlantillaProc:0,
          },
          procurementId:0,
          listTiposProceso:[],
          listPriority:[{value:0, label:""},{value:1, label:this.props.t("Costa Rica")},{value:2, label:this.props.t("Panamá")},{value:3, label:this.props.t("Guatemala")}],
          listUnOrg:[{value:0, label:""},{value:1, label:this.props.t("B5416-LCR, PAMCO, Costa Rica")}],          
          forecasts: [],
          Templates:[],
          listProcurements:[],
          ProjectsV:[],
          WorkFlows:[],
          ProjectsBQ:[],
          tableTemplates:"",
          idTipoProcesoSe:{value:0, label:""},
          idPlantillaSe:{value:0, label:""},
          idProjectBQ:{value: "", label: "", description:"", project_Manager_Name:"", start_date:new Date(), end_date:new Date()},
          loading: false,
          modal: false,
          modalMsg: false,
          modalSubProc: false,
          modalProcurement:false,
          mensajeOperacion: "",
        };
    
        //this.handleEdit = this.handleEdit.bind(this);
        this.renderProcurementsTable = this.renderProcurementsTable.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleMsg = this.toggleMsg.bind(this);
        this.toggleSubProc = this.toggleSubProc.bind(this);
        this.toggleProcurement = this.toggleProcurement.bind(this);
        this.handleChangeUnidadOrg = this.handleChangeUnidadOrg.bind(this);
        this.handlePaisBenef = this.handlePaisBenef.bind(this);
        this.handleTipoProceso = this.handleTipoProceso.bind(this);    
        this.handlePlantilla = this.handlePlantilla.bind(this);
      }

      
      handleEsPlantilla = (e) => {
        let valores = this.state.values;
        valores.esPlantilla = !valores.esPlantilla;
        this.setState({values:valores});
      };

      handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
          values: {
            ...this.state.values,
            [name]: value,
          },
        });
      };
  
      handleChangeUnidadOrg(data)
      {
        let valor = this.state.values;
        valor.idUnidadOrg = data;
  
        this.setState({values:valor});
      }
  
      handlePaisBenef(data)
      {
        let valor = this.state.values;
        valor.paisesBeneficiarios = data;
        this.setState({values:valor});
      }

      handleTipoProceso(data)
      {
        let valor = this.state.values;
        valor.idTipoProceso = data.value;
        this.setState({idTipoProcesoSe:data});
      }

      handlePlantilla(data)
      {
        let valor = this.state.values;
        valor.idPlantillaProc = data.value;
        this.setState({idPlantillaSe:data});
      }

      toggle() {
        this.setState({
          modal: !this.state.modal,
        });
      }

      toggleMsg() {
        this.setState({
          modalMsg: !this.state.modalMsg,
        });
      }

      toggleSubProc() {
        this.setState({
          modalSubProc: !this.state.modalSubProc,
        });
      }      

      toggleProcurement() {
        this.setState({
          modalProcurement: !this.state.modalProcurement,
        });
      }

      componentDidMount() {
        this.getBitacoras(this.props.procurementId);
      }

      EnviarProcurementPage(procurementId, driveFolderId, title, listTransitions){
        if(procurementId>0)
        {
            var data = {procurementId:procurementId, driveFolderId:driveFolderId, title:title, listTransitions:listTransitions};
            this.props.history.push({
                pathname: "/apps/procurement",
                query:data,
              })
        }
    }

    AdminSubProceso(procurementId){
      if(procurementId>0)
      {
        this.setState({procurementId:procurementId})
        this.toggleSubProc();
      }
    }

      renderProcurementsTable(listProcurements) {
        const columns = [
          {
            name: "",
            width: "4rem",
            cell: (row) => {
              return (
                <Fragment>
                  <button
                    className="btn btn-outline-primary"
                    onClick={(id) => this.EnviarProcurementPage(row.id, row.driveFolderId, row.title, row.listTransitions)}
                  >
                    <FaPencilAlt size={16} />
                  </button>
                </Fragment>
              );
            },
          },
          {
            name: this.props.t('Título'),
            id: "code",
            //width: "25rem",
            selector: (row) => row.title,
            sortable: true,
          },
          {
            name: this.props.t('Organismo Ejecutor (Contratante)'),
            //width: "10rem",
            selector: (row) => row.organizationalUnit,
            sortable: true,
          },
          {
            name: this.props.t('Paises beneficiarios'),
            //width: "15rem",
            selector: (row) => row.beneficiaryCountries,
            sortable: true,
          },
          {
            name: this.props.t('Fecha creación'),
            //width: "13rem",
            selector: (row) => row.createdOn,
            sortable: true,
          },
          /*{
            name: this.props.t('ImplementationDateEnd'),
            width: "13rem",
            selector: (row) => row.endDate,
            sortable: true,
          },*/
        ];
    
        return (
          <div>
            <DataTable
              data={listProcurements}
              columns={columns}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Registros por pagina:",
                rangeSeparatorText: "de",
              }}
            />
          </div>
        );
      }

      render() {
        let contents = this.state.loading ? (
          <p><Loader type="ball-pulse" />
            <em>{this.props.t('Loading')}...</em>
          </p>
        ) : (
          this.renderProcurementsTable(this.state.listProcurements)
        );
    
        //const mensajeOperacion = this.state.mensajeOperacion;
    
        return (
          <Fragment>
            <TransitionGroup>
              <CSSTransition
                component="div"
                classNames="TabsAnimation"
                appear={true}
                timeout={1500}
                enter={false}
                exit={false}
              >
                <div>
                  <Row>
                    <Col md="12">
                      <Card className="main-card mb-3">
                        <CardHeader>
                        </CardHeader>
                        <div className="px-0">
                        <CardBody>
                            <CardTitle><p>{this.state.listProcurements.length>0?this.state.listProcurements[0].workFlowTime:""}</p></CardTitle>
                            <VerticalTimeline className="vertical-time-icons" layout="1-column">
                                  {this.state.listProcurements.map((option, index) => (
                                    <VerticalTimelineElement key={index} className="vertical-timeline-item"
                                    
                                    icon={
                                    <div className="timeline-icon border-primary">
                                        <i className="lnr-license text-primary" />
                                    </div>
                                    }>
                                    <Card body className="card-border mb-3" outline color="primary">
                                    <h4 className="timeline-title"> {option.wPhaseName} </h4>
                                    <p>
                                    {option.userName}
                                    </p>
                                    <p>{option.tiempoTarea}</p>
                                    <p>
                                    <Card body className="card-border mb-3" outline color="primary">
                                    <CardTitle>Observaciones</CardTitle>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: option.observations }}>
                                    </div>
                                    </Card>
                                    </p>
                                    </Card>
                                    </VerticalTimelineElement>
                                    ))}
                                    

                            {/*}<VerticalTimelineElement className="vertical-timeline-item"
                                icon={
                                <div className="timeline-icon border-primary">
                                    <i className="lnr-license text-primary" />
                                </div>
                                }>
                                <h4 className="timeline-title">All Hands Meeting</h4>
                                <p>
                                Lorem ipsum dolor sic amet, today at{" "}
                                <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}> 12:00 PM </a>
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement className="vertical-timeline-item"
                                icon={
                                <div className="timeline-icon border-success bg-success">
                                    <FontAwesomeIcon icon={faCoffee} className="text-white"/>
                                </div>
                                }>
                                <h4 className="timeline-title text-success"> FontAwesome Icons </h4>
                                <p>
                                Lorem ipsum dolor sit amit,consectetur elit enim at
                                minim veniam quis nostrud
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement className="vertical-timeline-item"
                                icon={
                                <div className="timeline-icon border-warning bg-warning">
                                    <FontAwesomeIcon icon={faArchive} className="text-white"/>
                                </div>
                                }>
                                <h4 className="timeline-title"> Build the production release </h4>
                                <p>
                                Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                                incididunt ut labore et dolore magna elit enim at
                                minim veniam quis nostrud
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement className="vertical-timeline-item"
                                icon={
                                <div className="timeline-icon bg-danger border-danger">
                                    <i className="pe-7s-cloud-upload text-white" />
                                </div>
                                }>
                                <p>
                                Another meeting today, at{" "}
                                <b className="text-warning">12:00 PM</b>
                                </p>
                                <p>
                                Yet another one, at{" "}
                                <span className="text-dark">15:00 PM</span>
                                </p>
                            </VerticalTimelineElement>{*/}
                            </VerticalTimeline>
                        </CardBody>
                        </div>
                      </Card>
                    </Col>
    
                    <Modal
                      isOpen={this.state.modalMsg}
                      toggle={this.toggleMsg}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleMsg}>{this.props.t('Procesos de licitación')}</ModalHeader>
                      <ModalBody>
                        <p>{this.state.mensajeOperacion}</p>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={this.toggleMsg}>
                        {this.props.t('OK')}
                        </Button>
                      </ModalFooter>
                    </Modal>

                    <Modal
                      isOpen={this.state.modalProcurement}
                      toggle={this.toggleProcurement}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleProcurement}>{this.props.t('Agregar proceso')}</ModalHeader>
                      <ModalBody>
                        <div className="mb-2">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                {"Titulo"}
                              </label>
                              <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        aria-describedby="emailHelp"
                                        name="title"
                                        value={this.state.values.title}
                                        ref="title"
                                        onChange={this.handleChange}
                                        ></input> 
                        </div>                    
                        <div className="mb-2">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                {"Organismo Ejecutor (Contratante)"}
                              </label>
                              <Select
                                  defaultValue={this.state.values.idUnidadOrg}
                                  id="idUnidadOrg"
                                  name="idUnidadOrg"
                                  placeholder="Please select item"
                                  isSearchable={true}
                                  onChange={this.handleChangeUnidadOrg}
                                  options={this.state.listUnOrg}
                                />  
                        </div>                  
                        <Row>
                                <Col  md={12}>
                                <div className="mb-2">
                                <FormGroup>
                                <Label for="idModulo">{"Pais beneficiario"}</Label>
                                <Select
                                  defaultValue={this.state.values.paisesBeneficiarios}
                                  id="paisesBeneficiarios"
                                  name="paisesBeneficiarios"
                                  placeholder="Please select item"
                                  isSearchable={true}
                                  onChange={this.handlePaisBenef}
                                  options={this.state.listPriority}
                                />                        
                                </FormGroup>
                                </div>
                              </Col>
                              <Col  md={12}>
                                <div className="mb-2">
                                <FormGroup>
                                <Label for="idModulo">{"Tipo proceso"}</Label>
                                <Select
                                  defaultValue={this.state.idTipoProcesoSe}
                                  id="tipoProcesoL"
                                  name="tipoProcesoL"
                                  placeholder="Please select item"
                                  isSearchable={true}
                                  onChange={this.handleTipoProceso}
                                  options={this.state.listTiposProceso}
                                />                        
                                </FormGroup>
                                </div>
                              </Col>                              
                              <Col  md={12}>
                                <div className="mb-2">
                                <FormGroup>
                                <Label for="idModulo">{"Plantilla"}</Label>
                                <Select
                                  defaultValue={this.state.idPlantillaSe}
                                  id="templateL"
                                  name="templateL"
                                  placeholder="Please select item"
                                  isSearchable={true}
                                  onChange={this.handlePlantilla}
                                  options={this.state.Templates}
                                />                        
                                </FormGroup>
                                </div>
                              </Col>                              
                              <Col md={12}>
                              <Label for="idModulo">
                                <input
                                    type="checkbox"
                                    id="topping"
                                    name="topping"
                                    value="Paneer"
                                    checked={this.state.values.esPlantilla}
                                    disabled
                                    //onChange={this.handleEsPlantilla}
                                  />
                                  {"Es plantilla"}
                              </Label>
                                </Col>                                                 
                         </Row>
                          {this.state.loading ? <div><Loader type="ball-beat" />
                                  <p>
                                    <em>Creating procurement record...</em>
                                  </p></div> : ""}
                      </ModalBody>
                      <ModalFooter>
                      <Button color="primary" 
                                onClick={this.handleSaveProcurement}
                                >
                              {"Guardar"}
                              </Button>{" "}
                      </ModalFooter>
                    </Modal> 

                  </Row>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Fragment>
        );
      }

      async getBitacoras(procId) {
        try {
      
          const bod = { ProcurementId: procId, 
            StartPhaseId:0, 
            DestinationPhaseId: 0,
            AssignedUserId: this.props.auth.user.idUsuario,
            Observations:" ",
        };

          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_WORKFLOW;
            this.setState({loading:true});  
            
            customAxios.patch(url,
                values,
                { headers }).then((res) => {
                  //console.log(res.data)
                this.setState({ listProcurements: res.data, loading: false });
                this.setState({countItems: res.data.length})
              })
              .catch((err) => {
                this.setState({loading:false});  
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }

}

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

    export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(WorkFlow)));    