import React, { Component, Fragment } from "react";
import { TransitionGroup } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Container, Alert,Breadcrumb, BreadcrumbItem,  Button, Modal, ModalHeader, ModalBody, Row, Col} from "reactstrap";
import cx from "classnames";
import { connect } from "react-redux";
import { setToken } from "../../../actions/authActions";
import { withRouter } from "react-router-dom";
import Tabs from "react-responsive-tabs";
import {faHome, faShareAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../../config.json";
import myCustomAxios from "../../../services/myAxios";
import InfoGeneral from "./components/infoGeneral";
import Criterios from "./components/criterios";
import Cuestionario from "./components/cuestionario";
import Detalles from "./components/detalles";
import ListaVerificacion from "./components/listaVerificacion";
import Documentos from "./components/documentos";
import DetalleFinanciero from "./components/detalleFinanciero";
import Swal from 'sweetalert';
import Select from 'react-select';
import ReactQuill from "react-quill";
import 'quill/dist/quill.snow.css';
import WorkFlow from "./components/workFlow";
import Competencias from "./components/competencias";
import Reviews from "./components/reviews";
import Loader from "react-loaders";
import CondicionesContrato from "./components/condicionesContrato";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ size: [] }],
    [{ font: [] }],
    [{ align: ["right", "center", "justify"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: ["red", "#785412"] }],
    [{ background: ["red", "#785412"] }]
  ]
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
  "size",
  "font"
];

class Procurement extends Component {
  
  constructor(props) {
    super(props);
      this.state = {
          values:{
            id:0,
            description:"",
          },
          wPhaseId:0,
          listUsers:[],
          idUserSe:{value:0, label:""},
          listDestinos:[],
          idDestinoSe:{value:0, label:""},
          procurementId:0,
          procCode:"",
          driveFolderId:"",
          title:"",
          listTransitions:[],
          editProccess:false,
          partialOfferId:0,
          isTemplate:false,
          //emissionDate2:new Date(),
          //selectedOption:"",
          //selectedOption2:"",
          loadingData:true,
          modalWorkFlow:false,
          observaciones:"",
      };
      
      //this.handleCreateDate = this.handleCreateDate.bind(this);
      this.toggleWorkFlow = this.toggleWorkFlow.bind(this);
      this.handleTransition = this.handleTransition.bind(this);
      this.handleUserSe = this.handleUserSe.bind(this); 
      this.handleDestinoSe = this.handleDestinoSe.bind(this); 
    }

  handleCallback = (OfertaParcial) =>{
      this.setState({partialOfferId:OfertaParcial})
      //console.log(OfertaParcial)
  }

    handleUserSe(data)
    {
      this.setState({idUserSe:data});
    }

    handleDestinoSe(data)
    {
      this.setState({idDestinoSe:data});

      let valores = [];
      let item = { value: 0, label: "", };
      data.listUsers.forEach(element => {
          let item = {value: element.userId, label: element.supplierName}
          valores.push(item);
      });

      this.setState({listUsers: valores});
    }

    handleProcedureContentChange = (content, delta, source, editor) => {
      this.setState({observaciones:content})
    };

    toggleWorkFlow() {
      this.setState({
        modalWorkFlow: !this.state.modalWorkFlow,
      });
    } 

    /*handleCreateDate(date) {
      this.setState({
        emissionDate2: date,
      });
    }*/

    AdminWorkFlow(procurementId){
      //console.log("Fase actual: ",this.state.wPhaseId)
      if(procurementId>0)
      {
        this.toggleWorkFlow();
      }
    }

    componentDidMount() {
      if(this.props.location.query)
      {
        var data = this.props.location.query;
        var {procurementId} = data;
        if(data.procurementId>0)
        {
          this.setState({procurementId:data.procurementId, driveFolderId:data.driveFolderId, title:data.title, 
                          editProccess:data.editProccess, partialOfferId:data.partialOfferId, procCode:data.procCode, 
                          wPhaseId:data.wPhaseId, isTemplate:data.isTemplate});
          console.log(data.wPhaseId)
          let valores = [];
          let item = { value: 0, label: "", };
          data.listTransitions.forEach(element => {
              let item = {value: element.destinationPhase, label: element.description, listUsers:element.listUsers, icon:element.icon, startPhase:element.startPhase}
              valores.push(item);
          });
          this.setState({listTransitions: valores});
        }
      }
      this.setState({loadingData:false});
    }

  EnviarProcurementPage(){
          var data = {};
          this.props.history.push({
              pathname: "/apps/listProcurements",
              query:data,
            })
  }

getTabs() {

    let valores = [];
    let item = {
        title: <div><span class="pe-7s-note2"></span> <span>{"General"}&nbsp;</span></div> ,
        content: !this.state.loadingData?<InfoGeneral procurementId={this.state.procurementId} editProccess={this.state.editProccess} 
                                            partialOfferId={this.state.partialOfferId}
                                            procCode ={this.state.procCode}
                                            parentCallback = {this.handleCallback}
                                          ></InfoGeneral>:"",
      }
    valores.push(item);
    item = {
        title: <div><span class="pe-7s-notebook"></span> <span>{"Detalles"}&nbsp;</span></div> ,
        content:!this.state.loadingData?<Detalles procurementId={this.state.procurementId} editProccess={this.state.editProccess}
                                         procCode ={this.state.procCode}
                                        ></Detalles>:"",
      }
    valores.push(item);
    item = {
      title: <div><span class="pe-7s-notebook"></span> <span>{"Cond. Contrato"}&nbsp;</span></div> ,
      content:!this.state.loadingData?<CondicionesContrato procurementId={this.state.procurementId} editProccess={this.state.editProccess}
                                       procCode ={this.state.procCode}
                                      ></CondicionesContrato>:"",
    }
  valores.push(item);
  item = {
        title: <div><span class="lnr-license"></span> <span>{"Criterios"}&nbsp;</span></div> ,
        content: !this.state.loadingData?<Criterios procurementId={this.state.procurementId} editProccess={this.state.editProccess} 
                                          partialOfferId={this.state.partialOfferId}
                                          procCode ={this.state.procCode}
                                         ></Criterios>:"",
      }
    valores.push(item);
    item = {
        title: <div><span class="pe-7s-note2"></span> <span>{"Cuestionarios"}&nbsp;</span></div> ,
        content: !this.state.loadingData?<Cuestionario procurementId={this.state.procurementId} editProccess={this.state.editProccess}
                                        procCode ={this.state.procCode}
                                      ></Cuestionario>:"",
      }
    valores.push(item);
    item = {
        title: <div><span class="pe-7s-copy-file"></span> <span>{"Documentos"}&nbsp;</span></div> ,
        content: !this.state.loadingData?<Documentos procurementId={this.state.procurementId} driveFolderId={this.state.driveFolderId} 
                                          editProccess={this.state.editProccess}
                                          procCode ={this.state.procCode}
                                        ></Documentos>:"",
      }
    valores.push(item);
    item = {
        title: <div><span class="pe-7s-check"></span> <span>{"Lista de verificación"}&nbsp;</span></div> ,
        content: !this.state.loadingData?<ListaVerificacion procurementId={this.state.procurementId} editProccess={this.state.editProccess} 
                                        partialOfferId={this.state.partialOfferId}
                                        procCode ={this.state.procCode}
                                      ></ListaVerificacion>:"",
      }
    valores.push(item);
    item = {
        title: <div><span class="pe-7s-cash"></span> <span>{"Detalles financieros"}&nbsp;</span></div> ,
        content: !this.state.loadingData?<DetalleFinanciero procurementId={this.state.procurementId} editProccess={this.state.editProccess}
        procCode ={this.state.procCode}
       ></DetalleFinanciero>:"",
      }
    valores.push(item);
    item = {
        title: <div><span class="lnr-users"></span> <span>{"Competencias"}&nbsp;</span></div> ,
        content: !this.state.loadingData?<Competencias procurementId={this.state.procurementId} editProccess={this.state.editProccess}
        procCode ={this.state.procCode}
       ></Competencias>:"",
      }
    valores.push(item);    

    if(!this.state.isTemplate)
    {
      item = {
        title: <div><span class="pe-7s-graph1"></span> <span>{"Flujo de trabajo"}&nbsp;</span></div> ,
        content: !this.state.loadingData?<WorkFlow procurementId={this.state.procurementId} editProccess={this.state.editProccess}
        procCode ={this.state.procCode}
       ></WorkFlow>:"",
      }
    valores.push(item); 

      item = {
        title: <div><span class="pe-7s-graph1"></span> <span>{"Revisar"}&nbsp;</span></div> ,
        content: !this.state.loadingData?<Reviews procurementId={this.state.procurementId} editProccess={this.state.editProccess}
        procCode ={this.state.procCode}
        wPhaseId={this.state.wPhaseId}
       ></Reviews>:"",
      }
      valores.push(item);     
    }

    return valores.map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content,
      key: index,
    }));
  }

  render() {
    let mostrar = true;

    return (
      <Fragment>
        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
          transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <div className="app-page-title app-page-title-simple">
                <div className="page-title-wrapper">
                  <div className="page-title-heading">
                    <div>
                      <div className="page-title-head center-elem">
                        <span className={cx("d-inline-block pe-2")}>
                          <i className={"pe-7s-id"} />
                        </span>
                        <span className="d-inline-block">{"E-Proeri"}</span>
                      </div>
                      <div className={cx("page-title-subheading opacity-10" )}>
                                <Breadcrumb>
                                  <BreadcrumbItem>
                                    <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>
                                      <FontAwesomeIcon icon={faHome} />
                                    </a>
                                  </BreadcrumbItem>
                                  <BreadcrumbItem>
                                    <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>
                                    Procurement
                                    </a>
                                  </BreadcrumbItem>
                                  <BreadcrumbItem active>{this.state.title}</BreadcrumbItem>
                                </Breadcrumb>
                      </div>
                    </div>
                  </div>

                  <div className="page-title-actions">
                    <Fragment>

                    {this.state.listTransitions.length>0?<Button className={`btn-icon btn-shadow ml-3`}
                                      onClick={()=>this.AdminWorkFlow(this.state.procurementId)}
                                      color="info" id="Tooltip-123"
                                      > 
                                        <FontAwesomeIcon icon={faShareAlt} />
                                        &nbsp;{"Work Flow"}
                                      </Button>:""}
                    </Fragment>                    
                  </div>
                </div>
              </div>


          <Alert className="mbg-3 m-2" color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
            <span className="pl-2">
            </span>
            {"Proceso: ["+this.state.procCode + "]"}
          </Alert>
        <div>  
        <Container fluid>   
        <div className="card card-nav-tabs pt-5">
          <div className=""></div>
            <Tabs tabsWrapperClass="nav-tabs-navigation card-header" transform={false}
            showInkBar={true} items={this.getTabs()}/>
        </div>
        </Container>
        </div>              

        <Modal
            isOpen={this.state.modalWorkFlow}
            toggle={this.toggleWorkFlow}
            className={this.props.className /*+ " modal-lg" */}
          >
            <ModalHeader toggle={this.toggleWorkFlow}>{this.props.t('Enviar proceso de licitación')}</ModalHeader>
            <ModalBody>
              <Card className="main-card mb-3">
                        <CardBody className="p-2">
                            <div className="px-1">
                              <Row>
                              <Col>
                                <div className="grid-menu grid-menu-3col">
                                  <Row className="g-0">
                                    {(this.state.wPhaseId===3 || this.state.wPhaseId===12)?<Col md={12}>
                                        <div className="mb-2">
                                            <Button className="btn-icon-vertical btn-square btn-transition" 
                                              style={{height: "100%"}} outline 
                                              color={`primary`} 
                                              onClick={()=>this.GetAllFileToDrive("")} 
                                            >
                                              <i className={`btn-icon-wrapper pe-7s-copy-file `}></i>
                                                        {"Visualizar documento"}
                                            </Button>                                            
                                        </div>                                          
                                    </Col>:<></>}
                                    {this.state.loading ? (
                                        <div><Loader type="ball-pulse" /><em>Espere un momento...</em></div>
                                    ) : ""}
                                    <Col md={12}>
                                        <div className="mb-2">
                                            <label htmlFor="exampleInputEmail1" className="form-label" >
                                                {"Observaciones"}
                                            </label>
                                            <ReactQuill
                                                    theme="snow"
                                                    modules={modules}
                                                    formats={formats}
                                                    value={this.state.observaciones}
                                                    onChange={this.handleProcedureContentChange}
                                                  />
                                        </div>                                          
                                      </Col>
                                      <Col md={12}>
                                      <div className="mb-2">
                                          <label htmlFor="exampleInputEmail1" className="form-label">
                                            {"Seleccione fase destino"}
                                          </label>
                                          <Select
                                              defaultValue={this.state.idDestinoSe}
                                              id="idUserSe"
                                              name="idUserSe"
                                              placeholder="Please select item"
                                              isSearchable={true}
                                              onChange={this.handleDestinoSe}
                                              options={this.state.listTransitions}
                                            />  
                                        </div>                                        
                                        <div className="mb-2">
                                          <label htmlFor="exampleInputEmail1" className="form-label">
                                            {"Seleccione usuario a asignar:"}
                                          </label>
                                          <Select
                                              defaultValue={this.state.idUserSe}
                                              id="idUserSe"
                                              name="idUserSe"
                                              placeholder="Please select item"
                                              isSearchable={true}
                                              onChange={this.handleUserSe}
                                              options={this.state.listUsers}
                                            />  
                                        </div>   
                                                                           
                                      </Col>
                                      <Col md={12}>
                                        <Button className="btn-icon-vertical btn-square btn-transition" 
                                          style={{height: "100%"}} outline 
                                          color={`${this.state.idDestinoSe.icon==='lnr-arrow-right'?`primary`:`warning`}`} 
                                          onClick={()=>this.handleTransition(1, this.state.idDestinoSe.value)} 
                                        >
                                          <i className={`btn-icon-wrapper ${this.state.idDestinoSe.icon} `}></i>
                                                    {this.state.idDestinoSe.label}
                                        </Button>
                                      
                                      </Col>
                                  </Row>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                        </CardBody>
              </Card>            
            </ModalBody>
          </Modal>        

        </TransitionGroup>
      </Fragment>
    );
  }

  async handleTransition(startPh, destinationPh) {
    try {
          if(this.state.observaciones ==='')
          {
            this.setState({ mensajeOperacion: "Por favor ingrese sus observaciones" });
            this.toggleMsg();
            return;
          }


      const bod = { ProcurementId: this.state.procurementId, 
        StartPhaseId:this.state.idDestinoSe.startPhase, 
        DestinationPhaseId: this.state.idDestinoSe.value,
        AssignedUserId: this.state.idUserSe.value,
        Observations:this.state.observaciones,
        EmailCopy:""
    };
      const values = JSON.stringify(bod);
      this.setState({loading: false});
  
      const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.auth.accessToken,
          }
        
        const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
        this.setState({loading: true});
        let url = process.env.REACT_APP_API + config.API_ESOURCING_WORKFLOW;
        customAxios.post(url,
          values,
            { headers }).then((res) => {
                this.setState({loading: false});
                if (!res.data.operacionConExito) {
                  Swal("Error", res.data.error ,"error");
                  this.setState({loading: false});
                } 
                else {
                this.setState({loading: false});
                Swal("Transición exitosa!!","¡Proceso enviado con éxito." ,"success");
                this.EnviarProcurementPage();
                }
            })
          .catch((err) => {
            this.setState({loading: false});
            console.log("AXIOS ERROR: ", err);
          })
    } catch (error) {
      console.log(error);
    }
  } 

  async GetAllFileToDrive(urlFile) {
    try {

      const bod = { ProcurementId: this.state.procurementId, 
        StartPhaseId:0, 
        DestinationPhaseId: 0,
        AssignedUserId: 0,
        Observations:"",
        EmailCopy:"tiger.mect@gmail.com"
    };

      const values = JSON.stringify(bod);

      const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.auth.accessToken,
          }
    
      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      this.setState({loading: true});
      let url = process.env.REACT_APP_API + config.API_ESOURCING_WORKFLOW;
          
      customAxios.post(url+'/allFiles',
          values,
          { headers }).
        then((res) => {
          //console.log(res.data)
          if (!res.data.operacionConExito) {
            Swal("Error",res.data.error,"error");
            this.setState({loading: false});
          } 
          else {
            
            this.setState({ base64File: res.data.base64, loading: false });
            const container = document.querySelector('#container');
            const tempLink = document.createElement('a');
            tempLink.href = `data:application/pdf;base64,${this.state.base64File}`;
            tempLink.setAttribute('download', 'doc.pdf');
            tempLink.click();
          }

        })
        .catch((err) => {
          this.setState({loading: false });
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      this.setState({loading: false });
      console.log(error);
    }
  }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      setToken: (token) => {
        dispatch(setToken(token));
      }
    }
  };

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Procurement)));