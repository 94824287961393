import React, { Component, Fragment } from "react";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { Button, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FaPencilAlt, FaCheckSquare, FaRegSquare, FaClipboardList, FaThemeisle} from "react-icons/fa";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import Loader from "react-loaders";
import TextareaAutosize from "react-textarea-autosize";
import Swal from 'sweetalert';

class Criterios extends Component {
    constructor(props) {
        super(props);
        this.state = {
          values:{
            id:0,
            sectionId:0,
            idEtapa:0,
            description:"",
            tipoCriterio:"Aceptación/Rechazo",
            loteId:0,
            listLotes:[],
          },
          idSeccion:0,
          nombreSeccion:"",
          descripcionSeccion:"",
          estadoSeccion:0,
          
          idDetalle:0,
          idCriterionD:0,
          requerimientoDetalle:"",
          cargoDetalle:"",
          //criterionEvaluar:"",
          documentacionRequerida:"",
          evaluacionDetalle:0,

          idEtapaSe:{value:0, label:""},
          idLoteSe:{value:0, label:""},
          idEvaluacionSe:{value:0, label:""},
          listSecciones:[],
          listCriterions:[],
          listCriterionDetail:[],
          listSubProcesos: [],
          rowsSelect:[],
          tableLotes:"",
          tableCriterios:"",
          tableDetalles:"",
          etapasList:[
            {value:0, label:""},
            {value:1, label:"Criterios formales y de elegibilidad"},
            {value:2, label:"Criterios de calificación"},
            {value:3, label:"Criterios técnicos"}
          ],
          lotesList:[
            {value:0, label:""},
            {value:1, label:"Todos los lotes"},
            {value:2, label:"Lote específico"},
          ],
          evaluacionList:[
            {value:0, label:""},
            {value:1, label:"Cumple / No Cumple"},
            {value:2, label:"Asignar Puntaje"},
          ],
          loading: false,
          modal: false,
          modalSeccion: false,
          modalCriterio: false,
          modalDetailCriterio: false,
          mensajeOperacion: "",
          partialOfferId:0,
        };
    
        this.renderCriterionTable = this.renderCriterionTable.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleSeccion = this.toggleSeccion.bind(this);
        this.toggleCriterio = this.toggleCriterio.bind(this);
        this.toggleDetailCriterio = this.toggleDetailCriterio.bind(this);
        this.handleSaveCriterion = this.handleSaveCriterion.bind(this);
        this.handleChangeEtapa = this.handleChangeEtapa.bind(this);
        this.handleChangeEvaluacion = this.handleChangeEvaluacion.bind(this);
        this.handleChangeLote = this.handleChangeLote.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.handleChangeNombreS = this.handleChangeNombreS.bind(this);
        this.handleChangeDescripcionS = this.handleChangeDescripcionS.bind(this);
        this.handleSaveSeccion = this.handleSaveSeccion.bind(this);
        this.handleSaveDetalle = this.handleSaveDetalle.bind(this);
        this.EditDetalle = this.EditDetalle.bind(this);
      }

      handleChangeNombreS = (e) => {
        const { name, value } = e.target;
        this.setState({nombreSeccion:value});
        console.log(this.state.nombreSeccion)
      }

      handleChangeDescripcionS = (e) => {
        const { name, value } = e.target;
        this.setState({descripcionSeccion:value});
      }

      handleChangeEtapa(data)
      {
        let valor = this.state.values;
        valor.idEtapa = data.value;
    
        this.setState({values:valor});
        this.setState({idEtapaSe:data});
      }

      handleChangeEvaluacion(data)
      {
        let valor = this.state.values;
        this.setState({evaluacionDetalle:data.value, idEvaluacionSe:data});
      }

      handleChangeRequerimiento= (e) => {
        const { name, value } = e.target;
        this.setState({requerimientoDetalle:value});
      }

      handleChangeCargo= (e) => {
        const { name, value } = e.target;
        this.setState({cargoDetalle:value});
      }

      handleChangeCriterionEvaluar= (e) => {
        const { name, value } = e.target;
        this.setState({criterionEvaluar:value});
      }

      handleChangeDocumentacionRequerida= (e) => {
        const { name, value } = e.target;
        this.setState({documentacionRequerida:value});
      }


      handleChangeLote(data)
      {
        let valor = this.state.values;
        valor.loteId = data.value;
    
        this.setState({values:valor, idLoteSe:data});

        if(data.value === 1){
          let myList = [];
          this.state.listSubProcesos.map((item, index) => {  
            let existeL = this.state.values.listLotes.find(element => element.subProcessId === item.id);
            if(existeL)
            {
              existeL.status =1;
              myList.push(existeL);      
            }
            else
              myList.push({id:0, criterionId:this.state.values.id, subProcessId:item.id, status:1});    
          });
         
          let valores = this.state.values;
          valores.listLotes = myList;
          this.setState({values:valores})

          let listArreglada =[];
          let item ={};
    
          if(this.state.listSubProcesos.length>0)
            {
              this.state.listSubProcesos.forEach(loteA =>{
                item = loteA;
                item.status = 2;
                myList.forEach(lote =>{
                      if(lote.subProcessId === loteA.id)
                      {
                        item.status =lote.status;
                      }
                    });
                    listArreglada.push(item);
                });
          }
    
        let lotesT = this.renderSubProcTable(listArreglada)
        this.setState({tableLotes:lotesT});
        this.setState({listSubProcesos:listArreglada});

        }
    
      }
      
      handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
          values: {
            ...this.state.values,
            [name]: value,
          },
        });
      };

      toggle() {
        this.setState({
          modal: !this.state.modal,
        });
      }

      toggleSeccion() {
        this.setState({
          modalSeccion: !this.state.modalSeccion,
        });
      }

      toggleCriterio() {
        this.setState({
          modalCriterio: !this.state.modalCriterio,
        });
      }

      toggleDetailCriterio() {
        this.setState({
          modalDetailCriterio: !this.state.modalDetailCriterio,
        });
      }      

      async componentDidMount() {
        await this.getInformacionGeneral(this.props.procurementId);
        this.getSecciones(this.props.procurementId);
        this.getLotes(this.props.procurementId);
      }

      EnviarProcurementPage(procurementId){
        if(procurementId>0)
        {
            var data = {procurementId:procurementId};
            this.props.history.push({
                pathname: "/apps/procurement",
                query:data,
              })
        }
    }

    EditCriterio(id, idEtapa, descripcion, tipoCriterio, listLotes,subProcessOptionId)
    {
      //console.log("subProc: ", subProcessOptionId)
      let existe = this.state.etapasList.find(element => element.value === idEtapa);
      if(existe)
        this.setState({idEtapaSe:existe});

      let existeL = this.state.lotesList.find(element => element.value === subProcessOptionId);
      if(existeL)
        this.setState({idLoteSe:existeL});

      let valores={id:id, idEtapa:idEtapa, description:descripcion, tipoCriterio:tipoCriterio, listLotes:listLotes, loteId:subProcessOptionId}
      //console.log(valores)
      this.setState({values:valores});

      let listArreglada =[];
      let item ={};

      if(this.state.listSubProcesos.length>0)
        {
          this.state.listSubProcesos.forEach(loteA =>{
            item = loteA;
            item.status =2;
                
            listLotes.forEach(lote =>{
                  if(lote.subProcessId === loteA.id)
                  {
                    item.status =lote.status;
                  }
                });

                listArreglada.push(item);
            });
      }

    let lotes = this.renderSubProcTable(listArreglada)
    this.setState({tableLotes:lotes});
      //console.log("finalizo el proceso de edit")
      this.toggleCriterio();
    }

    async EditSeccion(id, name, description, criterionList)
    {
      this.setState({idSeccion:id, nombreSeccion:name, descripcionSeccion:description, listCriterions:criterionList});
      let criteriosDB = await this.getCriterions(this.props.procurementId, id);
      let criterios = await this.renderCriterionTable(criteriosDB)
      this.setState({tableCriterios:criterios});
      console.log(criteriosDB)
      this.toggleSeccion();
    }

    async EditDetalles(id)
    {
      let valores = this.state.values;
      valores.id = id;
      this.setState({values:valores});

      this.setState({idDetalle:0, evaluacionDetalle:0, idEvaluacionSe:{value:0, label:""}, requerimientoDetalle:"", cargoDetalle:"", documentacionRequerida:""})
      
      let detallesDB = await this.getCriterionDetail(this.props.procurementId, id);
      let detalles = await this.renderDetallesTable(detallesDB)
      this.setState({tableDetalles:detalles});
      //console.log(criteriosDB)
      this.toggleDetailCriterio();
    }

    async EditDetalle(id, requisito, cargo, documentoRequerido, evaluacion)
    {
      console.log(evaluacion)
      let existeD = this.state.evaluacionList.find(element => element.label === evaluacion);
      console.log(existeD)
      let idEvaluacion = 0;
      if(existeD)
      {
        this.setState({idEvaluacionSe:existeD})
        idEvaluacion = existeD.value;
        console.log("actualizpo")
      }

      this.setState({idDetalle:id, requerimientoDetalle:requisito, cargoDetalle:cargo, documentacionRequerida:documentoRequerido, evaluacionDetalle:idEvaluacion});
//      this.toggleDetailCriterio();
    }

    handleRowSelected (id, estado) {
      let valores = this.state.values;
      let lotes = valores.listLotes;
      let listCh =lotes;
      let listChF =[];
      let itemCh ={};
      let encontrado =false;

      if(listCh.length>0)
      {
        listCh.forEach(lote =>{
          if(lote.subProcessId === id)
          {
            itemCh = lote;
            itemCh.status = estado===1?2:1;
            listChF.push(itemCh)
            encontrado=true;
          }
          else
          {
            listChF.push(lote);
          }
        });
        
        if(!encontrado)
          listChF.push({id:0, criterionId:0, subProcessId:id, status:1});
      }
      else
      listChF.push({id:0, criterionId:0, subProcessId:id, status:1});

      valores.listLotes = listChF;
      
      this.setState({values:valores});

      let listArreglada =[];
      let item ={};

      if(this.state.listSubProcesos.length>0)
        {
          this.state.listSubProcesos.forEach(loteA =>{
            item = loteA;
            item.status = 2;
            listChF.forEach(lote =>{
                  if(lote.subProcessId === loteA.id)
                  {
                    item.status =lote.status;
                  }
                });
                listArreglada.push(item);
            });
      }

    let lotesT = this.renderSubProcTable(listArreglada)
    this.setState({tableLotes:lotesT});
    this.setState({listSubProcesos:listArreglada});

    };

    renderSubProcTable(litLotes) {
      const columns = [
        {
          name: "",
          width: "5rem",
          cell: (row) => {
            return (
              <Fragment>
                <button
                  className="btn btn-outline-primary"
                  onClick={(id) => this.handleRowSelected(row.id, row.status)}
                >
                  {row.status===1?<FaCheckSquare size={16} />:<FaRegSquare size={16} />}
                </button>
              </Fragment>
            );
          },
        },        
        {
          name: this.props.t('Descripción'),
          id: "code",
          //width: "25rem",
          selector: (row) => row.description,
          sortable: true,
        },
        {
          name: this.props.t('Numero'),
          //width: "10rem",
          selector: (row) => row.number,
          sortable: true,
        },
      ];
  
      return (
        <div>
          <DataTable
            data={litLotes}
            columns={columns}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            paginationComponentOptions={{
              rowsPerPageText: "Registros por pagina:",
              rangeSeparatorText: "de",
            }}
          />
        </div>
      );
    }

      async renderCriterionTable(listCriterion) {
        const columns = [
          {
            name: "",
            width: "7rem",
            cell: (row) => {
              return (
                <Fragment>
                  <button
                    className="btn btn-outline-primary"
                    disabled={!this.props.editProccess}
                    onClick={(id) => this.EditCriterio(row.id, row.etapaId, row.description, "Aceptación/Rechazo", row.subProcess, row.subProcessOptionId)}
                    
                  >
                  <FaPencilAlt size={16} />
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    disabled={!this.props.editProccess}
                    onClick={(id) => this.EditDetalles(row.id)}
                  >
                  <FaClipboardList size={16} />
                  </button>

                </Fragment>
              );
            },
          },
          {
            name: this.props.t('Description'),
            id: "code",
            //width: "25rem",
            selector: (row) => row.description,
            sortable: true,
          },
          {
            name: this.props.t('Etapa'),
            //width: "10rem",
            selector: (row) => row.etapaName,
            sortable: true,
          },
          {
            name: this.props.t('Tipo criterio'),
            //width: "15rem",
            selector: (row) => row.typeCriterion,
            sortable: true,
          },
        ];
    
        return (
          <div>
            <DataTable
              data={listCriterion}
              columns={columns}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Registros por pagina:",
                rangeSeparatorText: "de",
              }}
            />
          </div>
        );
      }

      async renderDetallesTable(listDetalles) {
        const columns = [
          {
            name: "",
            width: "7rem",
            cell: (row) => {
              return (
                <Fragment>
                  <button
                    className="btn btn-outline-primary"
                    disabled={!this.props.editProccess}
                    onClick={(id) => this.EditDetalle(row.id, row.requisito, row.cargo, row.documentoRequerido, row.evaluacion)}
                  >
                  <FaPencilAlt size={16} />
                  </button>
                </Fragment>
              );
            },
          },
          {
            name: this.props.t('Requisito'),
            id: "code",
            //width: "25rem",
            selector: (row) => row.requisito,
            sortable: true,
          },
          {
            name: this.props.t('Evaluacion'),
            //width: "10rem",
            selector: (row) => row.evaluacion,
            sortable: true,
          },
          {
            name: this.props.t('Documento requerido'),
            //width: "15rem",
            selector: (row) => row.documentoRequerido,
            sortable: true,
          },
          {
            name: this.props.t('Cargo'),
            //width: "15rem",
            selector: (row) => row.cargo,
            sortable: true,
          },
        ];
    
        return (
          <div>
            <DataTable
              data={listDetalles}
              columns={columns}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Registros por pagina:",
                rangeSeparatorText: "de",
              }}
            />
          </div>
        );
      }

      renderSeccionTable(secciones) {
        const columns = [
          {
            name: "",
            width: "5rem",
            cell: (row) => {
              return (
                <Fragment>
                  <button
                    className="btn btn-outline-primary"
                    disabled={!this.props.editProccess}
                    onClick={(id) => this.EditSeccion(row.id, row.name, row.description, row.criterionList)}
                  >
                  <FaPencilAlt size={16} />
                  </button>
                </Fragment>
              );
            },
          },
          {
            name: this.props.t('Nombre'),
            id: "code",
            //width: "25rem",
            selector: (row) => row.name,
            sortable: true,
          },
          {
            name: this.props.t('Descripción'),
            //width: "10rem",
            selector: (row) => row.description,
            sortable: true,
          },
        ];
    
        return (
          <div>
            <DataTable
              data={secciones}
              columns={columns}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Registros por pagina:",
                rangeSeparatorText: "de",
              }}
            />
          </div>
        );
      }

      render() {
        let contents = this.state.loading ? (
          <p>
            <em>{this.props.t('Loading')}...</em>
          </p>
        ) : (
          this.renderSeccionTable(this.state.listSecciones)
        );
    
        return (
                <div>
                  <Card style={{margin:"5px"}}>
                  <Row>
                  <Col md="12">
                            <div className="px-1">
                              <Row>
                              <Col>
                                <div className="grid-menu grid-menu-3col">
                                  <Row className="g-0">
    
                                    <Col md={3}>
                                        <div className="widget-chart" style={{background: "#FFF"}}>
                                            <div className="icon-wrapper rounded-circle">
                                            <div className="icon-wrapper-bg" style={{background: "#0092D1"}} />
                                              <i className="lnr-layers text-primary" />
                                            </div>
                                            <div className="widget-numbers" style={{fontSize: "12px"}}>{this.state.countItems} {this.props.t('Criterios')}</div>
                                        </div>
                                      </Col>
    
    
                                    <Col md={4}>
                                      <Button 
                                      className="btn-icon-vertical btn-square btn-transition" style={{height: "100%"}} outline color="primary" 
                                      disabled={!this.props.editProccess}
                                      onClick={() => this.EditSeccion(0, '', '', [])} >
                                        <i className="lnr-file-add btn-icon-wrapper"> </i>
                                                  Agregar sección
                                      </Button>
                                      </Col>
                                  </Row>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                    </Col>
                  </Row>
                  </Card>
                  <Card style={{margin:"5px"}}>
                  <Row>
                    <Col md="12">
                        <h3 style={{paddingLeft: "10px", paddingTop:"10px"}}>{this.props.t('Lista de criterios agregados!!')}</h3>
                        <div className="px-0">
                          <CardBody className="p-2">
                            {contents} <br/></CardBody>
                        </div>
                    </Col>
                  </Row>
                  </Card>
                  <Modal
                      isOpen={this.state.modalSeccion}
                      toggle={this.toggleSeccion}
                      className={this.props.className+ " modal-lg"}
                    >
                      <ModalHeader toggle={this.toggleSeccion}>{this.props.t('Sección')}</ModalHeader>
                      <ModalBody>
                        <Row>
                          <Col md={12}>
                            <label htmlFor="exampleInputEmail1" className="form-label"> {"Nombre"} </label>
                            <input
                                  type="text"
                                  className="form-control"
                                  id="nombreSeccion"
                                  aria-describedby="emailHelp"
                                  name="nombreSeccion"
                                  value={this.state.nombreSeccion}
                                  ref="nombreSeccion"
                                  onChange={this.handleChangeNombreS}
                                  ></input>
                          </Col>
                          <Col md={12}>
                            <label htmlFor="exampleInputEmail1" className="form-label"> {"Descripción"} </label>
                            <TextareaAutosize className="form-control" minRows={3} maxRows={6}
                                  id="descripcionSeccion"
                                  aria-describedby="emailHelp"
                                  name="descripcionSeccion"
                                  value={this.state.descripcionSeccion}
                                  ref="descripcionSeccion"
                                  onChange={this.handleChangeDescripcionS}
                                  />
                          </Col>
                          <Col md={12} className="pt-2">
                            <Button color="primary" 
                                onClick={this.handleSaveSeccion}
                                >
                              {"Guardar"}
                            </Button>
                          </Col>
                          {this.state.idSeccion>0?<Col md={12} className="pt-2">
                          <h3 style={{paddingLeft: "10px", paddingTop:"10px"}}>{this.props.t('Lista de criterios')}</h3>
                          {this.state.tableCriterios}
                          </Col>:<></>}
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                      {this.state.idSeccion>0?<Button color="primary" 
                                onClick={()=>this.EditCriterio(0, 0, "", "Aceptación/Rechazo", [], 0)}
                                >
                              {"Agregar criterio"}
                        </Button>:""}
                      </ModalFooter>
                  </Modal>

                    <Modal
                      isOpen={this.state.modalCriterio}
                      toggle={this.toggleCriterio}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleCriterio}>{this.props.t('Agregar criterio')}s</ModalHeader>
                      <ModalBody>
                        <div className="mb-2">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                {"Etapa"}
                              </label>
                              <Select
                                  defaultValue={this.state.idEtapaSe}
                                  id="idEtapa"
                                  name="idEtapa"
                                  placeholder="Please select item"
                                  isSearchable={true}
                                  onChange={this.handleChangeEtapa}
                                  options={this.state.etapasList}
                                />  
                        </div>     
                        <div className="mb-2">
                            <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                            >
                                {"Description"}
                            </label>
                            <TextareaAutosize className="form-control" minRows={3} maxRows={6} defaultValue="" 
                            name="description"
                            id="description"
                            placeholder="Por favor describa el criterio para el proceso de licitación"
                            value={this.state.values.description} 
                            onChange={this.handleChange}
                            />
                        </div>   
                        <div className="mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label"> {"Tipo de criterio"} </label>
                        
                        <input
                                type="text"
                                className="form-control"
                                id="SupplierName"
                                aria-describedby="emailHelp"
                                name="title"
                                value={this.state.values.tipoCriterio}
                                ref="tipoCriterio"
                                disabled
                                //onChange={this.handleChange}
                                ></input>
                        </div>       
                        {this.state.partialOfferId===1?  
                        <><div className="mb-2">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                {"Opción de lote"}
                              </label>
                              <Select
                                  defaultValue={this.state.idLoteSe}
                                  id="idLote"
                                  name="idLote"
                                  placeholder="Please select item"
                                  isSearchable={true}
                                  onChange={this.handleChangeLote}
                                  options={this.state.lotesList}
                                />  
                        </div>
                        {(this.state.values.loteId>0)?(this.state.tableLotes):""}
                          {this.state.loading ? <div><Loader type="ball-beat" />
                                  <p>
                                    <em>Creating criterion record...</em>
                                  </p></div> : ""}
                        
                        </>:""}

                      </ModalBody>
                      <ModalFooter>
                      <Button color="primary" 
                                onClick={this.handleSaveCriterion}
                                >
                              {"Guardar"}
                              </Button>{" "}
                      </ModalFooter>
                    </Modal>                      

                    <Modal
                      isOpen={this.state.modalDetailCriterio}
                      toggle={this.toggleDetailCriterio}
                      className={this.props.className+ " modal-lg"}
                    >
                      <ModalHeader toggle={this.toggleDetailCriterio}>{this.props.t('Detalles criterio')}s</ModalHeader>
                      <ModalBody>
                        <div className="mb-2">
                            <label htmlFor="exampleInputEmail1" className="form-label">{"Requerimiento"}</label>
                            <TextareaAutosize className="form-control" minRows={2} maxRows={4} defaultValue="" 
                            name="description"
                            id="description"
                            placeholder="Por favor describa el criterio para el proceso de licitación"
                            value={this.state.requerimientoDetalle} 
                            onChange={this.handleChangeRequerimiento}
                            />
                        </div>   
                        <div className="mb-2">
                            <label htmlFor="exampleInputEmail1" className="form-label">{"Cargo"}</label>
                            <TextareaAutosize className="form-control" minRows={2} maxRows={4} defaultValue="" 
                            name="description"
                            id="description"
                            placeholder="Por favor describa el criterio para el proceso de licitación"
                            value={this.state.cargoDetalle} 
                            onChange={this.handleChangeCargo}
                            />
                        </div>   
                        {/*}<div className="mb-2">
                            <label htmlFor="exampleInputEmail1" className="form-label">{"Criterio a evaluar"}</label>
                            <TextareaAutosize className="form-control" minRows={2} maxRows={4} defaultValue="" 
                            name="description"
                            id="description"
                            placeholder="Por favor describa el criterio para el proceso de licitación"
                            value={this.state.criterionEvaluar} 
                            onChange={this.handleChangeCriterionEvaluar}
                            />
                        </div>   {*/}
                        <div className="mb-2">
                            <label htmlFor="exampleInputEmail1" className="form-label">{"Documentación requerida"}</label>
                            <TextareaAutosize className="form-control" minRows={2} maxRows={4} defaultValue="" 
                            name="description"
                            id="description"
                            placeholder="Por favor describa el criterio para el proceso de licitación"
                            value={this.state.documentacionRequerida} 
                            onChange={this.handleChangeDocumentacionRequerida}
                            />
                        </div>   
                        <div className="mb-2">
                              <label htmlFor="exampleInputEmail1" className="form-label">{"Evaluación"}</label>
                              <Select
                                  defaultValue={this.state.idEvaluacionSe}
                                  id="idEvaluacionSe"
                                  name="idEvaluacionSe"
                                  placeholder="Please select item"
                                  isSearchable={true}
                                  onChange={this.handleChangeEvaluacion}
                                  options={this.state.evaluacionList}
                                />  
                        </div>    
                        <Row>
                        <Col md={12} className="pt-2">
                          <Button color="primary" 
                                onClick={this.handleSaveDetalle}
                                >
                              {"Guardar"}
                              </Button>{" "}
                              <Button color="primary" 
                                onClick={()=>{this.setState({idDetalle:0, evaluacionDetalle:0, idEvaluacionSe:{value:0, label:""}, requerimientoDetalle:"", cargoDetalle:"", documentacionRequerida:""})}}
                                >
                              {"Limpiar"}
                              </Button>{" "}
                              
                        </Col>
                          <Col md={12} className="pt-2">
                          <h3 style={{paddingLeft: "10px", paddingTop:"10px"}}>{this.props.t('Lista de detalles')}</h3>
                          {this.state.tableDetalles}
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </Modal>                 
                </div>
        );
      }

      async getSecciones(procurementId) {
        try {
      
          const bod = { Id: 0, ProcurementId:procurementId, EtapaId:0, CriterionName:""};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_CRITERION+"/GetSections";

            customAxios.patch(url,
                values,
                { headers }).then((res) => {
                this.setState({ listSecciones: res.data, loading: false });
                console.log(res.data)
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }

      async getCriterions(procurementId, idSeccion) {
        let myCriterios =[];
        try {
      
          const bod = { Id: 0, ProcurementId:procurementId, EtapaId:0, CriterionName:"", SectionId: idSeccion};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_CRITERION;

          await  customAxios.patch(url,
                values,
                { headers }).then((res) => {
                this.setState({ listCriterions: res.data, loading: false });
                myCriterios = res.data;
                console.log(res.data)
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
        return myCriterios;
      }

      async getCriterionDetail(procurementId, idCriterion) {
        let myDetails =[];
        try {
      
          const bod = { Id: idCriterion, ProcurementId:procurementId, EtapaId:0, CriterionName:"", SectionId: 0};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_CRITERION+"/GetCriterionDetails";

          await  customAxios.patch(url,
                values,
                { headers }).then((res) => {
                this.setState({ listCriterionDetail: res.data, loading: false });
                myDetails = res.data;
                console.log(res.data)
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
        return myDetails;
      }

      async handleSaveSeccion() {
        try {
    //console.log("seccion....",this.state.nombreSeccion)
          if(this.state.nombreSeccion==='')
          {
            Swal("Advertencia","Por favor indique el nombre de la sección.","warning");
            return;
          }

          if(this.state.descripcionSeccion==='')
            {
              Swal("Advertencia","Por favor indique la descripción para la sección.","warning");
              this.toggleMsg();
              return;
            }

          const bod = {  Id: this.state.idSeccion, 
            ProcurementId:this.props.procurementId, 
            Name: this.state.nombreSeccion, 
            Description:this.state.descripcionSeccion, 
            Status:1};
          const values = JSON.stringify(bod);
                    console.log(values)
          this.setState({loading: false});
      
          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
            
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            this.setState({loading: true});
    
            let url = process.env.REACT_APP_API + config.API_ESOURCING_CRITERION+"/AddSeccion";
    
            customAxios.post(url,
              values,
                { headers }).then((res) => {
                    this.setState({loading: false});
                    if (!res.data.operacionConExito) {
                      Swal("Error",res.data.error,"error");
                      this.setState({loading: false});
                    } 
                    else {
                    this.setState({loading: false, idSeccion:res.data.id});
                    console.log(res.data.id)
                    Swal("Registro exitoso!!","¡Criterio agregado con éxito." ,"success");  
                    //this.toggleCriterio();
                    this.getSecciones(this.props.procurementId);
                    }
                })
              .catch((err) => {
                this.setState({loading: false});
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      } 

      async handleSaveCriterion() {
        try {
    
          if(this.state.values.idEtapa <=0)
          {
            Swal("Advertencia","Por favor indique una etapa para el criterio.","warning");
            return;
          }

          if(this.state.values.description==='')
            {
              Swal("Advertencia","Por favor indique la descripción para el criterio.","warning");
              return;
            }

            let valores = this.state.values;
            let lotes = valores.listLotes;
            let listCh =[];
            let itemCh ={};
      
            if(lotes.length>0)
            {
              lotes.forEach(lote =>{
                  itemCh = {Id:lote.id, CriterionId:lote.criterionId, SubProcessId:lote.subProcessId, status:lote.status};
                  listCh.push(itemCh)
              });
            }
 
          const bod = {  Id: this.state.values.id, 
            ProcurementId:this.props.procurementId, 
            EtapaId: this.state.values.idEtapa, 
            EtapaName:"", 
            Description:this.state.values.description, 
            TypeCriterion:this.state.values.tipoCriterio,
            SubProcess:listCh,
            SubProcessOptionId:this.state.idLoteSe.value,
            SectionCriterionId:this.state.idSeccion,
            Status:1};
          const values = JSON.stringify(bod);
          
          this.setState({loading: false});
      
          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
            
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            this.setState({loading: true});
    
            let url = process.env.REACT_APP_API + config.API_ESOURCING_CRITERION;
    
            await customAxios.post(url,
              values,
                { headers }).then((res) => {
                    this.setState({loading: false});
                    if (!res.data.operacionConExito) {
                      Swal("Error",res.data.error,"error");
                      this.setState({loading: false});
                    } 
                    else {
                    this.setState({loading: false});
                    Swal("Registro exitoso!!","¡Criterio agregado con éxito." ,"success");  
                    this.toggleCriterio();
                    //this.getCriterions(this.props.procurementId);
                    }
                })
              .catch((err) => {
                this.setState({loading: false});
                console.log("AXIOS ERROR: ", err);
              })

              let criteriosDB = await this.getCriterions(this.props.procurementId, this.state.idSeccion);
              console.log(criteriosDB)
              let criterios = await this.renderCriterionTable(criteriosDB)
              console.log(criterios)
              this.setState({tableCriterios:criterios});

        } catch (error) {
          console.log(error);
        }
      }  

      async handleSaveDetalle() {
        try {
          if(this.state.requerimientoDetalle==='')
          {
            Swal("Advertencia","Por favor indique el Requerimiento.","warning");
            return;
          }

          if(this.state.evaluacionDetalle<0)
            {
              Swal("Advertencia","Por favor indique la evaluación.","warning");
              this.toggleMsg();
              return;
            }

          const bod = {  Id: this.state.idDetalle, 
            CriterionId:this.state.values.id, 
            Requisito: this.state.requerimientoDetalle, 
            Evaluacion:this.state.idEvaluacionSe.label, 
            DocumentoRequerido: this.state.documentacionRequerida,
            Cargo:this.state.cargoDetalle,
            Status:1};
          const values = JSON.stringify(bod);
                    console.log(values)
          this.setState({loading: false});
      
          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
            
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            this.setState({loading: true});
    
            let url = process.env.REACT_APP_API + config.API_ESOURCING_CRITERION+"/CreateCriterionDetail";
    
            await customAxios.post(url,
              values,
                { headers }).then((res) => {
                    this.setState({loading: false});
                    if (!res.data.operacionConExito) {
                      Swal("Error",res.data.error,"error");
                      this.setState({loading: false});
                    } 
                    else {
                    this.setState({loading: false, idSeccion:res.data.id});
                    console.log(res.data.id)
                    Swal("Registro exitoso!!","¡Criterio agregado con éxito." ,"success");  
                    this.setState({idDetalle:0, evaluacionDetalle:0, requerimientoDetalle:"", cargoDetalle:"", documentacionRequerida:""})
                    }
                })
              .catch((err) => {
                this.setState({loading: false});
                console.log("AXIOS ERROR: ", err);
              })

              let detallesDB = await this.getCriterionDetail(this.props.procurementId, this.state.values.id);
              let detalles = await this.renderDetallesTable(detallesDB)
              this.setState({tableDetalles:detalles});
        
        } catch (error) {
          console.log(error);
        }
      } 

      async getLotes(procurementId) {
        try {
      
          const bod = { Id: 0, ProcurementId:procurementId};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_SUBPROCESO;

            customAxios.patch(url,
                values,
                { headers }).then((res) => {
                  //console.log(res.data)
                this.setState({ listSubProcesos: res.data, loading: false });
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }      

      async getInformacionGeneral(procurementId) {
        try {
    
          const bod = { Id: procurementId, Title:"", TipoConsulta:""};
          const values = JSON.stringify(bod);
          //console.log(bod)
    
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
    
              this.setState({loadingC: true });
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_GENERALINFO;
            customAxios.patch(url,
                values,
                { headers }).then((res) => {
    //console.log(res.data)
                if(res.data)
                {
                    if(res.data[0].partialOfferId)
                      {
                        this.setState({partialOfferId:res.data[0].partialOfferId});
                      }
                }
    
                this.setState({ loadingC: false });
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loadingC: false });
              })
        } catch (error) {
          this.setState({loadingC: false });
          console.log(error);
        }
      } 
}

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

    export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(Criterios)));    