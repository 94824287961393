import React, { Component, Fragment } from "react";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { Button, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { FaPencilAlt, FaCheckSquare, FaRegSquare} from "react-icons/fa";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import Select from 'react-select';
import Loader from "react-loaders";
import TextareaAutosize from "react-textarea-autosize";
import Switch from "react-switch";
import Swal from 'sweetalert';

class ListaVerificacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
          values:{
            id:0,
            idCheckCategory:0,
            description:"",
            esObligatorio:false,
            loteId:0,
            listLotes:[],
          },
          tableLotes:"",
          listSubProcesos: [],
          idCheckCategorySe:{value:0, label:""},
          idLoteSe:{value:0, label:""},
          checkLis:[],
          categoryList:[
            {value:0, label:""},
            {value:1, label:"Garantía de sostenimiento"},
            {value:2, label:"Formulario de presentación"},
            {value:3, label:"Perfil de la empresa"}
          ],
          lotesList:[
            {value:0, label:""},
            {value:1, label:"Todos los lotes"},
            {value:2, label:"Lote específico"},
          ],          
          loading: false,
          modal: false,
          modalMsg: false,
          modalCheckList: false,
          mensajeOperacion: "",
          partialOfferId:0,
        };
    
        //this.handleEdit = this.handleEdit.bind(this);
        this.renderCheckListTable = this.renderCheckListTable.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleMsg = this.toggleMsg.bind(this);
        this.toggleCheckList = this.toggleCheckList.bind(this);
        this.handleSaveCheckList = this.handleSaveCheckList.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleChangeLote = this.handleChangeLote.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);

      }

      handleChangeLote(data)
      {
        let valor = this.state.values;
        valor.loteId = data.value;
    
        this.setState({values:valor, idLoteSe:data});

        if(data.value === 1){
          let myList = [];
          this.state.listSubProcesos.map((item, index) => {  
            let existeL = this.state.values.listLotes.find(element => element.subProcessId === item.id);
            if(existeL)
            {
              existeL.status =1;
              myList.push(existeL);      
            }
            else
              myList.push({id:0, criterionId:this.state.values.id, subProcessId:item.id, status:1});    
          });
         
          let valores = this.state.values;
          valores.listLotes = myList;
          this.setState({values:valores})

          let listArreglada =[];
          let item ={};
    
          if(this.state.listSubProcesos.length>0)
            {
              this.state.listSubProcesos.forEach(loteA =>{
                item = loteA;
                item.status = 2;
                myList.forEach(lote =>{
                      if(lote.subProcessId === loteA.id)
                      {
                        item.status =lote.status;
                      }
                    });
                    listArreglada.push(item);
                });
          }
    
        let lotesT = this.renderSubProcTable(listArreglada)
        this.setState({tableLotes:lotesT});
        this.setState({listSubProcesos:listArreglada});

        }




      }

      handleChangeCategory(data)
      {
        let valor = this.state.values;
        valor.idCheckCategory = data.value;
    
        this.setState({values:valor, idCheckCategorySe:data});
        
      }
      
      handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
          values: {
            ...this.state.values,
            [name]: value,
          },
        });
      };

      handleObligatorio = (e) => {
        let valores = this.state.values;
        valores.esObligatorio = !valores.esObligatorio;
        this.setState({values:valores});
      };

      toggle() {
        this.setState({
          modal: !this.state.modal,
        });
      }

      toggleMsg() {
        this.setState({
          modalMsg: !this.state.modalMsg,
        });
      }

      toggleCheckList() {
        this.setState({
          modalCheckList: !this.state.modalCheckList,
        });
      }

      componentDidMount() {
        this.getCheckList(this.props.procurementId);
        this.getLotes(this.props.procurementId);
        //this.setState({partialOfferId:this.props.partialOfferId});
        this.getInformacionGeneral(this.props.procurementId);
      }

      EnviarProcurementPage(procurementId){
        if(procurementId>0)
        {
            var data = {procurementId:procurementId};
            this.props.history.push({
                pathname: "/apps/procurement",
                query:data,
              })
        }
    }

    /*EditCheck(id, idCheckCategory, descripcion, esObligatorio)
    {
      let existe = this.state.categoryList.find(element => element.value === idCheckCategory);
      if(existe)
        this.setState({idCheckCategorySe:existe});

      let valores={id:id, idCheckCategory:idCheckCategory, description:descripcion, esObligatorio:esObligatorio }
      this.setState({values:valores});

      this.toggleCheckList();
    }*/


    EditCheck(id, idCheckCategory, descripcion, esObligatorio, listLotes, subProcessOptionId)
    {
      let existe = this.state.categoryList.find(element => element.value === idCheckCategory);
      if(existe)
        this.setState({idCheckCategorySe:existe});

      let existeL = this.state.lotesList.find(element => element.value === subProcessOptionId);
      if(existeL)
        this.setState({idLoteSe:existeL});      

      let valores={id:id, idCheckCategory:idCheckCategory, description:descripcion, esObligatorio:esObligatorio, listLotes: listLotes, loteId:subProcessOptionId }
      this.setState({values:valores});

      let listArreglada =[];
      let item ={};

      if(this.state.listSubProcesos.length>0)
        {
          this.state.listSubProcesos.forEach(loteA =>{
            item = loteA;
            item.status =2;
                
            listLotes.forEach(lote =>{
                  if(lote.subProcessId === loteA.id)
                  {
                    item.status =lote.status;
                  }
                });

                listArreglada.push(item);
            });
      }

    //lotes
    let lotes = this.renderSubProcTable(listArreglada)
    this.setState({tableLotes:lotes});

      this.toggleCheckList();
    }

    handleRowSelected (id, estado) {
      let valores = this.state.values;
      let lotes = valores.listLotes;
      let listCh =lotes;
      let listChF =[];
      let itemCh ={};
      let encontrado =false;

      if(listCh.length>0)
      {
        listCh.forEach(lote =>{
          if(lote.subProcessId === id)
          {
            itemCh = lote;
            itemCh.status = estado===1?2:1;
            listChF.push(itemCh)
            encontrado=true;
          }
          else
          {
            listChF.push(lote);
          }
        });
        
        if(!encontrado)
          listChF.push({id:0, criterionId:0, subProcessId:id, status:1});
      }
      else
      listChF.push({id:0, criterionId:0, subProcessId:id, status:1});

      valores.listLotes = listChF;
      
      this.setState({values:valores});

      let listArreglada =[];
      let item ={};

      if(this.state.listSubProcesos.length>0)
        {
          this.state.listSubProcesos.forEach(loteA =>{
            item = loteA;
            item.status = 2;
            listChF.forEach(lote =>{
                  if(lote.subProcessId === loteA.id)
                  {
                    item.status =lote.status;
                  }
                });
                listArreglada.push(item);
            });
      }

    let lotesT = this.renderSubProcTable(listArreglada)
    this.setState({tableLotes:lotesT});
    this.setState({listSubProcesos:listArreglada});
    };

    renderSubProcTable(litLotes) {
      const columns = [
        {
          name: "",
          width: "5rem",
          cell: (row) => {
            return (
              <Fragment>
                <button
                  className="btn btn-outline-primary"
                  onClick={(id) => this.handleRowSelected(row.id, row.status)}
                >
                  {row.status===1?<FaCheckSquare size={16} />:<FaRegSquare size={16} />}
                </button>
              </Fragment>
            );
          },
        },        
        {
          name: this.props.t('Descripción'),
          id: "code",
          //width: "25rem",
          selector: (row) => row.description,
          sortable: true,
        },
        {
          name: this.props.t('Numero'),
          //width: "10rem",
          selector: (row) => row.number,
          sortable: true,
        },
      ];
  
      return (
        <div>
          <DataTable
            data={litLotes}
            columns={columns}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            paginationComponentOptions={{
              rowsPerPageText: "Registros por pagina:",
              rangeSeparatorText: "de",
            }}
          />
        </div>
      );
    }


    renderCheckListTable(listCriterion) {
        const columns = [
          {
            name: "",
            width: "5rem",
            cell: (row) => {
              return (
                <Fragment>
                  <button
                    className="btn btn-outline-primary"
                    disabled={!this.props.editProccess}
                    onClick={(id) => this.EditCheck(row.id, row.checkCategoryId, row.description, row.isMandatory, row.subProcess, row.subProcessOptionId)}
                  >
                    <FaPencilAlt size={16} />
                  </button>
                </Fragment>
              );
            },
          },
          {
            name: this.props.t('Description'),
            id: "code",
            //width: "25rem",
            selector: (row) => row.description,
            sortable: true,
          },
          {
            name: this.props.t('Categoria'),
            //width: "10rem",
            selector: (row) => row.checkCategoryName,
            sortable: true,
          },          {
            name: this.props.t('¿Es obligatorio?'),
            //width: "10rem",
            selector: (row) => row.isMandatory===true?"Este documento es obligatorio":"Este documento no es obligatorio",
            sortable: true,
          },
        ];
    
        return (
          <div>
            <DataTable
              data={listCriterion}
              columns={columns}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Registros por pagina:",
                rangeSeparatorText: "de",
              }}
            />
          </div>
        );
      }

      render() {
        let contents = this.state.loading ? (
          <p>
            <em>{this.props.t('Loading')}...</em>
          </p>
        ) : (
          this.renderCheckListTable(this.state.checkLis)
        );
    
        //const mensajeOperacion = this.state.mensajeOperacion;
    
        return (
                <div>
                  <Card style={{margin:"5px"}}>
                  <Row>
                  <Col md="12">
                            <div className="px-1">
                              <Row>
                              <Col>
                                <div className="grid-menu grid-menu-3col">
                                  <Row className="g-0">
    
                                    <Col md={3}>
                                        <div className="widget-chart" style={{background: "#FFF"}}>
                                            <div className="icon-wrapper rounded-circle">
                                            <div className="icon-wrapper-bg" style={{background: "#0092D1"}} />
                                              <i className="lnr-layers text-primary" />
                                            </div>
                                            <div className="widget-numbers" style={{fontSize: "12px"}}>{this.state.countItems} {this.props.t('Check List')}</div>
                                        </div>
                                      </Col>
    
    
                                    <Col md={4}>
                                      <Button className="btn-icon-vertical btn-square btn-transition" style={{height: "100%"}} outline color="primary" 
                                        disabled={!this.props.editProccess}
                                        onClick={()=>this.EditCheck(0, 0, '', false, [], 0)} >
                                        <i className="lnr-file-add btn-icon-wrapper"> </i>
                                                  Agregar item
                                      </Button>
                                      </Col>
                                  </Row>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                    </Col>
                  </Row>
                  </Card>
                  <Card style={{margin:"5px"}}>
                  <Row>
                    <Col md="12">
                        <h3 style={{paddingLeft: "10px", paddingTop:"10px"}}>{this.props.t('Lista de documentos que el proveedor debe presentar.')}</h3>
                        <div className="px-0">
                          <CardBody className="p-2">
                            {contents} <br/></CardBody>
                        </div>
                    </Col>
                  </Row>
                  </Card>
                  <Modal
                      isOpen={this.state.modalMsg}
                      toggle={this.toggleMsg}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleMsg}>{this.props.t('Project')}s</ModalHeader>
                      <ModalBody>
                        <p>{this.state.mensajeOperacion}</p>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={this.toggleMsg}>
                        {this.props.t('OK')}
                        </Button>
                      </ModalFooter>
                    </Modal>

                    <Modal
                      isOpen={this.state.modalCheckList}
                      toggle={this.toggleCheckList}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleCheckList}>{this.props.t('Agregar criterio')}s</ModalHeader>
                      <ModalBody>
                        <div className="mb-2">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                {"Categoria"}
                              </label>
                              <Select
                                  defaultValue={this.state.idCheckCategorySe}
                                  id="idEtapa"
                                  name="idEtapa"
                                  placeholder="Please select item"
                                  isSearchable={true}
                                  onChange={this.handleChangeCategory}
                                  options={this.state.categoryList}
                                />  
                        </div>     
                        <div className="mb-2">
                            <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                            >
                                {"Description"}
                            </label>
                            <TextareaAutosize className="form-control" minRows={3} maxRows={6} defaultValue="" 
                            name="description"
                            id="description"
                            placeholder="Por favor describa el problema o solicitud que necesita"
                            value={this.state.values.description} 
                            onChange={this.handleChange}
                            />
                        </div>   
                        <div className="mb-2">
                        <label htmlFor="exampleInputEmail1" className="form-label"> {"Este documento es obligatorio para el proveedor"} </label>
                        
                        <Switch 
                                  onChange={this.handleObligatorio} 
                                  checked={this.state.values.esObligatorio}
                                  className="me-2 mb-2" id="generaDdt"/>
                        </div>        
                        {this.state.partialOfferId===1?
                        <><div className="mb-2">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                {"Opción de lote"}
                              </label>
                              <Select
                                  defaultValue={this.state.idLoteSe}
                                  id="idLote"
                                  name="idLote"
                                  placeholder="Please select item"
                                  isSearchable={true}
                                  onChange={this.handleChangeLote}
                                  options={this.state.lotesList}
                                />  
                        </div>
                        {this.state.values.loteId>0?(this.state.tableLotes):""}                                                                       
                          {this.state.loading ? <div><Loader type="ball-beat" />
                                  <p>
                                    <em>Creating criterion record...</em>
                                  </p></div> : ""}
                        
                        </>:""}
                      </ModalBody>
                      <ModalFooter>
                      <Button color="primary" 
                                onClick={this.handleSaveCheckList}
                                >
                              {"Guardar"}
                              </Button>{" "}
                      </ModalFooter>
                    </Modal>                      
                </div>
        );
      }

      async getCheckList(procurementId) {
        try {
      
          const bod = { Id: 0, ProcurementId:procurementId};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_CHECKLIST;

            customAxios.patch(url,
                values,
                { headers }).then((res) => {
                this.setState({ checkLis: res.data, loading: false });
                //console.log(res.data)
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }

      async handleSaveCheckList() {
        try {
    
          if(this.state.values.idCheckCategory <=0)
          {
            Swal("Error", "Por favor indique la categoria del item para la lista de verificación.","warning");
            return;
          }

          if(this.state.values.description==='')
            {
              Swal("Error", "Por favor indique la descripción para la lista de verificación.","warning");
              return;
            }

            let valores = this.state.values;
            let lotes = valores.listLotes;
            let listCh =[];
            let itemCh ={};
      
            if(lotes.length>0)
            {
              lotes.forEach(lote =>{
                  itemCh = {Id:lote.id, CriterionId:lote.criterionId, SubProcessId:lote.subProcessId, status:lote.status};
                  listCh.push(itemCh)
              });
            }            
  
          const bod = {  Id: this.state.values.id, 
            ProcurementId:this.props.procurementId, 
            CheckCategoryId: this.state.values.idCheckCategory, 
            CheckCategoryName:"", 
            Description:this.state.values.description, 
            IsMandatory:this.state.values.esObligatorio,
            SubProcess:listCh,
            SubProcessOptionId:this.state.idLoteSe.value,
            Status:1};
          const values = JSON.stringify(bod);
          
          this.setState({loading: false});
      
          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
            
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            this.setState({loading: true});
    
            let url = process.env.REACT_APP_API + config.API_ESOURCING_CHECKLIST;
    
            customAxios.post(url,
              values,
                { headers }).then((res) => {
                    this.setState({loading: false});
                    if (!res.data.operacionConExito) {
                      //this.setState({ mensajeOperacion: res.data.error });
                      Swal("Error",res.data.error,"error");
                      this.setState({loading: false});
                      //this.toggleMsg();
                    } 
                    else {
                    this.setState({loading: false});
                    //this.setState({ mensajeOperacion: "Item agregado a lista de verificación con éxito." });
                    Swal("Registro exitoso!!","¡Item agregado a lista de verificación con éxito." ,"success");
                    this.toggleCheckList();
                    //this.toggleMsg();
                    this.getCheckList(this.props.procurementId);
                    }
                })
              .catch((err) => {
                this.setState({loading: false});
                console.log("AXIOS ERROR: ", err);
                //this.setState({loadingG: false});
              })
        } catch (error) {
          console.log(error);
        }
      }  
      
      async getLotes(procurementId) {
        try {
      
          const bod = { Id: 0, ProcurementId:procurementId};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_SUBPROCESO;

            customAxios.patch(url,
                values,
                { headers }).then((res) => {
                this.setState({ listSubProcesos: res.data, loading: false });
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      } 

      async getInformacionGeneral(procurementId) {
        try {
    
          const bod = { Id: procurementId, Title:"", TipoConsulta:""};
          const values = JSON.stringify(bod);
          //console.log(bod)
    
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
    
              this.setState({loadingC: true });
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_GENERALINFO;
            customAxios.patch(url,
                values,
                { headers }).then((res) => {
    //console.log(res.data)
                if(res.data)
                {
                  if(res.data[0].partialOfferId)
                    {
                      this.setState({partialOfferId:res.data[0].partialOfferId});
                    }
                }
    
                this.setState({ loadingC: false });
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loadingC: false });
              })
        } catch (error) {
          this.setState({loadingC: false });
          console.log(error);
        }
      } 
}

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

    export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(ListaVerificacion)));    