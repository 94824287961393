import React, { Component, Fragment } from "react";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { Button, Row, Col, Card, CardBody, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input} from "reactstrap";
import {FaPencilAlt, FaTrash, FaCheckSquare, FaRegSquare} from "react-icons/fa";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import Swal from 'sweetalert';
import Loader from "react-loaders";
import ReactQuill from "react-quill";
import 'quill/dist/quill.snow.css';
import Select from 'react-select';
import { is } from "date-fns/locale";


const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ size: [] }],
    [{ font: [] }],
    [{ align: ["right", "center", "justify"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: ["red", "#785412"] }],
    [{ background: ["red", "#785412"] }]
  ]
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
  "size",
  "font"
];

const Temp_Subprocess = {
  "Id": 0,
  "CheckListId": 0,
  "QuestionId": 0,
  "CreatedOn": null,
  "Status": 0,
  "Description": null,
  "SubProcessId": 0
}

const StatusForm = {
  New : 1,
  Edit : 2
}

class Cuestionario extends Component {
    constructor(props) {
        super(props);
        this.state = {
          globalHeaders: {},
          listQuestionnaire:[],
          listQuestions:[],
          listSubProcessByQuestion : [],
          listSubProcessSelected : [],
          // values:{
          //   id:0,
          //   idEtapa:{value:0, label:""},
          //   description:"",
          //   tipoCriterio:"Aceptación/Rechazo",
          // },
          pregunta:{id:0, questionnaireId:0, name:""},
          
          listOptionsSubprocess:[],
          // etapasList:[
          //   {value:0, label:""},
          //   {value:1, label:"Criterios formales y de elegibilidad"},
          //   {value:2, label:"Criterios de calificación"},
          //   {value:3, label:"Criterios técnicos"}
          // ],
          loading: false,
          modal: false,
          modalMsg: false,
          modalCuestionario: false,
          mensajeOperacion: "",
          textoIngresado:"",
          statusQuestionnaire: StatusForm.New,
          valorOpcionLote : {value:0, label:""},
          listOpcionLote:[
            {value:0, label:""},
            {value:1, label:"Todos los lotes"},
            {value:2, label:"Lote específico"},
            {value:3, label:"No Aplica Oferta Parcial"},
          ],
          listSubProcess: [] ,
          isQuestionWithouSubProcess: false,         
        };
    
        this.renderQuestionsTable = this.renderQuestionsTable.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleMsg = this.toggleMsg.bind(this);
        this.toggleCuestionario = this.toggleCuestionario.bind(this);
        this.onClickSaveCuestionario = this.onClickSaveCuestionario.bind(this);
        this.handleChangeEtapa = this.handleChangeEtapa.bind(this);
        this.onClickGuardarPregunta = this.onClickGuardarPregunta.bind(this);
        this.onClickSaveSubProcessByQuestion = this.onClickSaveSubProcessByQuestion.bind(this);
        this.onChangeSelectOpcionLote = this.onChangeSelectOpcionLote.bind(this);
        this.onClickDisableSubProcessByQuestion = this.onClickDisableSubProcessByQuestion.bind(this);
      }


  async componentDidMount() {
    await this.setState({ 
                  globalHeaders : {
                    "Access-Control-Allow-Origin": true,
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.props.auth.accessToken,
                  } 
                });

    this.getQuestionnaire(this.props.procurementId);
    this.getSubProcess(this.props.procurementId);
  }

//#region Get Data
  async getQuestionnaire(procurementId) {
    try {

      const bod = { Id: 0, ProcurementId:procurementId, Name:""};
      const values = JSON.stringify(bod);

      const headers= this.state.globalHeaders    

      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      let url = process.env.REACT_APP_API + config.API_ESOURCING_QUESTIONNAIRE;

      customAxios.patch(url, values, { headers })
        .then((res) => {
          this.setState({ listQuestionnaire: res.data, loading: false });

          if(res.data !== null ) {
            if(res.data.length > 0){
              this.getQuestion(res.data[0].id);
            }
            
          }

        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      console.log(error);
    }
  }

  async getQuestion(questionnaireId) {
    try {

      const bod = { Id: questionnaireId, ProcurementId:0, Name:""};
      const values = JSON.stringify(bod);

      const headers= this.state.globalHeaders          

      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      let url = process.env.REACT_APP_API + config.API_ESOURCING_QUESTIONNAIRE + "/getQuestions";

      customAxios.patch(url, values, { headers })
        .then((res) => {
          this.setState({ listQuestions: res.data, loading: false });          
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      console.log(error);
    }
  }

  async getSubProcessByQuestion(questionId) {
    try {

      const bod = { Id: questionId, ProcurementId:0, Name:""};
      const values = JSON.stringify(bod);

      const headers= this.state.globalHeaders          

      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      let url = process.env.REACT_APP_API + config.API_ESOURCING_QUESTIONNAIRE + "/getSubProcessByQuestion";
      
      customAxios.patch(url, values, { headers })
        .then((res) => {         
          let listSubProcessSelected = [];

          if(res.data.length>0){

            res.data.map((item) => {           
              listSubProcessSelected.push({SubProcessId: item.subProcessId, id:item.id, description:item.description, status:item.status });
            }); 

            this.setState({listSubProcessSelected });
            this.setState({isQuestionWithouSubProcess: false});

          }else{
            
            this.state.listSubProcess.map((item, index) => {       
              listSubProcessSelected.push({SubProcessId: item.id, id:index , description:item.description, status:2 });
            });
            
            this.setState({listSubProcessSelected});
            this.setState({isQuestionWithouSubProcess: true});
          }

          //console.log(res.data)

          

          //this.onChangeSelectOpcionLote(this.state.listOpcionLote[2]);

          this.setState({ loading: false });
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      console.log(error);
    }
  }

  async getSubProcess(procurementId) {
    try {
      let url = process.env.REACT_APP_API + config.API_ESOURCING_SUBPROCESO;
  
      const bod = { Id: 0, ProcurementId:procurementId };
      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      const values = JSON.stringify(bod);      
      const headers= {
                        "Access-Control-Allow-Origin": true,
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.props.auth.accessToken,
                      }
    
      customAxios.patch(url, values, { headers })
        .then((res) => {
          
          //Value default
          // let listOptions = [{ value: -1, label: "----Agregar todos los lotes-----" }];              
          // res.data.map((item) => {                  
          //   listOptions.push({ value: item.id, label: item.description });
          // });	

          this.setState({ listSubProcess: res.data });


          //console.log(res.data)
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      console.log(error);
    }
  }
  
//#endregion


//#region Events
  onClickSaveCuestionario() {
    try {
      if(this.state.listQuestionnaire.length<=0)
        {
          Swal("Error","Por favor cargue información para el cuestionario","warning");
          return;
        }


      if(this.state.listQuestionnaire[0].name ==='')
      {
        Swal("Error","Por favor indique el titulo del cuestionario","warning");
        return;
      }

      const bod = {  
                    Id: this.state.listQuestionnaire[0].id, 
                    ProcurementId:this.props.procurementId, 
                    Name: this.state.listQuestionnaire[0].name, 
                    Status:1,
                    QuestionList: null 
                  };

      const values = JSON.stringify(bod);
      this.setState({loading: false});
      const headers= this.state.globalHeaders
        
      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      this.setState({loading: true});

      let url = process.env.REACT_APP_API + config.API_ESOURCING_QUESTIONNAIRE;
      customAxios.post(url, values, { headers })
        .then((res) => {
              this.setState({loading: false});
              if (!res.data.operacionConExito) {
                Swal("Error",res.data.error,"warning");
                this.setState({loading: false});
              } 
              else {
                this.setState({loading: false});
                Swal("Registro exitoso!!","¡Cuestionario agregado con éxito." ,"success");
                this.getQuestionnaire(this.props.procurementId);
              }
        })
        .catch((err) => {
          this.setState({loading: false});
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      console.log(error);
    }
  }  

  onClickEditPregunta(idPregunta, texto, subProcessOptionId){
    this.setState({pregunta:{id:idPregunta, questionnaireId:0, name:texto}});
    this.setState({textoIngresado:texto});
    this.setState({statusQuestionnaire: idPregunta === 0 ? StatusForm.New :  StatusForm.Edit});   
    
    if(idPregunta === 0){
      this.setState({valorOpcionLote: this.state.listOpcionLote[0] });
      //Nueva pregunta
      let listSubProcessSelected = [];
      this.state.listSubProcess.map((item, index) => {        
        listSubProcessSelected.push({SubProcessId: item.id, id:index , description:item.description, status:2 });

      });
      this.setState({listSubProcessSelected});
    }else{

      this.state.listOpcionLote.map((item) => {
        if(item.value === subProcessOptionId){
          this.setState({valorOpcionLote:item});        
        }
      });
      
      this.getSubProcessByQuestion(idPregunta);

    }

    this.toggleCuestionario();
  }

  onClickGuardarPregunta(){
    try {

      let listSubProcessSelected = [];
      this.state.listSubProcessSelected.map((item) => {
        listSubProcessSelected.push({ 
          "Id": this.state.pregunta.id === 0 || this.state.isQuestionWithouSubProcess ? 0 : item.id,
          "CheckListId": 0,
          "QuestionId": this.state.pregunta.id,
          "CreatedOn": null,
          "Status":  item.status,
          "Description": "",
          "SubProcessId": item.SubProcessId
        });
      });

      const bod = {
        Id: this.state.pregunta.id,
        QuestionnaireId: this.state.listQuestionnaire[0].id,
        Name: this.state.textoIngresado,
        Answer: null,
        SubProcessOptionId : this.state.valorOpcionLote.value,
        Status : 1,
        SubProcess : listSubProcessSelected
       };

      const values = JSON.stringify(bod);
      const headers= this.state.globalHeaders          

      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      let url = process.env.REACT_APP_API + config.API_ESOURCING_QUESTIONNAIRE + "/createQuestion ";

      customAxios.put(url, values, { headers })
        .then((res) => {

          if (res.data.operacionConExito) {
            Swal("Exito", "Pregunta Guardada","success");
            this.getQuestion(this.state.listQuestionnaire[0].id); 
          }else{
            Swal("Error",res.data.error,"warning");
          }

        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      console.log(error);
    }
    this.toggleCuestionario();
 
  }

  onClickSaveSubProcessByQuestion(){
    let listSubProcess = []; 
    
    if (this.state.valorOpcionLote.value === -1) {
      this.state.listOptionsSubprocess.map((item) => {
        
        if (item.value !== -1) {
          listSubProcess.push({ 
            "Id": 0,
            "CheckListId": 0,
            "QuestionId": this.state.pregunta.id,
            "CreatedOn": null,
            "Status": 0,
            "Description": item.label,
            "SubProcessId": item.value});
        }

      });
    } else {
      listSubProcess.push({ 
        "Id": 0,
        "CheckListId": 0,
        "QuestionId": this.state.pregunta.id,
        "CreatedOn": null,
        "Status": 0,
        "Description": this.state.valorOpcionLote.label,
        "SubProcessId": this.state.valorOpcionLote.value});  
    }
   
    
    try {
      const bod = listSubProcess;

      const values = JSON.stringify(bod);
      const headers= this.state.globalHeaders          

      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      let url = process.env.REACT_APP_API + config.API_ESOURCING_QUESTIONNAIRE + "/createSubProcessByQuestion ";

      customAxios.put(url, values, { headers })
        .then((res) => {

          if (res.data.operacionConExito) {
            Swal("Exito", "","success");
            this.getSubProcessByQuestion(this.state.pregunta.id); 
            //this.setState({valorLote:null});
          }else{
            Swal("Error",res.data.error,"warning");
          }

        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      console.log(error);
    }
  }

  onChangeSelectOpcionLote(data){
    this.setState({valorOpcionLote:data});    
    
    if(data.value !== 2){
      let listSubProcessSelected = [];
      this.state.listSubProcessSelected.map((item, index) => {   

        listSubProcessSelected.push({SubProcessId: item.SubProcessId, id:item.id , description:item.description, status:1 });
        //console.log(item);

      });
      this.setState({listSubProcessSelected});   
      
    }

  }

  onClickDisableSubProcessByQuestion(QuestionSubProcessId){
    try {

      
      const bod = { Id: QuestionSubProcessId, ProcurementId:0, Name:""};

      const values = JSON.stringify(bod);
      const headers= this.state.globalHeaders          

      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      let url = process.env.REACT_APP_API + config.API_ESOURCING_QUESTIONNAIRE + "/disableSubProcessByQuestion ";

      customAxios.post(url, values, { headers })
        .then((res) => {

          if (res.data.operacionConExito) {
            
            Swal("Exito", "","success");
            this.getSubProcessByQuestion(this.state.pregunta.id); 
            this.setState({valorLote:null});

          }else{
            Swal("Error",res.data.error,"warning");
          }

        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      console.log(error);
    }
 
  }

  handleRowSelected (SubProcessId, estado) {
    let listSubProcessSelected = this.state.listSubProcessSelected;
    listSubProcessSelected.map((item) => {      

      if(item.SubProcessId === SubProcessId)
      {
        item.status = estado === 1 ? 2 : 1;
      }      

    });
    this.setState({listSubProcessSelected});
  }


//#endregion
 
//#region Render Tables
  renderQuestionsTable(questionnaire) {
    const columns = [
      {
        name: "",
        width: "5rem",
        cell: (row) => {
          return (
            <Fragment>
              <button
                className="btn btn-outline-primary"
                disabled={!this.props.editProccess}
                onClick={(id) => this.onClickEditPregunta(row.id, row.name, row.subProcessOptionId)}
              >
                <FaPencilAlt size={16} />
              </button>
            </Fragment>
          );
        },
      },
      {
        name: this.props.t('Pregunta'),
        id: "code",
        //width: "25rem",
        selector: (row) => {
          return <div dangerouslySetInnerHTML={{ __html: row.name }} />
        },
        sortable: true,
      },
    ];

    return (
      <div>
        <DataTable
          data={questionnaire}
          columns={columns}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 15, 25, 50]}
          paginationComponentOptions={{
            rowsPerPageText: "Registros por pagina:",
            rangeSeparatorText: "de",
          }}
        />
      </div>
    );
  }

  renderSubProcessTable(questionSubprocess) {
    const columns = [
      {
        name: "",
        width: "5rem",
        cell: (row) => {
          return (
            <Fragment>
            <button
              className="btn btn-outline-primary"              
              disabled={this.state.valorOpcionLote.value === 1 ? true : false}
              onClick={(SubProcessId) => this.handleRowSelected(row.SubProcessId, row.status)}
            >
              {row.status===1?<FaCheckSquare size={16} />:<FaRegSquare size={16} />}
            </button>
          </Fragment>
          );
        },
      },
      {
        name: this.props.t('Decription'),
        id: "code",
        //width: "25rem",
        selector: (row) => {
          let value = row.Description === null || row.Description === undefined ? row.description : row.Description;
          return value;
        } ,
        sortable: true,
      },
    ];

    return (
      <div>
        <DataTable
          data={questionSubprocess}
          columns={columns}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 15, 25, 50]}
          paginationComponentOptions={{
            rowsPerPageText: "Registros por pagina:",
            rangeSeparatorText: "de",
          }}
        />
      </div>
    );
  }
//#endregion
      handleChangeEtapa(data)
      {
        let valor = this.state.values;
        valor.idEtapa = data;
        this.setState({values:valor});
      }
      
      handleChange = (e) => {
        const { name, value } = e.target;

        if(this.state.listQuestionnaire.length===0)
        {
            let cuestionario = {id:0, ProcurementId:this.props.procurementId, name:value, status:1, questionList:[]}
            let listaV = [];
            listaV.push(cuestionario)
            this.setState({listQuestionnaire:listaV})
        }
        else
        {
            let lista = this.state.listQuestionnaire;
            lista[0].name = value;
            this.setState({listQuestionnaire:lista})
        }
      };

      toggle() {
        this.setState({
          modal: !this.state.modal,
        });
      }

      toggleMsg() {
        this.setState({
          modalMsg: !this.state.modalMsg,
        });
      }

      toggleCuestionario() {
        this.setState({          
          modalCuestionario: !this.state.modalCuestionario,
        });
      }



      EnviarProcurementPage(procurementId){
        if(procurementId>0)
        {
            var data = {procurementId:procurementId};
            this.props.history.push({
                pathname: "/apps/procurement",
                query:data,
              })
        }
    }

    handleProcedureContentChange = (content, delta, source, editor) => {
        this.setState({textoIngresado:content})
      };

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>{this.props.t('Loading')}...</em>
      </p>
    ) : (
      this.renderQuestionsTable(this.state.listQuestions)
    );

    return (
      <div>
        <Card className="main-card m-2">
            <CardBody  className="p-3">
            <div className="mb-2">
                <label htmlFor="exampleInputEmail1" className="form-label" >
                    {"Titulo"}
                </label>
                <input type="text" className="form-control"
                    id="title" aria-describedby="emailHelp"
                    name="title" value={this.state.listQuestionnaire[0]?.name}
                    ref="title" onChange={this.handleChange} ></input> 
            </div>                    
        <Row>
        </Row>  
            {this.state.loading ? <div><Loader type="ball-beat" />
                    <p>
                    <em>Creating procurement record...</em>
                    </p></div> : ""}
            </CardBody>
            <CardFooter>
            <Button color="primary" 
              disabled={!this.props.editProccess}
              onClick={this.onClickSaveCuestionario} >
                {"Guardar"}
            </Button>
            {" "}
            &nbsp;
            <Button color="primary" 
              disabled={!this.props.editProccess}
              onClick={()=>this.onClickEditPregunta(0, "")} >
              {"Agregar pregunta"}
            </Button>
            {" "}
            </CardFooter>
        </Card>

        <Card className="main-card m-2">
        <Row>
          <Col md="12">
              <h3 style={{paddingLeft: "10px", paddingTop:"10px"}}>{this.props.t('Lista de preguntas!!')}</h3>
              <div className="px-0">
                <CardBody className="p-2">
                  {contents} <br/></CardBody>
              </div>
          </Col>
        </Row>
        </Card>

        <Modal
            isOpen={this.state.modalMsg}
            toggle={this.toggleMsg}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleMsg}>{this.props.t('Project')}s</ModalHeader>
            <ModalBody>
              <p>{this.state.mensajeOperacion}</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggleMsg}>
              {this.props.t('OK')}
              </Button>
            </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalCuestionario}
          toggle={this.toggleCuestionario}                      
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCuestionario} >{this.props.t('Descripción de la pregunta')}s</ModalHeader>
          <ModalBody>
            <div className="mb-2">
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={this.state.textoIngresado}
                    onChange={this.handleProcedureContentChange}
                  />
                <div>
                    <Label>Agregar Lotes</Label>
                    <Select 
                      placeholder="Seleccione opción"
                      onChange={this.onChangeSelectOpcionLote}
                      defaultValue={this.state.valorOpcionLote}
                      options={this.state.listOpcionLote} />                   

                    {this.state.valorOpcionLote.value === 2 || this.state.valorOpcionLote.value === 1 ? this.renderSubProcessTable(this.state.listSubProcessSelected) : <></>}
                    
                  </div>

                  
            </div>     
              {this.state.loading ? <div><Loader type="ball-beat" />
                      <p>
                        <em>Creating criterion record...</em>
                      </p></div> : ""}
          </ModalBody>
          <ModalFooter>
          <Button color="primary" 
                    onClick={()=>this.onClickGuardarPregunta()}
                    >
                  {"OK"}
                  </Button>{" "}
          </ModalFooter>
        </Modal>                      
      </div>
    );
  }
 



}

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

    export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(Cuestionario)));    