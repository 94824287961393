import React, { Component, Fragment } from "react";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import { setToken } from "../../../../actions/authActions";
import { Button, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader} from "reactstrap";
import {FaFileAlt} from "react-icons/fa";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import Loader from "react-loaders";
import Swal from 'sweetalert';
import ReactQuill from "react-quill";
import 'quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ size: [] }],
    [{ font: [] }],
    [{ align: ["right", "center", "justify"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: ["red", "#785412"] }],
    [{ background: ["red", "#785412"] }]
  ]
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
  "size",
  "font"
];


class Enmiendas extends Component {
    constructor(props) {
        super(props);
        this.state = {
          values:{
            id:0,
          },
          solicitudAclaracion:"",
          respuestaIngresada:"",
          supplierName:"",
          listCriterions:[],
          listAclaracionesPost:[],
          listSubProcesos: [],
          rowsSelect:[],
          tableLotes:"",
          loading: false,
          modalRespuesta: false,
          mensajeOperacion: "",
        };
    
        this.toggleRespuesta = this.toggleRespuesta.bind(this);
        this.handleSaveAclaracion = this.handleSaveAclaracion.bind(this);
      }

      handleProcedureContentChange = (content, delta, source, editor) => {
        this.setState({respuestaIngresada:content})
      };

      toggleRespuesta() {
        this.setState({
          modalRespuesta: !this.state.modalRespuesta,
        });
      }

      componentDidMount() {
        this.getAclaracionPost(this.props.procurementId);
      }

    EditAclaracion(id, respuesta)
    {
      let valores = this.state.values;
      valores.id=id;
      this.setState({values:valores, respuestaIngresada:respuesta});
      this.toggleRespuesta();
    }

    renderAclaracionPostTable(listAclaraciones) {
        const columns = [
          {
            name: "",
            width: "5rem",
            cell: (row) => {
              return (
                <Fragment>
                  <button
                    className="btn btn-outline-primary"
                    onClick={(id) => this.EditAclaracion(row.id, row.description)}
                  >
                  <FaFileAlt size={16} />
                  </button>
                </Fragment>
              );
            },
          },
          {
            name: this.props.t('Enmienda'),
            id: "code",
            //width: "25rem",
            selector: (row) => <div
                                    dangerouslySetInnerHTML={{ __html: row.description }}>
                                </div>,
            sortable: true,
          },
          {
            name: this.props.t('Fecha'),
            //width: "15rem",
            selector: (row) => row.amendmentDate,
            sortable: true,
          },
          {
            name: this.props.t('Publicado'),
            //width: "15rem",
            selector: (row) => row.published?"Si":"No",
            sortable: true,
          },          
        ];
    
        return (
          <div>
            <DataTable
              data={listAclaraciones}
              columns={columns}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Registros por pagina:",
                rangeSeparatorText: "de",
              }}
            />
          </div>
        );
      }      

      render() {
        let contentsPost = this.state.loading ? (
          <p>
            <em>{this.props.t('Loading')}...</em>
          </p>
        ) : (
          this.renderAclaracionPostTable(this.state.listAclaracionesPost)
        );        
        return (
                <div>
                  <Row>
                    <Col md="12">
                      <Card className="pt-4 mx-2">
                      <CardHeader>
                        <h3 style={{paddingLeft: "10px", paddingTop:"10px"}}>{this.props.t('Post aclaraciones!!')}</h3>
                      </CardHeader>                        
                      <div className="px-0">
                            {contentsPost} <br/>
                        </div>  
                        <CardHeader>
                        <Button color="primary" onClick={(id) => this.EditAclaracion(0,"")}>
                        {this.props.t('Agregar')}
                        </Button>
                        </CardHeader>                      
                      </Card>
                    </Col>
                  </Row>
                  <Modal
                      isOpen={this.state.modalRespuesta}
                      toggle={this.toggleRespuesta}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleRespuesta}>{"Agregar aclaración"}</ModalHeader>
                      <ModalBody>
                        <div className="mb-2">
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={this.state.respuestaIngresada}
                                onChange={this.handleProcedureContentChange}
                              />
                        </div>     
                                                           
                          {this.state.loading ? <div><Loader type="ball-beat" />
                                  <p>
                                    <em>Registrando aclaración...</em>
                                  </p></div> : ""}
                      </ModalBody>
                      <ModalFooter>
                      <Button color="primary" 
                                onClick={()=>this.handleSaveAclaracion()}
                                >
                              {"Agregar"}
                              </Button>{" "}
                      </ModalFooter>
                    </Modal>                      
                </div>
        );
      }

      async handleSaveAclaracion() {
        try {
    
          if(this.state.respuestaIngresada==='')
            {
              Swal("Advertencia","Por favor agregue información de la enmienda a publicar.","warning");
              return;
            }

          const bod = {  Id: this.state.values.id, 
            ProcurementId:this.props.procurementId,
            Ask:"",
            Answer:this.state.respuestaIngresada, 
            UserId:this.props.auth.user.idUsuario,
            Status:1,
            };

          const values = JSON.stringify(bod);
          
          this.setState({loading: false});
      
          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
            
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            this.setState({loading: true});
    
            let url = process.env.REACT_APP_API + config.API_ESOURCING_REVIEWS+"/AddAmendmentPost";
    
            customAxios.post(url,
              values,
                { headers }).then((res) => {
                    this.setState({loading: false});
                    if (!res.data.operacionConExito) {
                      Swal("Error",res.data.error,"error");
                      this.setState({loading: false});
                    } 
                    else {
                    this.setState({loading: false});
                    Swal("Registro exitoso!!","¡Enmienda registrada con éxito." ,"success");  
                    this.toggleRespuesta();
                    this.getAclaracionPost(this.props.procurementId);
                    }
                })
              .catch((err) => {
                this.setState({loading: false});
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }  

      async getAclaracionPost(procurementId) {
        try {
      
          const bod = { Id: this.props.procurementId, Title:"", TipoConsulta:""};
          const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }
        
            const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_REVIEWS+"/GetAmendmentPosts";

            customAxios.post(url,
                values,
                { headers }).then((res) => {
                  console.log(res.data)
                this.setState({ listAclaracionesPost: res.data, loading: false });
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
              })
        } catch (error) {
          console.log(error);
        }
      }      

}

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => {
      return {
        setToken: (token) => {
          dispatch(setToken(token));
        }
      }
    };

    export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(Enmiendas)));    