import React, { Component, Fragment } from "react";
//import PageTitle from "../../../Layout/AppMain/PageTitle";
import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";
import Catalogo from "../../../../util/catalogo";
import { setToken } from "../../../../actions/authActions";
import { Button, FormGroup, Label, Row, Col, Card, CardBody, CardFooter, Input, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Loader from "react-loaders";
import Swal from 'sweetalert';
import Select from 'react-select';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // The locale you want: vi, en, cn, in,..etc .
import { withTranslation, WithTranslation } from 'react-i18next';
import SubProcesos from "./subProcesos";
import ReactQuill from "react-quill";
import 'quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ size: [] }],
    [{ font: [] }],
    [{ align: ["right", "center", "justify"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: ["red", "#785412"] }],
    [{ background: ["red", "#785412"] }]
  ]
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
  "size",
  "font"
];


class InfoGeneral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      values: {
        id: 0,
        title: "",
        description: "",
        processTypeId:0,
        processTypeT:0,
        reference:"",
        esProcesoEmergencia:"No",
        esCooperacionconONU:"No",
        ofertaParcial:"No",
        fechaLimiteOfertas:new Date(),
        fechaLimiteAclaraciones:new Date(),
        organizationalUnit:"-",
        beneficiaryCountries:"-",
        renderizado:0,
        editProccess:false,
        partialOfferId:0,
      },
      processType:{value:0, label:""},
      procurementId:0,
      proccessTypes:[],//[{value:0, label:""},{value:1, label:"ITB"}],
      infoGenral:[],
      loading: false,
      modalMsg: false,
      modalSubProc: false,
      };

      registerLocale('es', es)
    this.handleChangeProcessType = this.handleChangeProcessType.bind(this);
    this.handleFechaLimiteOfertas = this.handleFechaLimiteOfertas.bind(this);
    this.handleFechaLimiteAclaraciones = this.handleFechaLimiteAclaraciones.bind(this);
    this.handleSaveGeneralInfo = this.handleSaveGeneralInfo.bind(this);
    this.handleDate1 = this.handleDate1.bind(this);
    this.handleDate2 = this.handleDate2.bind(this);
    this.toggleSubProc = this.toggleSubProc.bind(this);
    this.handleProcesoEmergencia = this.handleProcesoEmergencia.bind(this);
    this.handleCooperacionconONU = this.handleCooperacionconONU.bind(this);
  }

  handleChangeProcessType(data)
  {
    let valor = this.state.values;
    valor.processTypeId = data.value;

    this.setState({values:valor, processType:data});
  }

  handleDate1(date) {
    //console.log(date)
    let valores = this.state.values;
    valores.fechaLimiteOfertas = date;
    this.setState({values:valores});
  }

  handleDate2(date) {
    let valores = this.state.values;
    valores.fechaLimiteAclaraciones = date;
    this.setState({values:valores});
  }

  handleProcesoEmergencia(dato) {
    let valores = this.state.values;
    valores.esProcesoEmergencia = dato;
    this.setState({values:valores});
  }

  handleCooperacionconONU(dato) {
    let valores = this.state.values;
    valores.esCooperacionconONU = dato;
    this.setState({values:valores});
  }

  handleFechaLimiteOfertas(date)
  {
        let myDate = new Date(date);
        let month = myDate.getMonth()+1;
        let day = myDate.getDate();
        let year = myDate.getFullYear();

        let myDateFormat =  (month<10?"0"+month:month) + "/" + (day<10?"0"+day:day) + "/" + year;
        let valores = this.state.values;
        valores.fechaLimiteOfertas = myDateFormat;
        this.setState({values:valores});
  }

  handleFechaLimiteAclaraciones(date)
  {
        let myDate = new Date(date);
        let month = myDate.getMonth()+1;
        let day = myDate.getDate();
        let year = myDate.getFullYear();

        let myDateFormat =  (month<10?"0"+month:month) + "/" + (day<10?"0"+day:day) + "/" + year;
        //let formatMyDate = myDateFormat;
        let valores = this.state.values;
        valores.fechaLimiteAclaraciones = myDateFormat;
        this.setState({values:valores});
  }

  toggleSubProc() {
    this.setState({
      modalSubProc: !this.state.modalSubProc,
    });
  } 

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      values: {
        ...this.state.values,
        [name]: value,
      },
    });
  };

  async componentDidMount() {
    this.setState({editProccess:this.props.editProccess})
    this.setState({procurementId:this.props.procurementId});
    this.setState({partialOfferId:this.props.partialOfferId});
    this.getInformacionGeneral(this.props.procurementId);
    const listProccessT= await Catalogo(this.props.auth.accessToken, this.props.auth.refreshToken, "GetProccessType",0);
    this.setState({proccessTypes:listProccessT});

    //this.deshabilitarControles();
  }

  /*deshabilitarControles()
  {
    var reference = document.getElementById('reference');
    //console.log(reference)
    reference.disabled = true;
    //console.log(reference)
    var btnLotes = document.getElementById('btnLotes');
    //console.log(btnLotes)

    this.setState({renderizado:1})
    //btnLotes.disabled = true;
  }*/

  AdminSubProceso(procurementId){
    if(procurementId>0)
    {
      this.setState({procurementId:procurementId})
      this.toggleSubProc();
    }
  }

  setEmergenciaSi(event) {
    let valores = this.state.values;
    valores.esProcesoEmergencia = "Si";
    this.setState({values:valores});
  }

  setEmergenciaNo(event) {
    let valores = this.state.values;
    valores.esProcesoEmergencia = "No";
    this.setState({values:valores});
  }

  setCooperacionONUsi(event) {
    let valores = this.state.values;
    valores.esCooperacionconONU = "Si";
    this.setState({values:valores});
  }

  setCooperacionONUno(event) {
    let valores = this.state.values;
    valores.esCooperacionconONU = "No";
    this.setState({values:valores});
  }

  setOfertaParcialsi(event) {
    let valores = this.state.values;
    valores.ofertaParcial = "Si";
    this.setState({values:valores});
    this.props.parentCallback({OfertaParcial:1});
  }

  setOfertaParcialno(event) {
    let valores = this.state.values;
    valores.ofertaParcial = "No";
    this.setState({values:valores});
    this.props.parentCallback({OfertaParcial:2});
  }


   handleProcedureContentChange = (content, delta, source, editor) => {
    let valores = this.state.values;
    valores.description=content;
    this.setState({values:valores})

    //let has_attribues = delta.ops[1].attributes || "";
    //console.log(has_attribues);
    //const cursorPosition = e.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★");
    //this.quill.setSelection(cursorPosition + 1);
  };

  render() {
    let contents = this.state.loading ? (
      <p>
        <Loader type="ball-pulse" /><em>Cargando...</em>
      </p>
    ) : (
      <Card className="main-card mb-1 pt-2">

        <CardBody  className="px-3">
        <div className="w-100">
            <Row> 
              <Col  md={10} className="mb-2">
              <div className="widget-content-left">
                <h4>Información general</h4>
              </div>
              </Col>
              <Col  md={2} className="widget-content-right">
              <div className="widget-content-right">
                {this.state.values.ofertaParcial === "Si"?<Button 
                                                                  id="btnLotes"
                                                                  className="m-0 p-0 btn-icon-vertical" outline size="sm" color="primary" 
                                                                    onClick={()=>this.AdminSubProceso(this.props.procurementId)}
                                                                    disabled={!this.state.editProccess}
                                                                    >
                                                                {"Lotes"}
                                                                <i className="pe-7s-tools btn-icon-wrapper"> </i>                    
                                                          </Button>:""}
              </div> 
              </Col>
            </Row>
         </div>          
          <Row>
                  <Col  md={12}>
                      <div className="mb-2">
                      <FormGroup>
                      <Label for="idModulo">{"Titulo"}</Label>
                      <input
                      type="text"
                      className="form-control"
                      id="SupplierName"
                      aria-describedby="emailHelp"
                      name="title"
                      value={this.state.values.title}
                      ref="localAmount"
                      readOnly={!this.state.editProccess}
                      onChange={this.handleChange}
                      ></input>                       
                      </FormGroup>
                      </div>
                  </Col>
                  <Col  md={6}>
                      <div className="mb-2">
                      <FormGroup>
                      <Label for="idModulo">{"Tipo de proceso"}</Label>
                      <div><b><Label for="idModulo">{this.state.values.processTypeT}</Label></b></div>
                      
                      {/*}<Select
                      defaultValue={this.state.processType}
                      id="metodo"
                      name="metodo"
                      placeholder="Please select item"
                      isSearchable={true}
                      isDisabled={!this.state.editProccess}
                      onChange={this.handleChangeProcessType}
                      options={this.state.proccessTypes}
                      />{*/}                        
                      </FormGroup>
                      </div>
                  </Col>                            
                  <Col  md={6}>
                      <div className="mb-2">
                      <FormGroup>
                      <Label for="idModulo">{"Referencia"}</Label>
                      <input
                      type="text"
                      className="form-control"
                      id="reference"
                      aria-describedby="emailHelp"
                      name="reference"
                      value={this.state.values.reference}
                      ref="localAmount"
                      //readOnly={true}
                      readOnly={!this.state.editProccess}
                      onChange={this.handleChange}
                      ></input>                       
                      </FormGroup>
                      </div>
                  </Col>                            
                             
          </Row>                        
          <div className="mb-2">
              <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
              >
                  {"Descripción"}
              </label>
              {this.props.editProccess?<div><ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      //isDisabled={!this.state.editProccess}
                      value={this.state.values.description}
                      onChange={this.handleProcedureContentChange}
                    /></div>:<Card body className="card-border mb-3" outline color="primary">
                              <div
                                  dangerouslySetInnerHTML={{ __html: this.state.values.description }}>
                              </div>
                              </Card>
                }
          </div>                    
          <Row>
              {/*}<Col md={6}>
                  <FormGroup tag="fieldset">
                  <Label for="idModulo">{"Es proceso de emergencia (EPP)"}</Label>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="ProcesoEPP1" 
                            checked={this.state.values.esProcesoEmergencia == "Si"} 
                            isDisabled={!this.state.editProccess}
                            onChange={this.setEmergenciaSi.bind(this)}/> Si
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="ProcesoEPP1" 
                            checked={this.state.values.esProcesoEmergencia == "No"} 
                            isDisabled={!this.state.editProccess}
                            onChange={this.setEmergenciaNo.bind(this)}/> No
                          </Label>
                        </FormGroup>
                      </FormGroup>
                  </Col>
                  <Col md={6}>
                  <FormGroup tag="fieldset">
                  <Label for="idModulo">{'Licitacion es considerada como "Cooperación con entidades de la ONU"?'}</Label>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="ProcesoCoop1" checked={this.state.values.esCooperacionconONU == "Si"} onChange={this.setCooperacionONUsi.bind(this)}/> Si
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="ProcesoCoop1" checked={this.state.values.esCooperacionconONU == "No"} onChange={this.setCooperacionONUno.bind(this)}/> No
                          </Label>
                        </FormGroup>
                      </FormGroup>

                  </Col>{*/}
                  <Col md={12}>
                  <FormGroup tag="fieldset">
                  <Label for="idModulo">{'Ofertas parciales *'}</Label>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="ofertaParcial" checked={this.state.values.ofertaParcial == "Si"} onChange={this.setOfertaParcialsi.bind(this)}/> 
                            Se permitirá a los licitantes que presenten cotizaciones de precios para uno o varios de los lotes identificados en esta licitación. Sin embargo, los licitantes deberan ofrecer el 100% de los productos especificados para cada lote y el 100% de las cantidades especificadas para cada producto de un lote. Se evaluará cada lote individualmente. (Licitaciones con número de lotes inferior a 150 lotes).
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="ofertaParcial" checked={this.state.values.ofertaParcial == "No"} onChange={this.setOfertaParcialno.bind(this)}/> 
                            No se aceptaran ofertas parciales. Los licitantes deben proporcionar cotizaciones de precios para la totalidad de bienes y/o servicios para el requerimiento total. La evaluación abarcá la totalidad del requerimiento.
                          </Label>
                        </FormGroup>
                      </FormGroup>

                  </Col>                  
                  <Col  md={6}>
                      <div className="mb-2">
                      <FormGroup>
                      <Label for="idModulo">{"Fecha límite para recibir ofertas"}</Label>
                      <DatePicker className="form-control" name="emissionDate2" id="emissionDate2" //ref="emissionDate2"  
                      showTimeSelect
                       dateFormat="MMMM d, yyyy h:mm aa"
                       locale="es"
                      //selected={(this.state.values.fechaLimiteOfertas=="" || this.state.values.fechaLimiteOfertas)?new Date():new Date(this.state.values.fechaLimiteOfertas)} 
                      //onChange={(date) => this.handleFechaLimiteOfertas(date)}
                      readOnly={!this.state.editProccess}
                      selected={this.state.values.fechaLimiteOfertas} 
                      onChange={this.handleDate1}
                      />                   
                      </FormGroup>
                      </div>
                  </Col>
                  <Col  md={6}>
                      <div className="mb-2">
                      <FormGroup>
                      <Label for="idModulo">{"Fecha límite para aclaraciones"}</Label>
                      <DatePicker className="form-control" name="emissionDate" id="emissionDate" //ref="emissionDate2"  
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      locale="es"
                      //selected={(this.state.values.fechaLimiteAclaraciones=="" || this.state.values.fechaLimiteAclaraciones)?new Date():new Date(this.state.values.fechaLimiteAclaraciones)} 
                      //onChange={(date) => this.handleFechaLimiteAclaraciones(date)}
                      readOnly={!this.state.editProccess}
                      selected={this.state.values.fechaLimiteAclaraciones} 
                      onChange={this.handleDate2}
                      />                   
                      </FormGroup>
                      </div>
                  </Col>          
          </Row>
              {this.state.loading ? <div><Loader type="ball-beat" />
              <p>
              <em>Sending request...</em>
              </p></div> : ""}
        </CardBody>
        <CardFooter>
              <Button color="primary" 
                  onClick={this.handleSaveGeneralInfo}
                  >
              {"Guardar"}
              </Button>{" "}
        </CardFooter>
      </Card>
    );

    /*let contentsB = this.state.loadingL ? (
      <p>
        <em>Cargando...</em>
      </p>
    ) : (
      this.renderBatchTable(this.state.batchTasks)
    );

    const mensajeOperacion = this.state.mensajeOperacion;*/


    return (
    <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            classNames="TabsAnimation"
            appear={true}
            timeout={1500}
            enter={false}
            exit={false}
          >     
        {contents}
        </CSSTransition>
        </TransitionGroup>
        <Modal
            isOpen={this.state.modalSubProc}
            toggle={this.toggleSubProc}
            className={this.props.className + " modal-lg" }
          >
            <ModalHeader toggle={this.toggleSubProc}>{this.props.t('Lotes')}</ModalHeader>
            <ModalBody>
            <SubProcesos procurementId={this.state.procurementId}></SubProcesos>
            </ModalBody>
          </Modal>              
    </Fragment>        
    );
  }

  async getInformacionGeneral(procurementId) {
    try {
      let supplierId =0;
      /*if(rolId === 12)
        supplierId = this.props.auth.user.supplierId;*/

      const bod = { Id: procurementId, Title:"", TipoConsulta:""};
      const values = JSON.stringify(bod);

        const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.auth.accessToken,
          }

          this.setState({loading: true });
        const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
        let url = process.env.REACT_APP_API + config.API_ESOURCING_GENERALINFO;
        customAxios.patch(url,
            values,
            { headers }).then((res) => {
              console.log(res.data)
            if(res.data)
            {
              let infoG = this.state.values;
              infoG.id = res.data[0].id;
              infoG.title = res.data[0].title;

              if(res.data[0].reference!=null)
                infoG.reference = res.data[0].reference;
              
              if(res.data[0].description!=null)
                infoG.description = res.data[0].description;

              if(res.data[0].deadlineToOffers!=null)
                infoG.fechaLimiteOfertas = new Date(res.data[0].deadlineToOffers);

              if(res.data[0].deadlineForClarifications!=null)
                infoG.fechaLimiteAclaraciones = new Date(res.data[0].deadlineForClarifications);

              if(res.data[0].processType!=null)
                infoG.processTypeT = res.data[0].processType;

              /*if(res.data[0].processTypeId>0)
              {
                let existe = this.state.proccessTypes.find(element => element.value === res.data[0].processTypeId);
                if(existe)
                {
                  infoG.processTypeId = res.data[0].processTypeId;
                  this.setState({processType:existe});
                }
              }*/

              if(res.data[0].partialOfferId>0)
              {
                  infoG.ofertaParcial = res.data[0].partialOfferId===1?"Si":"No";
              }

            if(res.data[0].itIsEmergencyProcess!=null)
              {
                  infoG.esProcesoEmergencia = res.data[0].itIsEmergencyProcess;
              }

            if(res.data[0].isCooperationWithONU!=null)
            {
                infoG.esCooperacionconONU = res.data[0].isCooperationWithONU;
            }
  
  
              this.setState({values:infoG, loading: false});
            }

          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.setState({loading: false });
          })
    } catch (error) {
      console.log(error);
    }
  }


  async handleSaveGeneralInfo() {
    try {
          if(this.state.values.processTypeId <=0)
          {
            Swal("Advertencia","Por favor indique el tipo de proceso.","warning");
            return;
          }

          if(this.state.values.reference =='')
            {
              Swal("Advertencia","Por favor indique el codigo de referencia.","warning");
              return;
            }

            if(this.state.values.description =='')
              {
                Swal("Advertencia","Por favor indique la descripción del proceso.","warning");
                return;
              }

              if(this.state.values.fechaLimiteAclaraciones > this.state.values.fechaLimiteOfertas)
                {
                  Swal("Advertencia","La fecha límite para recibir aclaraciones no puede ser mayor a la fecha límite para recibir ofertas.","warning");
                  return;
                }

                //console.log(this.state.values.fechaLimiteOfertas, this.state.values.fechaLimiteAclaraciones)
      const bod = { Id: this.state.values.id, 
        ProcessTypeId:this.state.values.processTypeId, 
        Title: this.state.values.title,
        Reference: this.state.values.reference,
        Description:this.state.values.description,
        ItIsEmergencyProcess:this.state.values.esProcesoEmergencia,
        IsCooperationWithONU:this.state.values.esCooperacionconONU, 
        DeadlineToOffers:new Date(this.state.values.fechaLimiteOfertas), 
        DeadlineForClarifications:this.state.values.fechaLimiteAclaraciones, 
        OrganizationalUnit:this.state.values.organizationalUnit, 
        BeneficiaryCountries:this.state.values.beneficiaryCountries,
        PartialOfferId:this.state.values.ofertaParcial==='Si'?1:2,
        PreviousMeeting:false,
        PreviousVisit:false,
        Status:1, 
    };
      const values = JSON.stringify(bod);
      this.setState({loading: false});
  
      const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.auth.accessToken,
          }
        
        const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
        this.setState({loading: true});
        let url = process.env.REACT_APP_API + config.API_ESOURCING_GENERALINFO;
        customAxios.post(url,
          values,
            { headers }).then((res) => {
                this.setState({loading: false});
                if (!res.data.operacionConExito) {
                  //this.setState({ mensajeOperacion: res.data.error });
                  Swal("Error",res.data.error,"error");
                  this.setState({loading: false});
                  //this.toggleMsg();
                } 
                else {
                this.setState({loading: false});
                //this.setState({ mensajeOperacion: "Información registrada con éxito." });
                Swal("Información general!!","Información registrada con éxito." ,"success");
                //this.toggleDDT();
                //this.toggleMsg();
                //this.getProducts(1);
                }
            })
          .catch((err) => {
            this.setState({loading: false});
            console.log("AXIOS ERROR: ", err);
            //this.setState({loadingG: false});
          })
    } catch (error) {
      console.log(error);
    }
  } 

}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
    return {
      setToken: (token) => {
        dispatch(setToken(token));
      }
    }
  };

export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(InfoGeneral)));
