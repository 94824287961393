import React, { Component, Fragment } from "react";
import { TransitionGroup } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import { Container, Alert} from "reactstrap";

import { connect } from "react-redux";
import { setToken } from "../../../../actions/authActions";
import { withRouter } from "react-router-dom";
import Tabs from "react-responsive-tabs";
import config from "react-transition-group";
import myCustomAxios from "../../../../services/myAxios";
import Swal from 'sweetalert';
import ExpresionInteres from "../components/ExpresionInteres"
import Aclaracion from "../components/aclaracion";
import Enmiendas from "./enmiendas";

class Reviews extends Component {
  
  constructor(props) {
    super(props);
      this.state = {
          values:{
            id:0,
            description:"",
          },
          procurementId:0,
          driveFolderId:"",
          title:"",
          listTransitions:[],
          editProccess:false,
          emissionDate2:new Date(),
          selectedOption:"",
          selectedOption2:"",
          loadingData:true,
          modalWorkFlow:false,
          observaciones:"",
      };
      
    }

    componentDidMount() {
    //console.log(this.props.procurementId)
    }

    getTabs() {

    let valores = [];
    let item={};

    if(this.props.wPhaseId==4 ||this.props.wPhaseId==8 ||this.props.wPhaseId==9||this.props.wPhaseId==11)
      {
        item = {
          title: <div><span class="pe-7s-note2"></span> <span>{"Aclaraciones"}&nbsp;</span></div> ,
          content: <Aclaracion procurementId={this.props.procurementId} ></Aclaracion>,
        }
        valores.push(item); 
      }

    if(this.props.wPhaseId==4 ||this.props.wPhaseId==9 ||this.props.wPhaseId==8||this.props.wPhaseId==11)
    {
      item = {
        title: <div><span class="lnr-license"></span> <span>{"Enmiendas"}&nbsp;</span></div> ,
        content: <Enmiendas procurementId={this.props.procurementId} ></Enmiendas>,
      }
      valores.push(item); 
    }
    
    item = {
        title: <div><span class="lnr-bookmark"></span> <span>{"Expresiones de interes"}&nbsp;</span></div> ,
        content: <ExpresionInteres procurementId={this.props.procurementId} ></ExpresionInteres>,
      }
    valores.push(item); 
    item = {
        title: <div><span class="lnr-book"></span> <span>{"Apertura de respuestas"}&nbsp;</span></div> ,
        content: <div>EN PROCESO</div>,
    }
    valores.push(item); 
    item = {
        title: <div><span class="lnr-users"></span> <span>{"Equipo de evaluación"}&nbsp;</span></div> ,
        content: <div>EN PROCESO</div>,
        }
    valores.push(item); 
    item = {
        title: <div><span class="lnr-list"></span> <span>{"Evaluación"}&nbsp;</span></div> ,
        content: <div>EN PROCESO</div>,
        }
        valores.push(item); 
    
          
    return valores.map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content,
      key: index,
    }));
  }

  render() {
    let mostrar = true;
    return (
      <Fragment>
        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
          transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

          <Alert className="mbg-3 m-2" color="info" isOpen={this.state.visible} toggle={this.onDismiss}>
            <span className="pl-2">
              {/*}<FontAwesomeIcon icon={faQuestionCircle} />{*/}
            </span>
            {"Revisar: "}
          </Alert>
        <div>  
        <Container fluid>   
        <div className="card card-nav-tabs pt-5">
          <div className=""></div>
            <Tabs tabsWrapperClass="nav-tabs-navigation card-header" transform={false}
            showInkBar={true} items={this.getTabs()}/>
        </div>
        </Container>
        </div>              

        </TransitionGroup>
      </Fragment>
    );
  }

  async handleTransition(startPh, destinationPh) {
    try {
          if(this.state.observaciones ==='')
          {
            this.setState({ mensajeOperacion: "Por favor ingrese sus observaciones" });
            this.toggleMsg();
            return;
          }


      const bod = { ProcurementId: this.state.procurementId, 
        StartPhaseId:startPh, 
        DestinationPhaseId: destinationPh,
        AssignedUserId: this.props.auth.user.idUsuario,
        Observations:this.state.observaciones,
    };
      const values = JSON.stringify(bod);
      this.setState({loading: false});
  
      const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.auth.accessToken,
          }
        
        const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
        this.setState({loading: true});
        let url = process.env.REACT_APP_API + config.API_ESOURCING_WORKFLOW;
        customAxios.post(url,
          values,
            { headers }).then((res) => {
                this.setState({loading: false});
                if (!res.data.operacionConExito) {
                  Swal("Error",res.data.error,"error");
                  this.setState({loading: false});
                } 
                else {
                this.setState({loading: false});
                Swal("Transición exitosa!!","¡Proceso enviado con éxito." ,"success");
                this.EnviarProcurementPage();
                }
            })
          .catch((err) => {
            this.setState({loading: false});
            console.log("AXIOS ERROR: ", err);
          })
    } catch (error) {
      console.log(error);
    }
  } 

}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      setToken: (token) => {
        dispatch(setToken(token));
      }
    }
  };

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Reviews)));