import React, { Component, Fragment } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { withTranslation} from 'react-i18next';

import {FormGroup, Card, CardBody,CardHeader, Row, Col, Button, Container, InputGroup,Modal, ModalHeader, ModalBody, ModalFooter, Collapse, Input} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextareaAutosize from "react-textarea-autosize";
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import Loader from "react-loaders";

import {
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import { setToken } from "../../../../actions/authActions";
import { withRouter} from "react-router-dom";

import config from "../../../../config.json";
import myCustomAxios from "../../../../services/myAxios";

import ReactQuill from "react-quill";
import 'quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ size: [] }],
    [{ font: [] }],
    [{ align: ["right", "center", "justify"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: ["red", "#785412"] }],
    [{ background: ["red", "#785412"] }]
  ]
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
  "size",
  "font"
];

let valorId = -1;
let valorIdDetail=-1;
let valorIdInspection=-1;
let valorIdInspecFile=-1;
let proyectoT=[];
let miLatitud="";
let miLongitud="";
let permisoUbicacion = false;
let botonHabilitado = true;
let botonPrHabilitado = true;


class DetallesProc extends Component {
  
  constructor(props) {
    super(props);
      this.state = {
          screenshot: null,
          facingMode:true,
          trimmedDataURL: null,
          trimmedDataUnops: null,
          trimmedDataAsociado: null,
          trimmedDataProveedor: null,
          modalFirma:false,
          modalNote:false,
          modalImage:false,
          modalMsg: false,
          modalFiles:false,
          loading: true,
          loadingF:false,
          objProject:[],
          dataTypesAnswer:[],
          modalSection:false,
          modalLocation:false,
          modalCalificar:false,
          templateId:0,
          productId:0,
          traceabilityDocumentId:0,
          codeCCT:"",
          currentPhase:0,
          destinationPhase:0,
          dateData:new Date(),
          checkOption:false,
          mensajeOperacion:"",
          mensajeCalificacion:"",
          FilesQuestion:[],
          accordion: [true, false, false],
          observacionesV:"",
          miLatitud:"",
          miLongitud:"",
          miIp:"",
          dataProject:{
            Id:0,
            ProductId:1,
            Name:"",
            Description:"",
            PublishedDate:null,
            Status:0,
            Categorys:[]
          },
          dataCategory:{
            Id:0,
            ProjectTId:0,
            Name:"",
            Description:"",
            Status:1,
            CategoryDetails:[]
          },
          dataDetail:{
            Id:0,
            CategoryId:0,
            TypeOfAnswerId:0,
            Name:"",
            Description:"",
            Status:1,
            typeOfAnswer:{id:0, name:"", icon:"", listAnswer:[]}
          },
          typeOfAnswer:{id:0, name:"", icon:"", listAnswer:[]},
          answerDet:{Id:0, CategoryDetailId:0, BitacoraId:0, valueAnswer:"", Note:"", InspectionDate:null, ResponsibleUserId:0, Status:1, InspectionFiles:[]},
          dataNote:{note:"", categoryId:0, detailId:0, inspectionId:0},
          dataMediaFile:{note:"", categoryId:0, detailId:0, inspectionId:0},
          inspectionFile:{Id: 0, InspectionId: 0, FileUrl:"", Status: 0, MediaFile:null},
          quienesFirman:{},
      };

      this.toggleFirma = this.toggleFirma.bind(this);
      this.toggleCalificar = this.toggleCalificar.bind(this);
      this.toggleLocation = this.toggleLocation.bind(this);
      this.toggleNote = this.toggleNote.bind(this);
      this.toggleImage = this.toggleImage.bind(this);
      this.toggleFiles = this.toggleFiles.bind(this);
      this.handleDateData = this.handleDateData.bind(this);
      this.handleOptionData = this.handleOptionData.bind(this);
      this.handleSaveTemplate = this.handleSaveTemplate.bind(this);
      this.handleFactoryApproval = this.handleFactoryApproval.bind(this);
      this.toggleCamera = this.toggleCamera.bind(this);
      this.toggleMsg = this.toggleMsg.bind(this);
      this.toggleAccordion = this.toggleAccordion.bind(this);
      this.VerifyQuestionCompliant = this.VerifyQuestionCompliant.bind(this);
      //this.toggleCalificar = this.toggleCalificar.bind(this);
    }

    setFile(e) {
      this.setState({ fileCarga: {mimeType: e.target.files[0].type, fileCarga:e.target.files[0], name:e.target.files[0].name} });
      //this.UploadFileGuarantee({mimeType: e.target.files[0].type, fileCarga:e.target.files[0], name:e.target.files[0].name}, this.state.dataMediaFile.categoryId, this.state.dataMediaFile.detailId, this.state.dataMediaFile.inspectionId);

      var imgsize = e.target.files[0].size;

      if(imgsize > 6000000){
        //alert('El archivo supera los 6Mb.');
        this.setState({ mensajeOperacion: "El archivo supera los 6 Mb."});
        this.toggleMsg();        
        return;
      }
      URL.createObjectURL(e.target.files[0])
      this.setState({screenshot: URL.createObjectURL(e.target.files[0])});
      this.handleDataMediaFile(e.target.files[0], this.state.dataMediaFile.categoryId, this.state.dataMediaFile.detailId, this.state.dataMediaFile.inspectionId)
    }

    async componentDidMount() {
        botonHabilitado = true;
        botonPrHabilitado = true;
        this.setState({templateId : this.props.templateId});
        this.setState({productId : this.props.productId}); 
        this.setState({traceabilityDocumentId : this.props.traceabilityDocId}); 
        this.setState({codeCCT : this.props.codeCCT}); 
        this.setState({currentPhase : this.props.currentPhase}); 
        this.setState({destinationPhase : this.props.destinationPhase}); 
        //console.log(this.props.currentPhase)
        //console.log(this.props.destinationPhase)

        this.GetProjectInformation(0, this.props.procurementId);
    }

    toggleAccordion(tab) {
      const prevState = this.state.accordion;
      const state = prevState.map((x, index) => (tab === index ? !x : false));
  
      this.setState({
        accordion: state,
      });
    }

  // Web camera
  screenshot() {
      var screenshot = this.refs.webcam.getScreenshot();
      this.setState({screenshot: screenshot});
      let file = this.dataURLtoFile(screenshot, 'imagen.jpg');
      this.handleDataMediaFile(file, this.state.dataMediaFile.categoryId, this.state.dataMediaFile.detailId, this.state.dataMediaFile.inspectionId);
  }

  sigPad = {}
    
  clear = () => {
    this.sigPad.clear()
  }
    
  trim = () => {
      this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png')})

      let file = this.dataURLtoFile(this.sigPad.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg');  
      this.handleDataMediaFile(file, this.state.dataMediaFile.categoryId, this.state.dataMediaFile.detailId, this.state.dataMediaFile.inspectionId);
      this.toggleFirma();  
  }

/*firmas */

sigPadUnops = {}
    
clearUnops = () => {
  this.sigPadUnops.clear()
}
  
trimUnops = () => {
    this.setState({trimmedDataUnops: this.sigPadUnops.getTrimmedCanvas().toDataURL('image/png')})
    let file = this.dataURLtoFile(this.sigPadUnops.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg');  
}

sigPadAsociado = {}
    
clearAsociado = () => {
  this.sigPadAsociado.clear()
}
  
trimAsociado = () => {
    this.setState({trimmedDataAsociado: this.sigPadAsociado.getTrimmedCanvas().toDataURL('image/png')})
    let file = this.dataURLtoFile(this.sigPadAsociado.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg');  
}

sigPadProveedor = {}
    
clearProveedor = () => {
  this.sigPadProveedor.clear()
}
  
trimProveedor = () => {
    this.setState({trimmedDataAsociado: this.sigPadProveedor.getTrimmedCanvas().toDataURL('image/png')})
    let file = this.dataURLtoFile(this.sigPadProveedor.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg');  
}
/**/

handleChechInspection(id)
{
    //console.log(id)
};

handleChangeObs = (e) => {
  const { name, value } = e.target;
  this.setState({observacionesV:value});
};

  handlevalueAnswer(date, categoryId, detailId, inspectionId) {
      
      if(this.state.objProject.length>0)
      {
        if(this.state.objProject[0].categorys.length>0)
        {
          this.state.objProject[0].categorys.forEach(myCategory =>{
            if(myCategory.id === categoryId)
            {
              myCategory.categoryDetails.forEach(myDetail =>{
                if(myDetail.id === detailId && myDetail.answerDet.id === inspectionId)
                {
                  myDetail.answerDet.valueAnswer = date.target.value;
                  let proy = this.state.objProject;
                  this.setState({objProject:proy});
                }
              });
            }
          });
        }
    }

  }

  handleProcedureContentChange = (content, categoryId, detailId, inspectionId) => {
    if(this.state.objProject.length>0)
      {
        if(this.state.objProject[0].categorys.length>0)
        {
          this.state.objProject[0].categorys.forEach(myCategory =>{
            if(myCategory.id === categoryId)
            {
              myCategory.categoryDetails.forEach(myDetail =>{
                if(myDetail.id === detailId && myDetail.answerDet.id === inspectionId)
                {
                  myDetail.answerDet.valueAnswer = content;
                  let proy = this.state.objProject;
                  this.setState({objProject:proy});
                }
              });
            }
          });
        }
    }
    //console.log(this.state.objProject)
  };

  handleOptionData(date, categoryId, detailId, inspectionId)
  {
      //let valor =this.state.objProject.length;

      if(this.state.objProject.length>0)
      {
        if(this.state.objProject[0].categorys.length>0)
        {
          this.state.objProject[0].categorys.forEach(myCategory =>{
            if(myCategory.id === categoryId)
            {
              myCategory.categoryDetails.forEach(myDetail =>{
                if(myDetail.id === detailId && myDetail.answerDet.id === inspectionId)
                {
                  myDetail.answerDet.valueAnswer = date.toString();
                  let proy = this.state.objProject;
                  this.setState({objProject:proy});
                  this.handleSaveQuestionForInspection(myDetail.answerDet, categoryId, detailId, inspectionId);
                }
              });
            }
          });
        }
    }

  }

  handleDateData(date, categoryId, detailId, inspectionId)
  {
      //let valor =this.state.objProject.length;

      if(this.state.objProject.length>0)
      {
        if(this.state.objProject[0].categorys.length>0)
        {
          this.state.objProject[0].categorys.forEach(myCategory =>{
            if(myCategory.id === categoryId)
            {
              myCategory.categoryDetails.forEach(myDetail =>{
                if(myDetail.id === detailId && myDetail.answerDet.id === inspectionId)
                {
                  //console.log(date)
                  let myDate = new Date(date);
                  let month = myDate.getMonth()+1;
                  let day = myDate.getDate();
                  let year = myDate.getFullYear();

                  let myDateFormat =  (month<10?"0"+month:month) + "/" + (day<10?"0"+day:day) + "/" + year;
                  //console.log(myDateFormat)
                  //let formatMyDate = myDateFormat;
                  myDetail.answerDet.valueAnswer = myDateFormat;//new Date(date).toUTCString();//date.toString();
                  //myDetail.answerDet.valueAnswer = new Date(date).toUTCString();//date.toString();
                  let proy = this.state.objProject;
                  this.setState({objProject:proy});
                  this.handleSaveQuestionForInspection(myDetail.answerDet, categoryId, detailId, inspectionId);
                }
              });
            }
          });
        }
    }

  }

  handleDataNote(myNote, categoryId, detailId, inspectionId)
  {
      if(this.state.objProject.length>0)
      {
        if(this.state.objProject[0].categorys.length>0)
        {
          this.state.objProject[0].categorys.forEach(myCategory =>{
            if(myCategory.id == categoryId)
            {
              myCategory.categoryDetails.forEach(myDetail =>{
                if(myDetail.id == detailId && myDetail.answerDet.id == inspectionId)
                {
                  myDetail.answerDet.note = myNote.toString();
                  let proy = this.state.objProject;
                  this.setState({objProject:proy});
                  this.handleSaveQuestionForInspection(myDetail.answerDet,categoryId, detailId, inspectionId);
                }
              });
            }
          });
        }
    }
    this.toggleNote();
  }

  urltoFile(url, filename, mimeType){
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }

  dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

  async handleDataMediaFile(myFile, categoryId, detailId, inspectionId)
  {
    let fileConfig = {};
      if(this.state.objProject.length>0)
      {
        if(this.state.objProject[0].categorys.length>0)
        {
          this.state.objProject[0].categorys.forEach(myCategory =>{
            if(myCategory.id == categoryId)
            {
              myCategory.categoryDetails.forEach(myDetail =>{
                if(myDetail.id == detailId && myDetail.answerDet.id == inspectionId)
                {
                  fileConfig ={id: valorIdInspecFile, inspectionId: inspectionId, fileUrl:"", status: 1, mediaFile:myFile}
                  
                  if(!myDetail.answerDet.inspectionFiles)  
                    myDetail.answerDet.inspectionFiles = [];

                  myDetail.answerDet.inspectionFiles.push(fileConfig);
                  let proy = this.state.objProject;
                  this.setState({objProject:proy});
                  valorIdInspecFile = valorIdInspecFile-1;
                  this.handleSaveQuestionForInspection(myDetail.answerDet, categoryId, detailId, inspectionId);
                }
              });
            }
          });
        }
    }
  }

  async handleActualizarInspeccion(answerDet, categoryId, detailId, inspectionId)
  {
    let fileConfig = {};
      if(this.state.objProject.length>0)
      {
        if(this.state.objProject[0].categorys.length>0)
        {
          this.state.objProject[0].categorys.forEach(myCategory =>{
            if(myCategory.id == categoryId)
            {
              myCategory.categoryDetails.forEach(myDetail =>{
                if(myDetail.id == detailId && myDetail.answerDet.id == inspectionId)
                {
                  myDetail.answerDet = answerDet;


                  let compliant =0;
                  let noCompliant =0;
                  let noAplica =0;
                  let sinCalificacion =0;
            
                  let totalCumple=0;
                  let totalNoCumple=0;
                  let totalNoAplica=0;
                  let totalSinCalificacion=0;
                  let cumpleInspeccion="NO CUMPLE";
                  let listResp =[];
                  let valorComparar ={}
            
                  if(this.state.objProject.length>0)
                  {
                    if(this.state.objProject[0].categorys.length>0)
                    {
            
                      this.state.objProject[0].categorys.forEach(myCategory =>{
                        compliant =0;
                        noCompliant =0;
                        noAplica =0;
                        sinCalificacion =0;
                      
                        myCategory.categoryDetails.forEach(myDetail =>{
                          listResp = myDetail.typeOfAnsw.listAnswer;
                          valorComparar = listResp.find((element) => element.isAGuide===true )
                          //console.log(valorComparar);

                          if(valorComparar)
                          {
                            if(myDetail.answerDet.valueAnswer ===valorComparar.value)
                            {
                              compliant = compliant+1;
                            }
                            else
                            if(myDetail.answerDet.valueAnswer ==='N/A')
                            {
                              noAplica = noAplica+1;
                            }
                            else
                            if(myDetail.answerDet.valueAnswer ==='')
                            {
                              sinCalificacion=sinCalificacion+1;
                            }
                            else
                            {
                              noCompliant = noCompliant+1;
                            }
                          }
                          else
                          {
                            if(myDetail.answerDet.valueAnswer ==='Compliant')
                            {
                              compliant = compliant+1;
                            }
                            else
                            if(myDetail.answerDet.valueAnswer ==='Non-Compliant')
                            {
                              noCompliant = noCompliant+1;
                            }
                            else
                            if(myDetail.answerDet.valueAnswer ==='N/A')
                            {
                              noAplica = noAplica+1;
                            }
                            else
                            sinCalificacion=sinCalificacion+1;
                          }

              
                          });
                          myCategory.questionsCompliant = compliant;
                          myCategory.questionsNoCompliant = noCompliant;
                          myCategory.questionsNA = noAplica;
                          myCategory.pendingToAnswer = myCategory.questions - (compliant+noCompliant+noAplica);
            
                          totalCumple = totalCumple+compliant;
                          totalNoCumple = totalNoCumple+noCompliant;
                          totalNoAplica = totalNoAplica + noAplica;
                          totalSinCalificacion = totalSinCalificacion+sinCalificacion;
                      });
                    }
                  }
                  let total = this.state.objProject[0].questions;
                  this.state.objProject[0].questionsCompliant = totalCumple;
                  this.state.objProject[0].questionsNoCompliant = totalNoCumple;
                  this.state.objProject[0].questionsNA = totalNoAplica;
                  this.state.objProject[0].pendingToAnswer = total-(totalCumple+totalNoCumple+totalNoAplica);


                  let proy = this.state.objProject;
                  this.setState({objProject:proy});
                
                }

              });
            }
          });
        }
    }
  }

    handleCheck = (e) => {
      this.setState({checkOption:!this.state.checkOption});
    };

      handleChangeNoteState= (e) => {
        const { value } = e.target;
        let data = this.state.dataNote;
        data.note = value;
        this.setState({dataNote: data});
      };

  toggleFirma() {
        this.setState({
          modalFirma: !this.state.modalFirma,
        });
  }

 async toggleCalificar() {

  let permisoU = false;
  
  await navigator.permissions.query({name:'geolocation'}).then(function(result) {
    if (result.state === 'granted') {
      //('granted');
      permisoU = true;
    } else if (result.state === 'prompt') {
      //console.log('prompt');
      //result.state='granted';
      permisoU = false;
    }
    else
    {
      //console.log('NA');
      permisoU = false;
    }
  });
  
  //console.log(this.state.objProject[0]);
  /*if(permisoU===false)
  {
    this.setState({mensajeOperacion:"Por favor autorice la opción para obtener ubicación."});
    this.toggleMsg();
    return;
  }*/

  let compliant =0;
  let noCompliant =0;
  let noAplica =0;
  let sinCalificacion =0;

  let totalCumple=0;
  let totalNoCumple=0;
  let totalNoAplica=0;
  let totalSinCalificacion=0;
  let cumpleInspeccion="NO CUMPLE";

  let withoutAnswer = "";

  if(this.state.objProject.length>0)
  {
    //console.log('project');
    if(this.state.objProject[0].categorys.length>0)
    {
      //console.log('categorys');
      this.state.objProject[0].categorys.forEach(myCategory =>{
        compliant =0;
        noCompliant =0;
        noAplica=0;
        sinCalificacion =0;


        /*
                                  listResp = myDetail.typeOfAnsw.listAnswer;
                          valorComparar = listResp.find((element) => element.isAGuide===true )
                          console.log(valorComparar);

                          if(valorComparar)
                          {
                            if(myDetail.answerDet.valueAnswer ===valorComparar.value)
                            {
                              compliant = compliant+1;
                            }
                            else
                            if(myDetail.answerDet.valueAnswer !==valorComparar.value)
                            {
                              noCompliant = noCompliant+1;
                            }
                            else
                            if(myDetail.answerDet.valueAnswer ==='N/A')
                            {
                              noAplica = noAplica+1;
                            }
                            else
                            sinCalificacion=sinCalificacion+1;
                          }
                          else
                          {
                            if(myDetail.answerDet.valueAnswer ==='Compliant')
                            {
                              compliant = compliant+1;
                            }
                            else
                            if(myDetail.answerDet.valueAnswer ==='Non-Compliant')
                            {
                              noCompliant = noCompliant+1;
                            }
                            else
                            if(myDetail.answerDet.valueAnswer ==='N/A')
                            {
                              noAplica = noAplica+1;
                            }
                            else
                            sinCalificacion=sinCalificacion+1;
                          }
        */
        let listResp =[];
        let valorComparar={};
        myCategory.categoryDetails.forEach(myDetail =>{
          //console.log('detail');
          //if(myDetail.typeOfAnsw.name==='Multiple choice compliant')
          if(myDetail.typeOfAnsw.isMultiple)
          {
            //console.log('multiple');
            listResp = myDetail.typeOfAnsw.listAnswer;
            valorComparar = listResp.find((element) => element.isAGuide===true )
            //console.log(valorComparar);

            if(valorComparar)
            {
              if(myDetail.answerDet.valueAnswer ===valorComparar.value)
              {
                compliant = compliant+1;
              }
              else
              if(myDetail.answerDet.valueAnswer ==='N/A')
              {
                noAplica = noAplica+1;
              }
              else
              if(myDetail.answerDet.valueAnswer ==='')
              {
                sinCalificacion=sinCalificacion+1;
              }
              else
              {
                noCompliant = noCompliant+1;
              }
            }
            else
            {
            if(myDetail.answerDet.valueAnswer ==='Compliant')
              {
                compliant = compliant+1;
              }
              else
              if(myDetail.answerDet.valueAnswer ==='Non-Compliant')
              {
                noCompliant = noCompliant+1;
              }
              else
              if(myDetail.answerDet.valueAnswer ==='N/A')
              {
                noAplica = noAplica+1;
              }
              else
              {
                sinCalificacion=sinCalificacion+1;
                withoutAnswer =  withoutAnswer + myDetail.name + ", ";
              }
            }
          }
          else
          if(myDetail.typeOfAnsw.name==='Date time' || myDetail.typeOfAnsw.name==='Text answer'|| myDetail.typeOfAnsw.name==='Number')
          {
            if(myDetail.answerDet.valueAnswer !=='' || myDetail.answerDet.valueAnswer !==null)
            compliant = compliant+1;
            else 
            noCompliant = noCompliant+1;
          }
          else
          {
            noAplica = noAplica+1;
          }
          });
          myCategory.questionsCompliant = compliant;
          myCategory.questionsNoCompliant = noCompliant;

          totalCumple = totalCumple+compliant;
          totalNoCumple = totalNoCumple+noCompliant;
          totalNoAplica = totalNoAplica + noAplica;
          totalSinCalificacion = totalSinCalificacion+sinCalificacion;
      });
    }
    }

    let total = this.state.objProject[0].questions;
    this.state.objProject[0].questionsCompliant = totalCumple;
    this.state.objProject[0].questionsNoCompliant = totalNoCumple;
    this.state.objProject[0].questionsNA = totalNoAplica;
    this.state.objProject[0].pendingToAnswer = total-(totalCumple+totalNoCumple+totalNoAplica);

    let porcentaje = (totalCumple * 100)/(total-totalNoAplica);
    if(porcentaje>=100)
    cumpleInspeccion ="SI CUMPLE";
    
    //if(porcentaje<=80)
    //{
        this.setState({ mensajeCalificacion: "Porcentaje de cumplimiento: "+porcentaje.toFixed(2) + "%  Resultado: "+cumpleInspeccion });
        //this.toggleMsg();
        //return;
    //}

    if(totalSinCalificacion > 0)
    {
      if(totalSinCalificacion < 10)
      {
        this.setState({ mensajeOperacion: "Faltan por responder : " + totalSinCalificacion + " preguntas: " + withoutAnswer + " se debe responder todas las preguntas para poder calificar la inspección"});
        this.toggleMsg();
      }
      else
      {
        this.setState({ mensajeOperacion: "Faltan por responder : " + totalSinCalificacion + " preguntas, se debe responder todas las preguntas para poder calificar la inspección"});
        this.toggleMsg();
      }
    }
    else
    {
        this.setState({
          modalCalificar: !this.state.modalCalificar,
        });

    }
}

  toggleNote() {

    this.setState({
      modalNote: !this.state.modalNote,
    });
}

toggleImage() {
  this.setState({ modalImage: !this.state.modalImage, });
}

toggleFiles() {
  this.setState({ modalFiles: !this.state.modalFiles, });
}

toggleCamera() {

  this.setState({
    facingMode: !this.state.facingMode,
  });
}

toggleMsg() {
  this.setState({
    modalMsg: !this.state.modalMsg,
  });
}

setDataNote(myNote, categoryId, detailId, inspectionId)
{
  let dataNote={note:myNote, categoryId:categoryId, detailId:detailId, inspectionId:inspectionId}
  this.setState({dataNote:dataNote});
  this.toggleNote();
}

setDataImage(myNote, categoryId, detailId, inspectionId)
{
  let dataMediaF={note:myNote, categoryId:categoryId, detailId:detailId, inspectionId:inspectionId}
  this.setState({dataMediaFile:dataMediaF});

  this.setState({screenshot:null});
  this.toggleImage();
}

setDataSignature(myNote, categoryId, detailId, inspectionId)
{
  let dataMediaF={note:myNote, categoryId:categoryId, detailId:detailId, inspectionId:inspectionId}
  this.setState({dataMediaFile:dataMediaF});

  this.toggleFirma();
}

toggleLocation() {
    this.setState({
      modalLocation: !this.state.modalLocation,
    });
}

handleFilesQuestion(files){
  //console.log(files.length)
  if(files.length>0)
  {
    this.GetFilesInspection(files[0].inspectionId)
    this.setState({FilesQuestion:files});
  }
  else
  this.setState({FilesQuestion:[]});

this.toggleFiles();
}

  render() {

      let contents = this.state.loading ? (
          <div><Loader type="ball-pulse" /><em>Espere un momento...</em></div>
      ) : (
        <div>
        <Row>
            <Col md={12}>
                <Card className="main-card mb-2">
                      <CardBody className="p-3">
                          <Row>
                              {/*}<Col md={12}>
                                  <FormGroup>
                                      <b>{this.state.codeCCT }</b>
                                  </FormGroup>
                              </Col>{*/}
                              <Col md={12}>
                                  <FormGroup>
                                      <p>{this.state.objProject.length>0?this.state.objProject[0].description:""}</p>
                                  </FormGroup>
                              </Col>                                
                              {/*}<Col md={2}>
                                <Button className=" btn-transition" style={{height: "100%"}} outline color="primary" onClick={this.handleFactoryApproval}> 
                                <i className="btn-icon-wrapper"> <FaCheck size={20} /></i>
                                &nbsp;Válido de fábrica
                                </Button>
                              </Col>{*/}                                
                          </Row>
                      </CardBody>
                </Card>                                    
            </Col>
      </Row>
      <Row>
      {this.state.objProject.length>0? this.state.objProject[0].categorys.map((myCategory, index) => (
        <Col md="12" key={index}>
          <div id="accordion" className="accordion-wrapper mb-3">
              <Card className="main-card mb-2">
                <CardHeader id="headingOne" color="primary">
                      <Button block color="link" className="text-start m-0 p-0" onClick={() => this.toggleAccordion(index)}
                        aria-expanded={this.state.accordion[index]} aria-controls="collapseOne">
                        <div>
                          <div className="widget-description" style={{ fontSize: 20 }}><FontAwesomeIcon icon={faAngleDown} /> &nbsp;&nbsp; {myCategory.name}</div>
                        </div>                               
                      </Button>
                </CardHeader> 
                <Collapse isOpen={this.state.accordion[index]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                  <CardBody>
                  {myCategory.categoryDetails.map((option, index2) => (
                  <Card className="main-card mb-1" key={index2}>
                  <CardBody>
                  
                      <b>{option.name}</b>
                      <p>{option.description}</p>
                      <div className="widget-content-left me-2 ms-2">
                                  {/*}<i className={option.typeOfAnswer.icon+" btn-icon-wrapper"}> {" "} </i>{*/}
                      </div>
                      <Row>
                        <Col md="12">
                        <div className="list-answer-content">
                              {option.typeOfAnsw/*.listAnswer <=0*/ ? <div> {(() => {
                                
                                                                                    switch (option.typeOfAnsw.name) {
                                                                                    case 'Formatted text answer':
                                                                                        return  <>{this.props.editProccess?<div><ReactQuill
                                                                                                    theme="snow"
                                                                                                    modules={modules}
                                                                                                    formats={formats}
                                                                                                    value={option.answerDet.valueAnswer}
                                                                                                    onChange={(content)=>this.handleProcedureContentChange(content, myCategory.id, option.id, option.answerDet.id)}
                                                                                                /></div>:<Card body className="card-border mb-3" outline color="primary">
                                                                                                <div
                                                                                                    dangerouslySetInnerHTML={{ __html: option.answerDet.valueAnswer }}>
                                                                                                </div>
                                                                                                </Card>
                                                                                                }</>
                                                                                    case 'Multiple options with description':
                                                                                      return <Row>
                                                                                            <Col md="12">
                                                                                              {option.typeOfAnsw.listAnswer.map((myList, index2) => (
                                                                                                      <div className="list-answer" key={index2}>
                                                                                                        <Button 
                                                                                                        color={option.answerDet.valueAnswer === myList.value?"primary":"light"}
                                                                                                        onClick={(value) => this.handleOptionData(myList.value, myCategory.id, option.id, option.answerDet.id)}
                                                                                                        > {myList.value} </Button>
                                                                                                        </div>
                                                                                              ))}    
                                                                                            </Col>
                                                                                            <Col md="12">
                                                                                              {}<ReactQuill
                                                                                                  theme="snow"
                                                                                                  modules={modules}
                                                                                                  formats={formats}
                                                                                                  value={option.answerDet.valueAnswer}
                                                                                                  onChange={(content)=>this.handleProcedureContentChange(content, myCategory.id, option.id, option.answerDet.id)}
                                                                                              />  {}
                                                                                              </Col>
                                                                                              </Row>                                                                                              
                                                                                    case 'Number':
                                                                                        return <input type="number" className="form-control"
                                                                                                id={option.answerDet.id}
                                                                                                aria-describedby="userHelp"
                                                                                                placeholder=""
                                                                                                name={option.typeOfAnsw.name}
                                                                                                value={option.answerDet.valueAnswer}
                                                                                                ref={option.typeOfAnsw.name}
                                                                                                onChange={(value) => this.handlevalueAnswer(value, myCategory.id, option.id, option.answerDet.id)}
                                                                                                ></input>
                                                                                    case 'Checkbox':
                                                                                        return  <FormGroup>
                                                                                                <Switch 
                                                                                                //onChange={this.handleChechInspection} 
                                                                                                onChange={(value) => this.handleOptionData(value, myCategory.id, option.id, option.answerDet.id)}
                                                                                                checked={option.answerDet.valueAnswer=='true'?true:false}
                                                                                                className="me-2 mb-2" 
                                                                                                //id={option.answerDet.id}
                                                                                                />
                                                                                                </FormGroup>
                                                                                    case 'Location':
                                                                                        return <div>
                                                                                                    <Button color="light" onClick={(id) => this.toggleLocation()}> Add location </Button>
                                                                                                </div> 
                                                                                    case 'Date time':
                                                                                            return <InputGroup>
                                                                                                        <div className="input-group-text">
                                                                                                        <i className="lnr-calendar-full btn-icon-wrapper text-primary"> </i>
                                                                                                        </div>
                                                                                                        {/*}<div className="input-group-text">
                                                                                                              <FontAwesomeIcon icon={faCalendarAlt} />
                                                                                                        </div>{*/}
                                                                                                        <DatePicker 
                                                                                                        className="form-control"
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        name="emissionDate2" 
                                                                                                        id={option.typeOfAnsw.id} 
                                                                                                        ref="emissionDate2"  
                                                                                                        selected={(option.answerDet.valueAnswer=="" || option.answerDet.valueAnswer==null)?new Date():new Date(option.answerDet.valueAnswer)} 
                                                                                                        onChange={(date) => this.handleDateData(date, myCategory.id, option.id, option.answerDet.id)}
                                                                                                        //onChange={this.handlevalueAnswer}
                                                                                                        />
                                                                                                    </InputGroup>
                                                                                    case 'Media':
                                                                                                return <Button className="mb-2 me-2 btn-icon" color="link" 
                                                                                                          id={option.typeOfAnsw.id}
                                                                                                          onClick={(id) =>this.setDataImage(option.answerDet.note, myCategory.id, option.id, option.answerDet.id)}>
                                                                                                          <i className="pe-7s-paperclip btn-icon-wrapper"> </i>
                                                                                                          Attach media
                                                                                                        </Button>   
                                                                                    case 'Signature':
                                                                                                return <div>
                                                                                                    <Button color="primary" onClick={(id) => this.setDataSignature(option.answerDet.note, myCategory.id, option.id, option.answerDet.id)}> 
                                                                                                    <i className="pe-7s-note btn-icon-wrapper"> </i>
                                                                                                    Firmar </Button>
                                                                                                    {this.state.trimmedDataURL
                                                                                                    ? <img src={this.state.trimmedDataURL} />: null}
                                                                                                </div>                                                                                                                                                                                                                                                                                 
                                                                                    default:
                                                                                        return null
                                                                                    }
                                                                                })()}
                                                                  </div>:""/*option.typeOfAnsw.listAnswer.map((myList, index2) => (
                                                                            <div className="list-answer" key={index2}>
                                                                              <Button 
                                                                              color={option.answerDet.valueAnswer === myList.value?"primary":"light"}
                                                                              onClick={(value) => this.handleOptionData(myList.value, myCategory.id, option.id, option.answerDet.id)}
                                                                              > {myList.value} </Button>
                                                                              </div>
                                                                    ))*/}
                          
                        </div>
                        </Col>
                        {/*}<Col md="6">
                          <div className="list-answer-content">
                          {(option.answerDet.inspectionFiles!=null)? <div>
                            <Button className="mb-2 me-2 btn-icon" color="link" onClick={(id) =>this.handleFilesQuestion(option.answerDet.inspectionFiles)}>
                              <i className="pe-7s-paperclip btn-icon-wrapper"> </i>
                              Files
                            </Button>
                          </div>:""}
                          </div>
                        </Col>
                      <Col md="6">
                        <div className="list-answer-content">
                        {(option.answerDet.note !==null && option.answerDet.note !=="null" && option.answerDet.note !=="")? "Note: "+option.answerDet.note:""}
                        </div>
                      </Col>{*/}

                      </Row>
                  </CardBody>
                  {/*}<CardFooter className="d-block text-end">
                    <div className="widget-content-right">
                      <div className="list-answer">
                      <Button className="mb-2 me-2 btn-icon" color="link" onClick={(id) =>this.setDataNote((option.answerDet.note !==null && option.answerDet.note !=="null" && option.answerDet.note !=="")? option.answerDet.note:"", myCategory.id, option.id, option.answerDet.id)}>
                        <i className="pe-7s-note2 btn-icon-wrapper"> </i>
                        {this.props.t('EditNote')}
                      </Button>                  
                      </div>           
                      <div className="list-answer">
                      <Button className="mb-2 me-2 btn-icon" color="link" onClick={(id) =>this.setDataImage(option.answerDet.note, myCategory.id, option.id, option.answerDet.id)}>
                        <i className="pe-7s-paperclip btn-icon-wrapper"> </i>
                        {this.props.t('AttachMedia')}
                      </Button>                  
                      </div>           

                    </div>  

                  </CardFooter>{*/}
                  </Card>
                  ))}  

                  </CardBody>
                </Collapse>
                {/*}<CardFooter className="d-block text-end">
                  <Row>
                    <Col md={2}>
                      {this.props.t('Compliant')+ ": "+myCategory.questionsCompliant}
                    </Col>
                    <Col md={2}>
                      {this.props.t('NonCompliant')+ ": "+myCategory.questionsNoCompliant}
                    </Col>
                    <Col md={2}>
                      {"N/A: "+myCategory.questionsNA}
                    </Col>
                    <Col md={2}>
                      {this.props.t('PendingToAnswer')+ ": "+myCategory.pendingToAnswer}
                    </Col>
                    <Col md={4}>
                      {"total: "+myCategory.questions}
                    </Col>
                  </Row>
                  </CardFooter>{*/}                
              </Card>
          </div>
                  
       </Col>
       )):""}                      
      </Row>
      </div> 
      );

    return (
      <Fragment>
        <TransitionGroup>
        <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
        <div>  
            <Container fluid>    
            {contents}      
          <Row>
                <Col md={12}>
                    <Card className="main-card mb-3">
                          <CardBody className="p-3">
                          <div className="widget-content-right">
                          <div className="list-answer">
                            <Button color="primary" 
                            //onClick={this.toggleCalificar} disabled={!botonPrHabilitado}
                            onClick={this.handleSaveTemplate} disabled={!botonPrHabilitado}
                            > 
                                <i className="pe-7s-note btn-icon-wrapper"> </i>
                                {this.props.t('Guardar') } </Button>                  
                          </div>           
                        </div> 
                          </CardBody>
                    </Card>                                    
                </Col>
          </Row>
          </Container>
              </div>
              </CSSTransition>        
          </TransitionGroup>

        <Modal
          isOpen={this.state.modalNote}
          toggle={this.toggleNote}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleNote}>
          {"Add note"}
          </ModalHeader>
          <ModalBody>
            <TextareaAutosize className="form-control" minRows={3} maxRows={6} defaultValue="" maxlength="400"
            value={this.state.dataNote.note} 
            onChange={this.handleChangeNoteState}/>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggleNote}>
            {this.props.t('Cancel')}
            </Button>
            <Button color="primary" onClick={(id) =>this.handleDataNote(this.state.dataNote.note, this.state.dataNote.categoryId, this.state.dataNote.detailId, this.state.dataNote.inspectionId)}>
            {this.props.t('Add')}
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
                  isOpen={this.state.modalMsg}
                  toggle={this.toggleMsg}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggleMsg}>{this.props.t('TradabilityDocument')}</ModalHeader>
                  <ModalBody>
                    <p>{this.state.mensajeOperacion}</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggleMsg}>
                    {this.props.t('OK')}
                    </Button>
                  </ModalFooter>
        </Modal>

        <Modal
            isOpen={this.state.modalCalificar}
            toggle={this.toggleCalificar}
            className={this.props.className + " modal-lg" }
          >
            <ModalHeader toggle={this.toggleCalificar}>
            {"Calificar inspección"}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <div className="mb-2">
                    {this.state.mensajeCalificacion}
                  </div>                  
                </Col>
              </Row>

              <Row>
                <Col md={4} hidden={this.state.quienesFirman.unopsSignature===true?"":"hidden"}>
                    <Card className="main-card mb-2">
                        <CardBody className="p-3">
                          
                        </CardBody>
                    </Card>                   
                    {this.props.t('UnopsSignature') }
                </Col>
                <Col md={4} hidden={this.state.quienesFirman.associatedSignature===true?"":"hidden"}>
                    <Card className="main-card mb-2">
                        <CardBody className="p-3">
                          
                        </CardBody>
                    </Card>    
                    {this.props.t('AssociateSignature') }              
                </Col>
                <Col md={4} hidden={this.state.quienesFirman.supplierSignature===true?"":"hidden"}>
                    <Card className="main-card mb-2">
                        <CardBody className="p-3">
                          {/*}<SignaturePad ref={(ref) => { this.sigPadProveedor = ref }} canvasProps={{ width: 350, height: 200 }}/>{*/}
                        </CardBody>
                    </Card>    
                    {this.props.t('SupplierSignature') }              
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={12}>
                <div className="mb-2">
                      <label htmlFor="puestoFor" className="form-label">
                      {this.props.t('Observations')}
                      </label>
                      <TextareaAutosize className="form-control" minRows={2} maxRows={6} defaultValue="" 
                      name="Description"
                      id="Description"
                      value={this.state.observacionesV} onChange={this.handleChangeObs}
                      maxlength="2000"
                      />
                    </div>                  
                </Col>
              </Row>
              {this.state.loading ? <p>
                <div><Loader type="ball-pulse" /><em>Espere un momento...</em></div>
              </p>: ""}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.handleSaveTemplate} disabled={!botonHabilitado}>
              {this.props.t('Validate') +" "+this.props.t('Inspection')}
              </Button>{" "}
            </ModalFooter>
        </Modal>
      </Fragment>
    );
  }

  async GetProjectInformation(id, procurementId) {
    try {
        const bod = { Id: id, TypeData:"ForInspection", ProcurementId: procurementId,  OfficeId:1, TypeTemplate:"Detalles"};
        const values = JSON.stringify(bod);
        //console.log(bod)
        let contador =0;
        let categories=[];

          const headers= {
              "Access-Control-Allow-Origin": true,
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.props.auth.accessToken,
            }
      
          this.setState({loading: true });
          const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
          
          let url = process.env.REACT_APP_API + config.API_ESOURCING_DETAILS;
          customAxios.patch(url,
              values,
              { headers }).then((res) => {
              this.setState({ objProject: res.data, loading: false });
              //console.log(res.data)
              //this.setState({ quienesFirman: res.data[0].signatures, loading: false });
              let miProy = res.data[0];


              miProy.categorys.forEach(element => {

                if(contador === 0)
                categories.push(true);
                else
                categories.push(false);

                contador =contador+1;
              });

              this.setState({accordion:categories})
              //console.log(res.data[0])
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
              this.setState({ loading: false });
            })
      } catch (error) {
        this.setState({ loading: false });
        console.log(error);
      }
  }
  async GetFilesInspection(id) {
    try {
        const bod = { InspectionId: id};
        const values = JSON.stringify(bod);
        //console.log(bod)
        let contador =0;
        let categories=[];

          const headers= {
              "Access-Control-Allow-Origin": true,
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.props.auth.accessToken,
            }
      
          this.setState({loadingF: true });
          const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
          customAxios.put(config.API_TIJAX_DOCUMENT,
              values,
              { headers }).then((res) => {
                //console.log(res.data)
              this.setState({ FilesQuestion: res.data, loadingF: false });
              
              /*this.setState({ quienesFirman: res.data[0].signatures, loading: false });
              let miProy = res.data[0];


              miProy.categorys.forEach(element => {

                if(contador === 0)
                categories.push(true);
                else
                categories.push(false);

                contador =contador+1;
              });

              this.setState({accordion:categories})
              console.log(res.data[0])*/
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
              this.setState({ loadingF: false });
            })
      } catch (error) {
        this.setState({ loadingF: false });
        console.log(error);
      }
  }

  async VerifyQuestionCompliant(myProject)
  {
    let compliant =0;
    let noCompliant =0;
    let sinCalificacion =0;
    if(this.state.objProject.length>0)
    {
      if(this.state.objProject[0].categorys.length>0)
      {
        this.state.objProject[0].categorys.forEach(myCategory =>{

          compliant =0;
          noCompliant =0;
          sinCalificacion =0;
      
          myCategory.categoryDetails.forEach(myDetail =>{
              if(myDetail.answerDet.valueAnswer ==='Compliant')
              {
                compliant = compliant+1;
              }
              else
              if(myDetail.answerDet.valueAnswer ==='Non-Compliant')
              {
                noCompliant = noCompliant+1;
              }
              else
              sinCalificacion=sinCalificacion+1;

            });
            myCategory.questionsCompliant = compliant;
            myCategory.questionsNoCompliant = noCompliant;
        });
      }
    }
      proyectoT = myProject;
      this.setState({objProject:myProject});
  }

  async handleSaveTemplate() {
    try {
      /*botonHabilitado = false;
      //console.log(this.state.quienesFirman)
      let fileU = this.state.quienesFirman.unopsSignature===true?this.dataURLtoFile(this.sigPadUnops.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg'):null;  
      let fileA = this.state.quienesFirman.associatedSignature===true?this.dataURLtoFile(this.sigPadAsociado.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg'):null;  
      let filePr = this.state.quienesFirman.supplierSignature===true?this.dataURLtoFile(this.sigPadProveedor.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg'):null;  */

      this.setState({loading: false});

        /*const formData = new FormData();

        formData.append('ProductId', this.state.objProject[0].productId);
          formData.append('TraceabilityDocumentId', this.state.traceabilityDocumentId);
          formData.append('TemplateId', this.state.templateId);
          formData.append('Inspections', []);
          formData.append('ClientInfo', null);
          formData.append('UserId', this.props.auth.user.idUsuario);
          formData.append('CurrentPhase', this.state.currentPhase);
          formData.append('DestinationPhase', this.state.destinationPhase);

          formData.append('FirmaUnops', fileU);
          formData.append('FirmaAsociado', fileA);
          formData.append('FirmaProveedor', filePr);
          formData.append('Observaciones', this.state.observacionesV);

          formData.append('Latitud', miLatitud);
          formData.append('Longitud', miLongitud);
          formData.append('IP', this.state.miIp);
          formData.append('Operacion','Validacion');*/

          let templateDTO = {
            Id:0,
            ProcurementId: this.props.procurementId,
            Name:"", 
            Description:"",
            Status:1,
            Categorys:[],
          }

          let categorias = [];
          let detalles = [];
          let respuesta = {};
          let detalle ={}
          let categoria = {}

          if(this.state.objProject.length>0)
            {
              if(this.state.objProject[0].categorys.length>0)
              {
                this.state.objProject[0].categorys.forEach(myCategory =>{
                  detalles=[];
                    myCategory.categoryDetails.forEach(myDetail =>{
                      respuesta = {Id:myDetail.answerDet.id, 
                                    CategoryDetailId:myDetail.answerDet.categoryDetailId,
                                    TemplateId: myDetail.answerDet.templateId,
                                    ProcurementId: myDetail.answerDet.procurementId,
                                    valueAnswer: myDetail.answerDet.valueAnswer,
                                    ResponsibleUserId:1,
                                    Status:1,
                                  };

                      detalle = {
                            Id: myDetail.id,
                            CategoryId: myDetail.categoryId,
                            TypeOfAnswerId: myDetail.typeOfAnswerId,
                            Name: myDetail.name,
                            Description:myDetail.description,
                            Status: myDetail.status,
                            TypeOfAnsw:{Id:0, Name:"", Icon:"", Status:1, IsMultiple:false, SelectI:false},
                            AnswerDet:respuesta
                      };

                      detalles.push(detalle)
                        //let proy = this.state.objProject;
                        //this.setState({objProject:proy});
                    });
                  
                    categoria = {
                      Id: myCategory.id,
                      TemplateId:myCategory.templateId,
                      Name:myCategory.name,
                      Description:myCategory.description,
                      Status:1,
                      CategoryDetails: detalles
                    }  
                    categorias.push(categoria)
                });
              }
              templateDTO.Categorys=categorias;
          }

          const values = JSON.stringify(templateDTO);

          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }

      
      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      this.setState({loading: true});
      let url = process.env.REACT_APP_API + config.API_ESOURCING_DETAILS;
      customAxios.post(url,
        values,
          { headers }).then((res) => {
              //this.setState({loadingG: false});
              if (!res.data.operacionConExito) {
                this.setState({ mensajeOperacion: res.data.error });
                this.setState({loading: false});
                this.toggleMsg();
                //botonHabilitado = true;
              } 
              else {
              this.setState({loading: false});
              this.setState({ mensajeOperacion: "inspection registered successfully"});
              this.setState({observacionesV:""});
              this.toggleMsg();
              //botonHabilitado = true;
              this.props.parentCallbackSaveInspection(true);
              }
          })
        .catch((err) => {
          botonHabilitado = true;
          this.setState({loading: false});
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
    botonHabilitado = true;
    console.log(error);
    }
  }

  async handleFactoryApproval() {
    try {
      botonPrHabilitado = false;
      //console.log(this.state.quienesFirman)
      /*let fileU = this.state.quienesFirman.unopsSignature===true?this.dataURLtoFile(this.sigPadUnops.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg'):null;  
      let fileA = this.state.quienesFirman.associatedSignature===true?this.dataURLtoFile(this.sigPadAsociado.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg'):null;  
      let filePr = this.state.quienesFirman.supplierSignature===true?this.dataURLtoFile(this.sigPadProveedor.getTrimmedCanvas().toDataURL('image/png'), 'imagen.jpg'):null;  */

      this.setState({loading: false});

        const formData = new FormData();

        formData.append('ProductId', this.state.objProject[0].productId);
          formData.append('TraceabilityDocumentId', this.state.traceabilityDocumentId);
          formData.append('TemplateId', this.state.templateId);
          formData.append('Inspections', []);
          formData.append('ClientInfo', null);
          formData.append('UserId', this.props.auth.user.idUsuario);
          formData.append('CurrentPhase', this.state.currentPhase);
          formData.append('DestinationPhase', this.state.destinationPhase);

          formData.append('FirmaUnops', null);
          formData.append('FirmaAsociado', null);
          formData.append('FirmaProveedor', null);
          formData.append('Observaciones', this.state.observacionesV);

          formData.append('Latitud', miLatitud);
          formData.append('Longitud', miLongitud);
          formData.append('IP', this.state.miIp);
          formData.append('Operacion','AprobacionDeFabrica');

          const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.auth.accessToken,
              }

      
      const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);
      this.setState({loading: true});

      customAxios.post(config.API_TIJAX_POST_INSPECTION,
        formData,
          { headers }).then((res) => {
              //this.setState({loadingG: false});
              if (!res.data.operacionConExito) {
                this.setState({ mensajeOperacion: res.data.error });
                this.setState({loading: false});
                this.toggleMsg();
                botonPrHabilitado = true;
              } 
              else {
              this.setState({loading: false});
              this.setState({ mensajeOperacion: "Check list calificada de fábrica"});
              this.toggleMsg();
              botonPrHabilitado = true;
              this.setState({observacionesV:"Válido de fábrica"});
              this.GetProjectInformation(this.props.templateId, this.props.productId, this.props.traceabilityDocId, this.props.currentPhase);
              }
          })
        .catch((err) => {
          botonPrHabilitado = true;
          this.setState({loading: false});
          console.log("AXIOS ERROR: ", err);
        })
    } catch (error) {
      botonPrHabilitado = true;
    console.log(error);
    }
  }

  async handleSaveQuestionForInspection(answerDet, categoryId, detailId, inspectionId) {
    try {
    const formData = new FormData();

    formData.append('Id', answerDet.id);
      formData.append('CategoryDetailId', answerDet.categoryDetailId);
      formData.append('TemplateId', this.state.templateId);
      formData.append('TraceabilityDocumentId', this.state.traceabilityDocumentId);
      formData.append('valueAnswer', answerDet.valueAnswer);
      formData.append('Note', answerDet.note);
      formData.append('InspectionDate', answerDet.inspectionDate);
      formData.append('ResponsibleUserId', this.props.auth.user.idUsuario);
      formData.append('Status', answerDet.status);
      formData.append('WPhaseId', this.state.currentPhase);
      

      if(answerDet.inspectionFiles)
      {
        if(answerDet.inspectionFiles.length >0)
          for (var j = 0; j < answerDet.inspectionFiles.length; j++)
          {
            formData.append('InspectionFiles['+j+'].Id',answerDet.inspectionFiles[j].id);
            formData.append('InspectionFiles['+j+'].InspectionId',answerDet.inspectionFiles[j].inspectionId);
            formData.append('InspectionFiles['+j+'].FileUrl',answerDet.inspectionFiles[j].fileUrl);
            formData.append('InspectionFiles['+j+'].Status',answerDet.inspectionFiles[j].status);
            formData.append('InspectionFiles['+j+'].MediaFile',answerDet.inspectionFiles[j].mediaFile);
          }
        else
          formData.append('InspectionFiles',null);
        }
        else
        formData.append('InspectionFiles',null);

      const headers= {
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.auth.accessToken,
          }
        
        const customAxios = myCustomAxios(this.props.auth.accessToken, this.props.auth.refreshToken);

        customAxios.put(config.API_TIJAX_POST_INSPECTION,
          formData,
            { headers }).then((res) => {
                if (!res.data.operacionConExito) {
                  this.setState({ mensajeOperacion: res.data.error });
                  this.toggleMsg();
                } 
                else {
                this.handleActualizarInspeccion(res.data,categoryId, detailId, inspectionId)
                }
            })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
          })
    } catch (error) {
      console.log(error);
    }
  }

}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      setToken: (token) => {
        dispatch(setToken(token));
      }
    }
  };

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DetallesProc)));